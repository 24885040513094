import { Component } from "react";
import { 
    CardHeader,
    Input,
    MDBRow,
    MDBCol,
    MDBListGroup,
    MDBListGroupItem,
    MDBTabContent,
    MDBTabPane,MDBTable, MDBTableBody, MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption,MDBBtn, MDBInput, MDBInputGroup, MDBIcon,
    MDBPopover,
    MDBPopoverBody,
    MDBPopoverHeader,
    MDBSelectV5
 } from "mdbreact";
import { OauthSender } from 'react-oauth-flow';
import { connect } from "react-redux";
import {SettingsAPI} from "../../Api/connect";
import Fulfiller_BPS from "./Fulfillment/bps";
import New_Fulfiller from "./Fulfillment/newFulfiller";



class Fulfillment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "newFulfiller",
            fulfillers: props.fulfillers?.fulfillers,
            activeFulfillers: props.fulfillers?.fulfillers ? Object.keys(props.fulfillers.fulfillers) : [],
            profile: ""
        }

    }

    componentWillReceiveProps(props) {
        this.setState({activeFulfillers: props.fulfillers?.fulfillers ? Object.keys(props.fulfillers.fulfillers) : [], fulfillers: props.fulfillers?.fulfillers})
    }

    

toggleTabs = tab => () => {
    if (this.state.activeTab !== tab) {
    this.setState({
        activeTab: tab
    });
    }
    }

    
render() {
    return (
        <section>
           <MDBRow>
               <MDBCol sm="4">
                <MDBListGroup className="my-4 mx-4" style={{ width: "22rem" }}>
                        {this.state.activeFulfillers.length > 0 && this.state.activeFulfillers.map(fulfiller => <MDBListGroupItem color="light" onClick={this.toggleTabs(fulfiller)}>{this.state.fulfillers[fulfiller].name}</MDBListGroupItem>)}
                        <MDBListGroupItem className="default-color white-text text-center" onClick={this.toggleTabs("newFulfiller")}><MDBIcon icon="plus" /></MDBListGroupItem>
                    </MDBListGroup>
               </MDBCol>
                <MDBCol sm="8">
                <MDBTabContent className="card p-0" activeItem={this.state.activeTab}>
                <CardHeader color="stylish-color">{this.state.activeTab.charAt(0).toUpperCase() + this.state.activeTab.slice(1)}</CardHeader>
                <MDBTabPane tabId="bps">
                   <Fulfiller_BPS />
                    
                </MDBTabPane>
                <MDBTabPane tabId="newFulfiller">
                   <New_Fulfiller activeFulfillers={this.state.activeFulfillers} toggleFulfiller={this.toggleTabs} />
                </MDBTabPane>
                </MDBTabContent>
                </MDBCol>
           </MDBRow>
        </section>
     );
}
  
};


const mapStateToProps = (state, ownProps) => ({
    fulfillers: state.fulfillers,
    ...ownProps
})

 export default connect(
    mapStateToProps, null
 )(Fulfillment)