import { Component } from "react";
import { 
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    ListGroup,
    ListGroupItem,
    MDBIcon,
    Input,
    Table,
    TableBody,
    TableHead,
    Button,
    MDBInput,
    MDBPopover,
    MDBPopoverHeader,
    MDBPopoverBody,
    MDBBtn
 } from "mdbreact";
import {PricingAPI} from "../../Api/connect";
import CreateRuleModal from "../../components/pricing/rules/CreateRule";
import EditRuleModal from "../../components/pricing/rules/EditRule";
import ImportProductsModal from "../../components/products/ImportProducts";
import Images from "../../components/images";

export default class PricingRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pricingRules: [],
            selectedRules: [],
            selectedRule: {},
            qs: {
                limit: 20
            },
            modals: {
                createRule: {
                    isOpen: false
                },
                editRule: {
                    isOpen: false
                },
                EditAttribute: {
                    isOpen: false
                },
                importProducts: {
                    isOpen: false
                }
            }
        }
    }

    async componentDidMount() {
        this.getRules(this.state.qs);
    }

    async getRules(qs) {
        try {
            const pricingRules = await PricingAPI.getPricingRules(qs);
            this.setState({
                pricingRules,
                pagination: {
                    firstItem: pricingRules[0].id,
                    lastItem: pricingRules[pricingRules.length - 1].id
                }
            })
        } catch (error) {
            console.log(error)
        } 
    }

    deleteRule = async id => {
        try {
            await PricingAPI.deletePricingRule(null, {
                rule: {
                    id
                }
            });
            this.getRules()
        } catch (error) {
            console.log(error)
        } 
    }


    
    selectRule = (e) => {
        const id = e.target.id;
        if (this.state.selectedRules.indexOf(parseInt(id)) >= 0) {
            this.setState(state => ({
                selectedRules: state.selectedRules.filter(pId => pId !== parseInt(id)),
                selectedRule: ""
            }))
        } else {
            this.setState(state => ({
                selectedRules: [...state.selectedRules, parseInt(id)],
                selectedRule: state.pricingRules.filter(pR => pR.id === parseInt(id))[0]
            }))
        }
    }

    editRule = id => {
        this.selectRule({target: {id}});
        this.setState({
            modals: {
                createRule: {
                    isOpen: false
                },
                editRule: {
                    isOpen: true
                },
                EditAttribute: {
                    isOpen: false
                },
                importProducts: {
                    isOpen: false
                }
            }
        })
    }
    
    renderRules(rules) {

        return rules.map(rule => {
            const d = new Date().getTime();
            const isactive = rule.timing.from < d && (rule.timing.to ? rule.timing.to > d : true);
            return ({
                check: <Input label=" " type="checkbox" checked={this.state.selectedRules.indexOf(rule.id) >= 0 ? "checked" : false} id={rule.id.toString()} onChange={this.selectRule} />,
                name: rule.name,
                marketplace: Images[rule.marketplace.id],
                products: rule.products ? rule.products.length : 0,
                active: <span style={{color: isactive ? "green": "red"}}><MDBIcon icon="circle" /></span>,
                action: <span class="actionButtons"><MDBIcon far icon="edit" onClick={() => this.editRule(rule.id)} />
                <MDBPopover
                placement="bottom"
                popover
                clickable
                id={"popoverDelete" + rule.id}
                >
                <MDBBtn flat><MDBIcon className="ml-4 red-text" icon="trash" /></MDBBtn>
                <div>
                    <MDBPopoverHeader>Confirm</MDBPopoverHeader>
                    <MDBPopoverBody>
                    <MDBBtn color="red" onClick={() => this.deleteRule(rule.id)}>Delete</MDBBtn>
                    </MDBPopoverBody>
                </div>
                </MDBPopover>
               </span>
        })
        });

    }

    toggleModal = (id) => {
        if (this.state.modals[id].isOpen) {
            setTimeout(async () => {    
                    this.getRules(this.state.qs);
            }, 500)
        }
        this.setState(state => ({
            modals: {
                ...state.modals,
                [id]: {
                    ...!state.modals[id],
                    isOpen: !state.modals[id].isOpen
                }
            }
        }));
    }

    render() {

        const data_checkboxes = {
            columns: [
              {
                'label': '#',
                'field': 'check',
                'sort': 'asc'
              },
              {
                'label': 'Name',
                'field': 'name',
                'sort': 'asc'
              },
              {
                'label': 'Marketplace',
                'field': 'marketplace',
                'sort': 'asc'
              },
              {
                'label': 'Products',
                'field': 'products',
                'sort': 'asc'
              },
              {
                'label': 'Active',
                'field': 'active',
                'sort': 'asc'
              },
              {
                'label': 'Action',
                'field': 'action',
                'sort': 'asc'
              }
            ]
          };

        return (
            <section>
         <Row>
             <Col md="12">
             <Card>
                <CardHeader color="stylish-color">Actions</CardHeader>
                <CardBody>
                <Row>
                    <Col>
                        <ListGroup style={{cursor: "pointer"}}>
                            <ListGroupItem className="d-flex justify-content-between align-items-center" onClick={() => this.toggleModal("createRule")}>
                                Create <MDBIcon icon="plus" />
                            </ListGroupItem>
                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                Delete <MDBIcon icon="remove" />
                            </ListGroupItem>
                        </ListGroup>
                    </Col>
                    <Col>
                        <ListGroup style={{cursor: "pointer"}}>
                            <ListGroupItem className="d-flex justify-content-between align-items-center" onClick={() => this.toggleModal("editProduct")}>
                                Edit <MDBIcon icon="edit" />
                            </ListGroupItem>
                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                Copy<MDBIcon icon="copy" />
                            </ListGroupItem>
                        </ListGroup>
                    </Col>
                    <Col>
                        <ListGroup style={{cursor: "pointer"}}>
                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                Edit with blueprint <MDBIcon icon="edit" />
                            </ListGroupItem>
                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                Edit history <MDBIcon icon="history" />
                            </ListGroupItem>
                        </ListGroup>
                    </Col>
                    <Col>
                        <ListGroup style={{cursor: "pointer"}}>
                            <ListGroupItem className="d-flex justify-content-between align-items-center" onClick={() => this.toggleModal("importProducts")}>
                                Import <MDBIcon icon="file-import" />
                            </ListGroupItem>
                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                Export <MDBIcon icon="file-export" />
                            </ListGroupItem>
                        </ListGroup>
                    </Col>
                </Row>
                </CardBody>
            </Card>
             </Col>
         </Row>
                <Row className="py-3">
               <Col md="12">
                 <Card>
                   <CardBody>
                   <Row>
                   <Col size="1">
                        <Button outline size="sm" className="mt-4" onClick={() => this.getRules(this.state.searchFilters)}>
                            <MDBIcon icon="sync" className="mr-0" />
                        </Button>
                    </Col>
                       <Col size="3">
                          
                       </Col>
                       <Col size="8">
                            <Row>
                                <Col size="10">
                                    <MDBInput label="Search"
                                    id="search"
                                    onKeyPress={this.search}
                                    />
                                </Col>
                                <Col size="2">
                                <Button outline size="sm" className="mt-4" onClick={this.search}>
                                    <MDBIcon icon="search" className="mr-0" />
                                </Button>
                                </Col>
                            </Row>
                       </Col>
                   </Row>
                    <Table responsive className="text-center">
                        <TableHead  color="teal" textWhite columns={data_checkboxes.columns} />
                        <TableBody rows={this.state.pricingRules && this.renderRules(this.state.pricingRules)} />
                        <Row>
                        <Col size="12">
                            <span onClick={this.prevPage} style={{float: "left"}}>Previous Page</span>
                            <span onClick={this.nextPage} style={{float: "right"}}>Next Page</span>
                        </Col>
                        </Row>
                    </Table>
                   </CardBody>
                 </Card>
               </Col>
             </Row>
             <CreateRuleModal isOpen={this.state.modals.createRule.isOpen} toggle={this.toggleModal} />
             <EditRuleModal isOpen={this.state.modals.editRule.isOpen} toggle={this.toggleModal} rule={this.state.selectedRule} />
             <ImportProductsModal isOpen={this.state.modals.importProducts.isOpen} toggle={this.toggleModal} settings={this.state.settings} />
            </section>
         );
    }
  
};
