import { Component, useEffect, useState, useCallback } from 'react';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBInput, MDBInputGroup, MDBNav, MDBNavItem, MDBNavLink, MDBRow } from "mdbreact";
import {SettingsAPI} from "../../Api/connect";

export default function Accounting() {
    // const getData = async () => {
    //     const data = await SettingsAPI.getSettingsAccounting().then(r => flattenObject(r))
    //     console.log(data)
    //     setInputs(data)
    // }
    const [inputs,setInputs] = useState({});
    useEffect(() => {
        SettingsAPI.getSettingsAccounting().then(r => setInputs(flattenObject(r)))
    }, inputs)
    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    const request = async () => {
        const data = unflatten(inputs)
        try {
            await SettingsAPI.updateSettingsAccounting(null, data)
        } catch (error) {
            console.log(error)
        }
        
    }
    return <>
        <MDBNav color="rgba-grey-slight">
            <MDBNavItem>
                <MDBNavLink active  to="">General</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
                <MDBNavLink to="">Accounts</MDBNavLink>
            </MDBNavItem>
        </MDBNav>
        <MDBCard className="mt-5">
            <MDBCardHeader>General Accounts</MDBCardHeader>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol>
                    <MDBInputGroup
                        material
                        labelClassName="mb-0 ml-2"
                        containerClassName="mb-3 mt-0"
                        inputs={
                            <>
                                <MDBInput label="Bank" type="text" background name="default.bank" value={inputs["default.bank"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Geldtransit" type="text" background name="default.transit" value={inputs["default.transit"] || ''} onChange={handleChange} />  
                                <MDBInput label="Erlöskonto" type="text" background name="default.earnings" value={inputs["default.earnings"] || ''} onChange={handleChange} /> 
                            </>
                        }
                    />  
                    <MDBBtn onClick={request}>Save</MDBBtn> 
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
        <MDBCard className="mt-5">
            <MDBCardHeader>Payment providers</MDBCardHeader>
            <MDBCardBody>
                	<h5>PayPal</h5>
                <MDBRow>
                    <MDBCol>
                    <MDBInputGroup
                        material
                        labelClassName="mb-0 ml-2"
                        containerClassName="mb-3 mt-0"
                        inputs={
                            <>
                                <MDBInput label="Finanz" type="text" background name="paypal.financial" value={inputs["paypal.financial"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Nebenkosten Geldverkehr" type="text" background name="paypal.fees" value={inputs["paypal.fees"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Erlöse" type="text" background name="paypal.earnings" value={inputs["paypal.earnings"] || ''} onChange={handleChange} /> 
                            </>
                        }
                    />
                    </MDBCol>
                </MDBRow>
                <h5>Amazon - DE</h5>
                <MDBRow>
                    <MDBCol>
                    <MDBInputGroup
                        material
                        label="Allgemeine Konten"
                        labelClassName="mb-0 ml-2"
                        containerClassName="mb-3 mt-0 nowrap"
                        inputs={
                            <>
                                <MDBInput label="Finanz" type="text" background name="amazon.de.financial" value={inputs["amazon.de.financial"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Gebühren" type="text" background name="amazon.de.fees" value={inputs["amazon.de.fees"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Sammeldebitoren" type="text" background name="amazon.de.debtors" value={inputs["amazon.de.debtors"] || ''} onChange={handleChange} />
                                <MDBInput label="Verbringung" type="text" background name="amazon.de.stock" value={inputs["amazon.de.stock"] || ''} onChange={handleChange} /> 
                            </>
                        }
                            />
                    </MDBCol>
                    <MDBCol>
                    <MDBInputGroup
                        material
                        label="Erlöskonten"
                        labelClassName="mb-0 ml-2"
                        containerClassName="mb-3 mt-0"
                        inputs={
                            <>
                                <MDBInput label="Mit Umsatzsteuer" type="text" background name="amazon.de.earnings.withTax" value={inputs["amazon.de.earnings.withTax"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Unternehmen mit UstId" type="text" background name="amazon.de.earnings.businessWithTaxId" value={inputs["amazon.de.earnings.businessWithTaxId"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Drittländer" type="text" background name="amazon.de.earnings.thirdCountries" value={inputs["amazon.de.earnings.thirdCountries"] || ''} onChange={handleChange} /> 
                            </>
                        }
                            />
                        <MDBInputGroup
                            material
                            label="Stornierungen / Erlösschmälerung"
                            labelClassName="mb-0 ml-2"
                            containerClassName="mb-3 mt-0"
                            inputs={
                                <>
                                    <MDBInput label="Mit Umsatzsteuer" type="text" background name="amazon.de.refunds.withTax" value={inputs["amazon.de.refunds.withTax"] || ''} onChange={handleChange} /> 
                                    <MDBInput label="Unternehmen mit UstId" type="text" background name="amazon.de.refunds.businessWithTaxId" value={inputs["amazon.de.refunds.businessWithTaxId"] || ''} onChange={handleChange} /> 
                                    <MDBInput label="Drittländer" type="text" background name="amazon.de.refunds.thirdCountries" value={inputs["amazon.de.refunds.thirdCountries"] || ''} onChange={handleChange} /> 
                                </>
                            }
                        />
                    </MDBCol>
                </MDBRow>
                <h5>Amazon - FR</h5>
                <MDBRow>
                    <MDBCol>
                    <MDBInputGroup
                        material
                        label="Allgemeine Konten"
                        labelClassName="mb-0 ml-2"
                        containerClassName="mb-3 mt-0 nowrap"
                        inputs={
                            <>
                                <MDBInput label="Finanz" type="text" background name="amazon.fr.financial" value={inputs["amazon.fr.financial"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Gebühren" type="text" background name="amazon.fr.fees" value={inputs["amazon.fr.fees"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Sammeldebitoren" type="text" background name="amazon.fr.debtors" value={inputs["amazon.fr.debtors"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Verbringung" type="text" background name="amazon.fr.stock" value={inputs["amazon.fr.stock"] || ''} onChange={handleChange} /> 
                            </>
                        }
                            />
                    </MDBCol>
                    <MDBCol>
                    <MDBInputGroup
                        material
                        label="Erlöskonten"
                        labelClassName="mb-0 ml-2"
                        containerClassName="mb-3 mt-0"
                        inputs={
                            <>
                                <MDBInput label="Mit Umsatzsteuer" type="text" background name="amazon.fr.earnings.withTax" value={inputs["amazon.fr.earnings.withTax"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Unternehmen mit UstId" type="text" background name="amazon.fr.earnings.businessWithTaxId" value={inputs["amazon.fr.earnings.businessWithTaxId"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Drittländer" type="text" background name="amazon.fr.earnings.thirdCountries" value={inputs["amazon.fr.earnings.thirdCountries"] || ''} onChange={handleChange} /> 
                            </>
                        }
                            />
                    </MDBCol>
                </MDBRow>
                <h5>Amazon - IT</h5>
                <MDBRow>
                    <MDBCol>
                    <MDBInputGroup
                        material
                        label="Allgemeine Konten"
                        labelClassName="mb-0 ml-2"
                        containerClassName="mb-3 mt-0 nowrap"
                        inputs={
                            <>
                                <MDBInput label="Finanz" type="text" background name="amazon.it.financial" value={inputs["amazon.it.financial"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Gebühren" type="text" background name="amazon.it.fees" value={inputs["amazon.it.fees"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Sammeldebitoren" type="text" background name="amazon.it.debtors" value={inputs["amazon.it.debtors"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Verbringung" type="text" background name="amazon.it.stock" value={inputs["amazon.it.stock"] || ''} onChange={handleChange} /> 
                            </>
                        }
                            />
                    </MDBCol>
                    <MDBCol>
                    <MDBInputGroup
                        material
                        label="Erlöskonten"
                        labelClassName="mb-0 ml-2"
                        containerClassName="mb-3 mt-0"
                        inputs={
                            <>
                                <MDBInput label="Mit Umsatzsteuer" type="text" background name="amazon.it.earnings.withTax" value={inputs["amazon.it.earnings.withTax"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Unternehmen mit UstId" type="text" background name="amazon.it.earnings.businessWithTaxId" value={inputs["amazon.it.earnings.businessWithTaxId"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Drittländer" type="text" background name="amazon.it.earnings.thirdCountries" value={inputs["amazon.it.earnings.thirdCountries"] || ''} onChange={handleChange} /> 
                            </>
                        }
                            />
                    </MDBCol>
                </MDBRow>
                <h5>Amazon - ES</h5>
                <MDBRow>
                    <MDBCol>
                    <MDBInputGroup
                        material
                        label="Allgemeine Konten"
                        labelClassName="mb-0 ml-2"
                        containerClassName="mb-3 mt-0 nowrap"
                        inputs={
                            <>
                                <MDBInput label="Finanz" type="text" background name="amazon.es.financial" value={inputs["amazon.es.financial"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Gebühren" type="text" background name="amazon.es.fees" value={inputs["amazon.es.fees"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Sammeldebitoren" type="text" background name="amazon.es.debtors" value={inputs["amazon.es.debtors"] || ''} onChange={handleChange} />
                                <MDBInput label="Verbringung" type="text" background name="amazon.es.stock" value={inputs["amazon.es.stock"] || ''} onChange={handleChange} />  
                            </>
                        }
                            />
                    </MDBCol>
                    <MDBCol>
                    <MDBInputGroup
                        material
                        label="Erlöskonten"
                        labelClassName="mb-0 ml-2"
                        containerClassName="mb-3 mt-0"
                        inputs={
                            <>
                                <MDBInput label="Mit Umsatzsteuer" type="text" background name="amazon.es.earnings.withTax" value={inputs["amazon.es.earnings.withTax"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Unternehmen mit UstId" type="text" background name="amazon.es.earnings.businessWithTaxId" value={inputs["amazon.es.earnings.businessWithTaxId"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Drittländer" type="text" background name="amazon.es.earnings.thirdCountries" value={inputs["amazon.es.earnings.thirdCountries"] || ''} onChange={handleChange} /> 
                            </>
                        }
                            />
                    </MDBCol>
                </MDBRow>
                <h5>Amazon - NL</h5>
                <MDBRow>
                    <MDBCol>
                    <MDBInputGroup
                        material
                        label="Allgemeine Konten"
                        labelClassName="mb-0 ml-2"
                        containerClassName="mb-3 mt-0 nowrap"
                        inputs={
                            <>
                                <MDBInput label="Finanz" type="text" background name="amazon.nl.financial" value={inputs["amazon.nl.financial"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Gebühren" type="text" background name="amazon.nl.fees" value={inputs["amazon.nl.fees"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Sammeldebitoren" type="text" background name="amazon.nl.debtors" value={inputs["amazon.nl.debtors"] || ''} onChange={handleChange} />
                                <MDBInput label="Verbringung" type="text" background name="amazon.nl.stock" value={inputs["amazon.nl.stock"] || ''} onChange={handleChange} />  
                            </>
                        }
                            />
                    </MDBCol>
                    <MDBCol>
                    <MDBInputGroup
                        material
                        label="Erlöskonten"
                        labelClassName="mb-0 ml-2"
                        containerClassName="mb-3 mt-0"
                        inputs={
                            <>
                                <MDBInput label="Mit Umsatzsteuer" type="text" background name="amazon.nl.earnings.withTax" value={inputs["amazon.nl.earnings.withTax"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Unternehmen mit UstId" type="text" background name="amazon.nl.earnings.businessWithTaxId" value={inputs["amazon.nl.earnings.businessWithTaxId"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Drittländer" type="text" background name="amazon.nl.earnings.thirdCountries" value={inputs["amazon.nl.earnings.thirdCountries"] || ''} onChange={handleChange} /> 
                            </>
                        }
                            />
                    </MDBCol>
                </MDBRow>
                <h5>Amazon - SE</h5>
                <MDBRow>
                    <MDBCol>
                    <MDBInputGroup
                        material
                        label="Allgemeine Konten"
                        labelClassName="mb-0 ml-2"
                        containerClassName="mb-3 mt-0 nowrap"
                        inputs={
                            <>
                                <MDBInput label="Finanz" type="text" background name="amazon.se.financial" value={inputs["amazon.se.financial"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Gebühren" type="text" background name="amazon.se.fees" value={inputs["amazon.se.fees"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Sammeldebitoren" type="text" background name="amazon.se.debtors" value={inputs["amazon.se.debtors"] || ''} onChange={handleChange} />
                                <MDBInput label="Verbringung" type="text" background name="amazon.se.stock" value={inputs["amazon.se.stock"] || ''} onChange={handleChange} /> 
                            </>
                        }
                            />
                    </MDBCol>
                    <MDBCol>
                    <MDBInputGroup
                        material
                        label="Erlöskonten"
                        labelClassName="mb-0 ml-2"
                        containerClassName="mb-3 mt-0"
                        inputs={
                            <>
                                <MDBInput label="Mit Umsatzsteuer" type="text" background name="amazon.se.earnings.withTax" value={inputs["amazon.se.earnings.withTax"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Unternehmen mit UstId" type="text" background name="amazon.se.earnings.businessWithTaxId" value={inputs["amazon.se.earnings.businessWithTaxId"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Drittländer" type="text" background name="amazon.se.earnings.thirdCountries" value={inputs["amazon.se.earnings.thirdCountries"] || ''} onChange={handleChange} /> 
                            </>
                        }
                            />
                    </MDBCol>
                </MDBRow>
                <h5>Amazon - UK</h5>
                <MDBRow>
                    <MDBCol>
                    <MDBInputGroup
                        material
                        label="Allgemeine Konten"
                        labelClassName="mb-0 ml-2"
                        containerClassName="mb-3 mt-0 nowrap"
                        inputs={
                            <>
                                <MDBInput label="Finanz" type="text" background name="amazon.uk.financial" value={inputs["amazon.uk.financial"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Gebühren" type="text" background name="amazon.uk.fees" value={inputs["amazon.uk.fees"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Sammeldebitoren" type="text" background name="amazon.uk.debtors" value={inputs["amazon.uk.debtors"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Verbringung" type="text" background name="amazon.uk.stock" value={inputs["amazon.uk.stock"] || ''} onChange={handleChange} /> 
                            </>
                        }
                            />
                    </MDBCol>
                    <MDBCol>
                    <MDBInputGroup
                        material
                        label="Erlöskonten"
                        labelClassName="mb-0 ml-2"
                        containerClassName="mb-3 mt-0"
                        inputs={
                            <>
                                <MDBInput label="Mit Umsatzsteuer" type="text" background name="amazon.uk.earnings.withTax" value={inputs["amazon.uk.earnings.withTax"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Unternehmen mit UstId" type="text" background name="amazon.uk.earnings.businessWithTaxId" value={inputs["amazon.uk.earnings.businessWithTaxId"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Drittländer" type="text" background name="amazon.uk.earnings.thirdCountries" value={inputs["amazon.uk.earnings.thirdCountries"] || ''} onChange={handleChange} /> 
                            </>
                        }
                            />
                    </MDBCol>
                </MDBRow>
                <h5>Amazon - PL</h5>
                <MDBRow>
                    <MDBCol>
                    <MDBInputGroup
                        material
                        label="Allgemeine Konten"
                        labelClassName="mb-0 ml-2"
                        containerClassName="mb-3 mt-0 nowrap"
                        inputs={
                            <>
                                <MDBInput label="Finanz" type="text" background name="amazon.pl.financial" value={inputs["amazon.pl.financial"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Gebühren" type="text" background name="amazon.pl.fees" value={inputs["amazon.pl.fees"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Sammeldebitoren" type="text" background name="amazon.pl.debtors" value={inputs["amazon.pl.debtors"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Verbringung" type="text" background name="amazon.pl.stock" value={inputs["amazon.pl.stock"] || ''} onChange={handleChange} /> 
                            </>
                        }
                            />
                    </MDBCol>
                    <MDBCol>
                    <MDBInputGroup
                        material
                        label="Erlöskonten"
                        labelClassName="mb-0 ml-2"
                        containerClassName="mb-3 mt-0"
                        inputs={
                            <>
                                <MDBInput label="Mit Umsatzsteuer" type="text" background name="amazon.pl.earnings.withTax" value={inputs["amazon.pl.earnings.withTax"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Unternehmen mit UstId" type="text" background name="amazon.pl.earnings.businessWithTaxId" value={inputs["amazon.pl.earnings.businessWithTaxId"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Drittländer" type="text" background name="amazon.pl.earnings.thirdCountries" value={inputs["amazon.pl.earnings.thirdCountries"] || ''} onChange={handleChange} /> 
                            </>
                        }
                            />
                    </MDBCol>
                </MDBRow>
                <h5>Amazon - CZE</h5>
                <MDBRow>
                    <MDBCol>
                    <MDBInputGroup
                        material
                        label="Allgemeine Konten"
                        labelClassName="mb-0 ml-2"
                        containerClassName="mb-3 mt-0 nowrap"
                        inputs={
                            <>
                                <MDBInput label="Finanz" type="text" background name="amazon.cze.financial" value={inputs["amazon.cze.financial"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Gebühren" type="text" background name="amazon.cze.fees" value={inputs["amazon.cze.fees"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Sammeldebitoren" type="text" background name="amazon.cze.debtors" value={inputs["amazon.cze.debtors"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Verbringung" type="text" background name="amazon.cze.stock" value={inputs["amazon.cze.stock"] || ''} onChange={handleChange} /> 
                            </>
                        }
                            />
                    </MDBCol>
                    <MDBCol>
                    <MDBInputGroup
                        material
                        label="Erlöskonten"
                        labelClassName="mb-0 ml-2"
                        containerClassName="mb-3 mt-0"
                        inputs={
                            <>
                                <MDBInput label="Mit Umsatzsteuer" type="text" background name="amazon.cze.earnings.withTax" value={inputs["amazon.cze.earnings.withTax"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Unternehmen mit UstId" type="text" background name="amazon.cze.earnings.businessWithTaxId" value={inputs["amazon.cze.earnings.businessWithTaxId"] || ''} onChange={handleChange} /> 
                                <MDBInput label="Drittländer" type="text" background name="amazon.cze.earnings.thirdCountries" value={inputs["amazon.cze.earnings.thirdCountries"] || ''} onChange={handleChange} /> 
                            </>
                        }
                            />
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    </>
}

function unflatten(data) {
    var result = {}
    for (var i in data) {
      var keys = i.split('.')
      keys.reduce(function(r, e, j) {
        return r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 == j ? data[i] : {}) : [])
      }, result)
    }
    return result
  }

  const flattenObject = function(ob) {
	var toReturn = {};
	
	for (var i in ob) {
		if (!ob.hasOwnProperty(i)) continue;
		
		if ((typeof ob[i]) == 'object') {
			var flatObject = flattenObject(ob[i]);
			for (var x in flatObject) {
				if (!flatObject.hasOwnProperty(x)) continue;
				
				toReturn[i + '.' + x] = flatObject[x];
			}
		} else {
			toReturn[i] = ob[i];
		}
	}
	return toReturn;
};