import React, { Fragment, useState, useEffect } from 'react'
import { 
    MDBRow,
    MDBCol,
    MDBCollapse ,
    MDBIcon, MDBBtn, MDBInput, MDBInputGroup, MDBCard, MDBCardTitle, MDBCardBody, MDBCardText, MDBCardImage,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBSelectV5,
    MDBContainer
 } from "mdbreact";
 import { OauthSender } from 'react-oauth-flow';
 import {API} from 'aws-amplify';

export default function Marketplace_Ebay(props) {

    // Existing connections
    const [stores, setStores] = useState({})
    // For creating new connection
    const [storeName, setStoreName] = useState("")

    // Configure a connected store
    const [selectedStore, setSelectedStore] = useState({})
    const [isModalOpen, setModalOpen] = useState(false)
    

    useEffect(() => {
        getStores()
    }, [props.isActive])

    const getStores = async () => {
        try {
            const resEbaySettings = await API.get("ConnectApi", "settings/component", {queryStringParameters: {component: "ebay"}})
            if(resEbaySettings.stores) setStores(resEbaySettings.stores)
        } catch (error) {
            console.log(error)
        }
    }


    const updateSyncInterval = async (type, value, storeName) => {
        console.log(value)
        if(isModalOpen && value.toString() !== selectedStore.syncintervals[type].toString()) {
            console.log("updating sync interval for " + type + " to: " + value)
            try {
                await API.post("ConnectApi", "settings/ebay/update", {queryStringParameters: {storeName: storeName}, body: {interval: {type, value}}})
                setStores({
                    ...stores,
                    [storeName]: {
                        ...stores[storeName],
                        syncintervals: {
                            ...stores[storeName].syncintervals,
                            [type]: parseInt(value)
                        }
                    }
                })
                setSelectedStore({
                    ...selectedStore,
                    syncintervals: {
                        ...selectedStore.syncintervals,
                        [type]: parseInt(value)
                    }
                })
            } catch (error) {
                console.log(error)
            }
        }
    }
    

  return (
    <Fragment>
        <MDBContainer className="my-2 my-md-4">
            {Object.keys(stores).length > 0 && <>
                <h4>Connected stores</h4>
                <MDBRow className="mt-4">
                {Object.keys(stores).map(storeHandle => <MDBCol key={storeHandle} size="12" md="6">
                    <MDBCard
                    onClick={() => {
                        setSelectedStore({handle: storeHandle, ...stores[storeHandle]});
                        setModalOpen(true)
                    }}
                    >
                        <MDBCardBody>
                            <MDBCardTitle>{storeHandle}</MDBCardTitle>
                            <MDBCardText>
                                Connected: {new Date(stores[storeHandle].connectedTime).toLocaleDateString()}<br></br>
                            </MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>)}
                </MDBRow>
            </>}
            <h4 className="mt-5">Connect new store</h4>
            <OauthSender
                authorizeUrl="https://auth.ebay.com/oauth2/authorize"
                clientId="ChannelH-ChannelH-PRD-88be2b6e8-a3145214"
                redirectUri="ChannelHive_Mar-ChannelH-Channe-kdhgejvs"
                state={{ from: '/settings' }}
                args={{scope: "https://api.ebay.com/oauth/api_scope https://api.ebay.com/oauth/api_scope/sell.marketing.readonly https://api.ebay.com/oauth/api_scope/sell.marketing https://api.ebay.com/oauth/api_scope/sell.inventory.readonly https://api.ebay.com/oauth/api_scope/sell.inventory https://api.ebay.com/oauth/api_scope/sell.account.readonly https://api.ebay.com/oauth/api_scope/sell.account https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly https://api.ebay.com/oauth/api_scope/sell.fulfillment https://api.ebay.com/oauth/api_scope/sell.analytics.readonly https://api.ebay.com/oauth/api_scope/sell.finances https://api.ebay.com/oauth/api_scope/sell.payment.dispute https://api.ebay.com/oauth/api_scope/commerce.identity.readonly https://api.ebay.com/oauth/api_scope/commerce.notification.subscription https://api.ebay.com/oauth/api_scope/commerce.notification.subscription.readonly"}}
                render={({ url }) => <a href={url}><MDBBtn color="primary">Sign in to grant access</MDBBtn></a>}
            />
        </MDBContainer>
                  
        <MDBModal
    isOpen={isModalOpen}
    toggle={() => {
      setModalOpen(false);
    }}
    centered
    size="lg">

    <MDBModalBody>
    <MDBCard style={{margin: "-1rem"}}>
    <MDBCardImage
        className='teal white-text d-flex justify-content-center align-items-center flex-column p-2 mb-4'
        tag='div'
      >Configure store {selectedStore.handle}.myebay.com
      </MDBCardImage>
    {selectedStore?.handle && 
        <>
        <h6 className="ml-3 ml-md-4 font-weight-bold">Sync intervals</h6>
        <MDBRow>
            <MDBCol size="6" className="text-center">
                <p className="mt-md-4">Orders</p>
            </MDBCol>
            <MDBCol size="6" className="px-1 px-md-5">
            <MDBSelectV5
                style={{width: "100%"}}
                getValue={(value) => {
                    if(value.length > 0) updateSyncInterval("orders", value[0], selectedStore.handle)
                }}
                options={[
                {
                    value: "0",
                    text: "Set order sync interval",
                    disabled: true
                },
                {
                    value: "10",
                    text: "Every 10 Minutes"
                },
                {
                    value: "20",
                    text: "Every 20 Minutes"
                },
                {
                    value: "30",
                    text: "Every 30 Minutes"
                },
                {
                    value: "60",
                    text: "Every 60 Minutes"
                }
                
                ]}
                selected={selectedStore.syncintervals?.orders ? "Every "+ selectedStore.syncintervals.orders +" Minutes" : "Set order sync interval"}
            />
            </MDBCol>
            <MDBCol size="6" className="text-center">
                <p className="mt-md-4">Stock</p>
            </MDBCol>
            <MDBCol size="6" className="px-1 px-md-5">
            <MDBSelectV5
                style={{width: "100%"}}
                getValue={(value) => {
                    if(value.length > 0) updateSyncInterval("stock", value[0], selectedStore.handle)
                }}
                options={[
                {
                    value: "0",
                    text: "Set stock sync interval",
                    disabled: true
                },
                {
                    value: "10",
                    text: "Every 10 Minutes"
                },
                {
                    value: "20",
                    text: "Every 20 Minutes"
                },
                {
                    value: "30",
                    text: "Every 30 Minutes"
                },
                {
                    value: "60",
                    text: "Every 60 Minutes"
                }
                
                ]}
                selected={selectedStore.syncintervals?.orders ? "Every "+ selectedStore.syncintervals.stock +" Minutes" : "Set stock sync interval"}
            />
            </MDBCol>
        </MDBRow>
        </>
        
    }
    
      
    </MDBCard>
    </MDBModalBody>
    <MDBModalFooter>
      <MDBBtn
        outline color="danger"
        onClick={() => { 
            setModalOpen(false);
      }}>Close</MDBBtn>
    </MDBModalFooter>
  </MDBModal>
    </Fragment>
  )
}
