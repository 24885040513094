import {Auth, API, Storage} from "aws-amplify";
import {v4 as uuid} from "uuid";

export default {
    post: async (body, path, apiName, qs) => {
        return API.post(apiName, path, {headers: {Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()}, queryStringParameters: qs})
    },
    get: async (body, path, apiName, qs) => {
        return API.get(apiName, path, {headers: {Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()}, queryStringParameters: qs})
    },
    uploadImg: async (img, mediaPath, name) => {
        const account = (await Auth.currentSession()).getIdToken().decodePayload(["cognito:username"]);
        const filename = name ? name : img.type === "image/png" ? uuid() + ".png" : img.type === "image/jpeg" && uuid() + ".jpg"
        return await Storage.put(account + "/" + filename, img)
        .then(r => {
            const imageURL = mediaPath + account + "/" + filename;
            return imageURL;
        })
    }
}