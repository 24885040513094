import {product as AmazonProduct} from "./amazon";
import {product as EbayProduct} from "./ebay";
import {product as ShopwareProduct} from "./shopware";
import {product as IdealoProduct} from "./idealo_direkt";
import {product as Shopify} from "./shopify";

export default {
    product: {
        amazon: AmazonProduct,
        ebay: EbayProduct,
        shopware: ShopwareProduct,
        idealo_direkt: IdealoProduct,
        shopify: Shopify
    }
}