import {API} from "aws-amplify";

const apiName = "ConnectApi";
const paths ={
    ebay: {
        exchangeToken: "oauth/ebay/exchangetoken"
    },
    shopify: {
        exchangeToken: "oauth/shopify/exchangetoken"
    }
}


// eBay

const eBayExchangeToken = async (qs, body) => {
    return API.post(apiName, paths.ebay.exchangeToken, {queryStringParameters: qs, body})
}

// Shopify

const shopifyExchangeToken = async (qs, body) => {
    return API.post(apiName, paths.shopify.exchangeToken, {queryStringParameters: qs, body})
}


// exports

export default {
    ebay: {
        exchangeToken: eBayExchangeToken
    },
    shopify: {
        exchangeToken: shopifyExchangeToken
    }
}