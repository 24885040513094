import { Component, useState, useEffect } from 'react';
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  Table,
  TableHead,
  TableBody,
  MDBInput,
  MDBDatePicker,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInputGroup
} from 'mdbreact';
import {OrdersAPI} from "../../Api/connect";
import {API} from 'aws-amplify';


export default function ValidateAddress(props) {
  const [isLoading, setIsLoading] = useState({validate: false, update: false});
  const [updatedRes, setUpdatedRes] = useState({received: false, succeded: false})
  const [validationResult, setValidationResult] = useState({})
  const [newAddress, setNewAddress] = useState(props.order?.addresses?.shipping || {})
  useEffect(() => {
    if(props.order?.addresses?.shipping) setNewAddress(props.order.addresses.shipping)
  }, [props.order])

  const executeValidate = async() => {
    if(isLoading.validate) return;
    setIsLoading({validate: true, update: isLoading.update});
    const body = {
      orderIds: [props.order.id],
      account: props.order.account,
      updateOrders: false
    };
    try {
      const res = await OrdersAPI.validateAddress(body);

      setValidationResult({
        succeded: res.completeOrders.length > 0,
        received: true,
        address: res.completeOrders[0].address
      })
      if(res.completeOrders.length > 0) {
        setNewAddress(res.completeOrders[0].address)
      }
    } catch (error) {
      console.log(error)
    }
    setIsLoading({validate: false, update: isLoading.update});
  }

  const executeUpdate = async() => {
    if(isLoading.update) return;
    setIsLoading({validate: isLoading.validate, update: true});
    const body = {
      account: props.order.account,
      orders: [{
        account: props.order.account,
        id: props.order.id,
        updateAttributes: {
          "shippment.address": newAddress
        }
      }]
    };
    try {
      const res = await API.post("ConnectApi", "orders/update", {body})
      setUpdatedRes({received: true, succeded: true})
    } catch (error) {
      setUpdatedRes({received: true, succeded: false})
      console.log(error)
    }
    setIsLoading({validate: isLoading.validate, update: false});
  }

  const changeAddressValue = e => {
    setNewAddress({...newAddress, [e.target.id]: e.target.value})
  }
  return <MDBModal
    isOpen={props.isOpen}
    toggle={() => {
      props.toggle("addressValidation");
      setValidationResult({});
      setUpdatedRes({received: false, succeded: false})
    }}
    centered
    size="lg">

    <MDBModalBody>
    <MDBCard style={{margin: "-1rem"}}>
    <MDBCardImage
        className='teal white-text d-flex justify-content-center align-items-center flex-column p-2 mb-4'
        tag='div'
      >Address validation & correction
      </MDBCardImage>

      <MDBRow className="mx-md-5 my-md-5">
        {props.order?.addresses &&
        <>

          <MDBCol>
          <MDBCard className={validationResult.received ? (validationResult.succeded ? "z-depth-1 border-success" : "z-depth-1 border-danger") : "z-depth-1"} style={{"border-width": "2px"}}>
              <MDBCardBody>
                <MDBCardTitle>New Address</MDBCardTitle>
                <MDBCardText className="grey-text text-center">
                <MDBInputGroup
                  material
                  
                  inputs={
                    <>
                      <MDBInput noTag type="text" hint="First name" label="First name" value={newAddress.firstName} id="firstName" onChange={(e) => changeAddressValue(e)} />
                      <MDBInput noTag type="text" labelClass="ml-50" label="Last name" hint="Last name" value={newAddress.lastName} id="lastName" onChange={(e) => changeAddressValue(e)} />
                    </>
                  }
                />
                <MDBInputGroup
                  material
                  
                  inputs={
                    <>
                      <MDBInput noTag type="text" hint="Street" label="Street" value={newAddress.street} id="street" onChange={(e) => changeAddressValue(e)} />
                      <MDBInput noTag type="text" labelClass="ml-50" label="Street Number" hint="Street Number" id="streetNumber" onChange={(e) => changeAddressValue(e)} value={newAddress.streetNumber} />
                    </>
                  }
                />
                 <MDBInputGroup
                  material
                  
                  inputs={
                    <>
                      <MDBInput noTag type="text" label="Street Additional" value={newAddress.street2 ||""} id="street2" onChange={(e) => changeAddressValue(e)} />
                      <MDBInput noTag type="text" labelClass="ml-50" label="Street Additional 2" id="street3" onChange={(e) => changeAddressValue(e)} value={newAddress.street3 ||""} />
                    </>
                  }
                />
                <MDBInputGroup
                  material
                  
                  inputs={
                    <>
                      <MDBInput noTag type="text" hint="Zip Code" label="Zip Code" value={newAddress.zip} id="zip" onChange={(e) => changeAddressValue(e)} />
                      <MDBInput noTag type="text" labelClass="ml-50" label="City" hint="City" value={newAddress.city} id="city" onChange={(e) => changeAddressValue(e)} />
                    </>
                  }
                />
                 <MDBInputGroup
                  material
                  
                  inputs={
                    <>
                      <MDBInput noTag type="text" hint="Region" label="Region" value={newAddress.region} id="region" onChange={(e) => changeAddressValue(e)} />
                      <MDBInput noTag type="text" labelClass="ml-50" label="Country" hint="Country" value={newAddress.country} id="country" onChange={(e) => changeAddressValue(e)} />
                    </>
                  }
                />
                <MDBBtn
                className="float-left"
                style={{width: "200px"}}
                outline 
                onClick={() => executeValidate()}
                disabled={validationResult.received}
              >
              {isLoading.validate ?
                <div className="spinner-grow spinner-small" role="status"></div>
              :
                <span>Validate<br></br>
                <span style={{fontSize: "8px", position: "absolute", left: "30px"}}><img src="https://cdn.eu.com/ch/partnerLogos/HERE_logo.svg" height="8" alt="Here Technologies" />Powered by Here Technologies</span></span>
              }</MDBBtn>
             
                <MDBBtn
                className="float-right"
                onClick={() => executeUpdate()}
                >
                {isLoading.update ?
                  <div className="spinner-grow spinner-small white-text" role="status"></div>
                :
                  "Update"
                }</MDBBtn>
               
                <br></br>
                {updatedRes.received && (updatedRes.succeded ? <span className="green-text">Successfully updated order address</span> : <span className="red-text">Failed updating order address</span>)}
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
            
          </MDBCol>
          </>}
        </MDBRow>
    </MDBCard>

    </MDBModalBody>
    <MDBModalFooter>
      <MDBBtn
        outline
        onClick={() => { 
          props.toggle("addressValidation");
          setValidationResult({})
          setUpdatedRes({received: false, succeded: false})
      }}>Close</MDBBtn>
    </MDBModalFooter>
  </MDBModal>;
}