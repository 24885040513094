import React from 'react'
import {
    MDBRow,
    MDBCol,
    MDBInput
} from "mdbreact";

export default function EditProduct_StockShip(props) {
    const { product, handleChange } = props;
  return (
      <>
      Inventory management only possible after warehouse configuration<br></br>
    {product.stock && Object.keys(product.stock).map(handle => <span>{handle} - {product.stock[handle]}</span>)}
    <MDBInput
            label="Amazon FNSKU"
            id="amz_fnsku"
            type="text"
            value={product.amz_fnsku}
            onChange={e => handleChange("amz_fnsku", e.target.value)}
            />
{/* <MDBRow>
            <MDBCol>
             
              <MDBRow>
                <MDBCol>
                <MDBInput
                    label="Stock"
                    id="stock"
                    type="number"
                    value={this.state.product.stock}
                    onChange={this.changeVal}/>
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    label="Min Stock"
                    id="minStock"
                    type="number"
                    value={this.state.product.minStock}
                    onChange={this.changeVal}/>
                </MDBCol>
              </MDBRow>
              <h5>Dropshippers</h5>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    label="FBA Stock"
                    id="stockFBA"
                    type="number"
                    value={this.state.product.stockFBA}
                    onChange={this.changeVal}/>
                </MDBCol>
                <MDBCol>
                <MDBInput
                    label="Min FBA Stock"
                    id="minStockFBA"
                    type="number"
                    value={this.state.product.minStockFBA}
                    onChange={this.changeVal}/>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    label="Solectric Stock"
                    id="stockSolectric"
                    type="number"
                    value={this.state.product.stockSolectric}
                    onChange={this.changeVal}/>
                </MDBCol>
                <MDBCol>
                <MDBInput
                    label="Min Solectric Stock"
                    id="minStockSolectric"
                    type="number"
                    value={this.state.product.minStockSolectric}
                    onChange={this.changeVal}/>
                </MDBCol>
              </MDBRow>
              <h5>eBay</h5>
              <MDBRow>
                <MDBCol md="6">
                  <MDBInput
                    label="Refill-Stock"
                    id="stockEbayRefill"
                    type="number"
                    value={this.state.product.stockEbayRefill}
                    onChange={this.changeVal}/>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow> */}
      </>
    
  )
}
