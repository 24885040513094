export default {
    shopware: <img src="https://cdn.eu.com/connect/partnerlogos/shopware/icon/logo.png" height="40" alt="Shopware" />,
    paypal: <img src="https://cdn.eu.com/connect/partnerlogos/paypal/icon/logo.png" height="17" alt="PayPal" />,
    amazon: <img src="https://cdn.eu.com/connect/img/amazon.png" height="20" alt="Amazon" />,
    billbee: <img src="https://cdn.eu.com/connect/img/billbee.png" height="30" alt="Amazon" />,
    amazon_pay: <img src="https://images-na.ssl-images-amazon.com/images/G/01/EPSMarketingJRubyWebsite/assets/3/images/logo-amazonpay.1fae0f1e.png" height="17" alt="AmazonPay" />,
    prepayment: <img src="https://cdn.eu.com/connect/partnerlogos/prepayment/icon/logo.png" height="20" alt="Prepayment" />,
    stripe_payment_sofort: <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4VEnplEjfBTgYlRPkKBuyoDjLYrcROEm68tn28Q3s7gLAXqy-qw" height="20" alt="Stripe" />,
    stripe_payment_card: <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4VEnplEjfBTgYlRPkKBuyoDjLYrcROEm68tn28Q3s7gLAXqy-qw" height="20" alt="Stripe" />,
    stripe: <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/320px-Stripe_Logo%2C_revised_2016.svg.png" height="25" alt="Stripe" />,
    dhl: <img className="rounded-0" src="https://cdn.eu.com/connect/partnerlogos/dhl/logo/logo.jpg" height="20" alt="DHL" />,
    "Versand DHL Deutschland": <img className="rounded-0" src="https://cdn.eu.com/connect/partnerlogos/dhl/logo/logo.jpg" height="20" alt="DHL" />,
    "Versand DHL Schweiz": <img className="rounded-0" src="https://cdn.eu.com/connect/partnerlogos/dhl/logo/logo.jpg" height="20" alt="DHL" />,
    "Versand DHL EU": <img className="rounded-0" src="https://cdn.eu.com/connect/partnerlogos/dhl/logo/logo.jpg" height="20" alt="DHL" />,
    amazon_fba: <img src="https://cdn.eu.com/connect/img/fba.png" height="22" alt="Amazon FBA" />,
    ebay: <img src="https://cdn.eu.com/connect/img/ebay.png" height="20" alt="eBay" />,
    idealo_direkt: <img src="https://cdn.eu.com/connect/img/idealo_direkt.png" height="20" alt="Idealo Direkt" />,
    shopify: <img src="https://cdn.eu.com/ch/partnerLogos/shopify.png" height="20" alt="Idealo Direkt" />,
    shopify_payments: <img src="https://cdn.eu.com/ch/partnerLogos/Shopify_Pay.jpg" height="17" alt="Shopify Payments" />,
    rechnung_mit_klarna: <img src="https://cdn.eu.com/ch/partnerLogos/Klarna.jpg" height="17" alt="Klarna" />,
    buy_now_pay_later_with_klarna: <img src="https://cdn.eu.com/ch/partnerLogos/Klarna.jpg" height="17" alt="Klarna" />,
    gls: <img src="https://cdn.eu.com/ch/partnerLogos/GLS.jpg" height="17" alt="GLS" />,
    dpag: <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Deutsche_Post-Logo.svg/320px-Deutsche_Post-Logo.svg.png" height="17" alt="Deutsche Post" />,
    bps: <img src="https://cdn.eu.com/ch/partnerLogos/Acut.jpg" height="10" style={{verticalAlign: "unset"}} alt="Acut Fulfillment GmbH" />,
    squarespace: <img src="https://cdn.eu.com/ch/partnerLogos/Squarespace.jpg" height="20" alt="Squarespace" />
};

export const imageUrls = {
    shopware: "https://cdn.eu.com/connect/partnerlogos/shopware/icon/logo.png",
    paypal: "https://cdn.eu.com/connect/partnerlogos/paypal/icon/logo.png",
    amazon: "https://cdn.eu.com/connect/img/amazon.png",
    billbee: "https://cdn.eu.com/connect/img/billbee.png",
    amazon_pay: "https://images-na.ssl-images-amazon.com/images/G/01/EPSMarketingJRubyWebsite/assets/3/images/logo-amazonpay.1fae0f1e.png",
    prepayment: "https://cdn.eu.com/connect/partnerlogos/prepayment/icon/logo.png",
    stripe_payment_sofort: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4VEnplEjfBTgYlRPkKBuyoDjLYrcROEm68tn28Q3s7gLAXqy-qw",
    stripe_payment_card: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4VEnplEjfBTgYlRPkKBuyoDjLYrcROEm68tn28Q3s7gLAXqy-qw",
    stripe: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/320px-Stripe_Logo%2C_revised_2016.svg.png",
    dhl: "https://cdn.eu.com/connect/partnerlogos/dhl/logo/logo.jpg",
    "Versand DHL Deutschland": <img className="rounded-0" src="https://cdn.eu.com/connect/partnerlogos/dhl/logo/logo.jpg" height="20" alt="DHL" />,
    "Versand DHL Schweiz": <img className="rounded-0" src="https://cdn.eu.com/connect/partnerlogos/dhl/logo/logo.jpg" height="20" alt="DHL" />,
    "Versand DHL EU": <img className="rounded-0" src="https://cdn.eu.com/connect/partnerlogos/dhl/logo/logo.jpg" height="20" alt="DHL" />,
    amazon_fba: "https://cdn.eu.com/connect/img/fba.png",
    ebay: <img src="https://cdn.eu.com/connect/img/ebay.png" height="20" alt="eBay" />,
    idealo_direkt: <img src="https://cdn.eu.com/connect/img/idealo_direkt.png" height="20" alt="Idealo Direkt" />,
    shopify: "https://cdn.eu.com/ch/partnerLogos/shopify.png",
    shopify_payments: "https://cdn.eu.com/ch/partnerLogos/Shopify_Pay.jpg",
    rechnung_mit_klarna: "https://cdn.eu.com/ch/partnerLogos/Klarna.jpg",
    sofort_bezahlen_mit_klarna: "https://cdn.eu.com/ch/partnerLogos/Klarna.jpg",
    buy_now_pay_later_with_klarna: "https://cdn.eu.com/ch/partnerLogos/Klarna.jpg",
    gls: "https://cdn.eu.com/ch/partnerLogos/GLS.jpg",
    postnl: "https://cdn.eu.com/ch/partnerLogos/postnl.jpg",
    dpag: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Deutsche_Post-Logo.svg/320px-Deutsche_Post-Logo.svg.png",
    bps: "https://cdn.eu.com/ch/partnerLogos/Acut.jpg",
    squarespace: "https://cdn.eu.com/ch/partnerLogos/Squarespace.jpg"
};