import React, { Fragment, useState, useEffect } from 'react'
import { 
    CardHeader,
    Input,
    MDBRow,
    MDBCol,
    MDBCollapse ,
    MDBListGroup,
    MDBListGroupItem,
    MDBTabContent,
    MDBIcon,
    MDBTabPane,MDBTable, MDBTableBody, MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption, MDBBtn, MDBInput, MDBInputGroup, MDBCard, MDBCardTitle, MDBCardBody, MDBCardText, MDBCardImage
 } from "mdbreact";
 import { useSelector, useDispatch } from 'react-redux'
 import {API} from "aws-amplify";
import { addActiveFulfiller } from "../../../features/fulfillers";

export default function New_Fulfiller(props) {
    const dispatch = useDispatch()
    // Get available Fulfillers
    const [availableFulfillers, setAvailableFulfillers] = useState([])

    const getAvailableFulfillers = async () => {
        try {
            const res = await API.get("ConnectApi", "system/availableproviders", {queryStringParameters: {type: "fulfillers"}})
            setAvailableFulfillers(res.available)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if(availableFulfillers.length === 0) {
            getAvailableFulfillers()
        }
    }, [])

    const activeFulfillers = useSelector((state) => state.fulfillers && Object.keys(state.fulfillers))

    const activateFulfiller = async (fulfiller) => {
        
        try {
            //await API.post("ConnectApi", "settings/fulfillers/activate", {body: {fulfiller}})
            dispatch(addActiveFulfiller(fulfiller))
        } catch (error) {
            console.log(error)
        }
        
    }

  return (
    <Fragment>
        <h4 className="mt-5 mx-4">Connect new Fulfiller</h4>
         <MDBRow className="p-2 p-md-4">
            {availableFulfillers.length > 0 && availableFulfillers.map(fulfiller => {
                const isActive = activeFulfillers?.indexOf(fulfiller.handle) >= 0

                return <MDBCol size="6" md="4" className="mb-3">
                <MDBCard
                className={isActive ? "rgba-green-slight border border-success" : ""}
                onClick={() => {
                    if(!isActive) {
                        activateFulfiller(fulfiller)
                    } else {
                        console.log("toggle");
                        const toggle = props.toggleFulfiller(fulfiller.handle)
                        toggle()
                    }
                }}
                >
                    <MDBCardImage  className="mx-auto pt-3" style={{height: "50px"}}src={fulfiller.logoUrl} />
                    <MDBCardBody className="mt-2 p-2">
                        <MDBCardText className="text-center">
                        <MDBBtn outline size="sm">{isActive ? "Configure" : "Activate"}</MDBBtn>
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
            })}
        </MDBRow>
    </Fragment>
  )
}
