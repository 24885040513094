import React, { Fragment, useState, useEffect } from 'react'
import { 
    MDBRow,
    MDBCol,
    MDBCollapse ,
    MDBIcon, MDBBtn, MDBInput, MDBInputGroup, MDBCard, MDBCardTitle, MDBCardBody, MDBCardText, MDBCardImage,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBSelectV5,
    MDBContainer
 } from "mdbreact";
 import { OauthSender } from 'react-oauth-flow';
 import {API} from 'aws-amplify';

export default function Marketplace_Amazon(props) {
    // Active requests in process
    const [isLoading, setIsLoading] = useState({refetchLocations: false, connectManualStore: false})





    // Existing connections
    const [stores, setStores] = useState({})
    // For creating new connection
    const [storeName, setStoreName] = useState("")

    // Conenct new store App or Manual select
    const [connectTypeOpen, setConnectTypeOpen] = useState("")

    // Manual setup form
    const [manualForm, updateManualForm] = useState({})
    const [manualStoreRes, setManualStoreRes] = useState({finished: false, success: null, message:""})

    // Configure a connected store
    const [selectedStore, setSelectedStore] = useState({})
    const [isModalOpen, setModalOpen] = useState(false)
    

    useEffect(() => {
        getStores();
    }, [props.isActive])
    
    const getStores = async () => {
        try {
            const resBillbeeSettings = await API.get("ConnectApi", "settings/component", {queryStringParameters: {component: "billbee"}})
            setStores(resBillbeeSettings.stores)
        } catch (error) {
            console.log(error)
        }
    }





    const connectManualStore = async () => {
        console.log("Connecting manual store")
        setIsLoading({...isLoading, connectManualStore: true})
        try {
            const connectRes = await API.post("ConnectApi", "settings/billbee/connectstore", {body: {
                credentials: {
                    email: manualForm.email,
                    apiKey: manualForm.apikey,
                }
            }})
            console.log(connectRes)
            setManualStoreRes({
                finished: true,
                success: true
            })
            updateManualForm({storeName: "", token: "", key: "", keySecret: ""})
            getStores()
        } catch (error) {
            // console.log(error.response)
            setManualStoreRes({
                finished: true,
                success: false,
                message: "Cannot connect store"
            })
        }
        setIsLoading({...isLoading, connectManualStore: false})
    }

    const updateSyncInterval = async (type, value, storeName) => {
        console.log(value)
        if(isModalOpen && value.toString() !== selectedStore.syncintervals[type].toString()) {
            console.log("updating sync interval for " + type + " to: " + value)
            try {
                await API.post("ConnectApi", "settings/shopify/update", {queryStringParameters: {storeName: storeName}, body: {interval: {type, value}}})
                setStores({
                    ...stores,
                    [storeName]: {
                        ...stores[storeName],
                        syncintervals: {
                            ...stores[storeName].syncintervals,
                            [type]: parseInt(value)
                        }
                    }
                })
                setSelectedStore({
                    ...selectedStore,
                    syncintervals: {
                        ...selectedStore.syncintervals,
                        [type]: parseInt(value)
                    }
                })
            } catch (error) {
                console.log(error)
            }
        }
    }
    

  return (
    <Fragment>
        <MDBContainer className="my-2 my-md-4">
            {stores && Object.keys(stores).length > 0 && <>
                <h4>Connected stores</h4>
                <MDBRow className="mt-4">
                {Object.keys(stores).map(storeHandle => <MDBCol key={storeHandle} size="12" md="6">
                    <MDBCard
                    onClick={() => {
                        setSelectedStore({handle: storeHandle, ...stores[storeHandle]});
                        setModalOpen(true)
                    }}
                    >
                        <MDBCardBody>
                            <MDBCardTitle>{storeHandle}<span style={{fontSize: "50%"}}> Selling Partner ID</span></MDBCardTitle>
                            <MDBCardText>
                                Connected: {new Date(stores[storeHandle].connectedTime).toLocaleDateString()}<br></br>
                                Connection-Type: {stores[storeHandle].type}
                            </MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>)}
                </MDBRow>
            </>}
            <h4 className="mt-5 text-center">Connect new store</h4>
            <MDBRow>
                <MDBCol size="0" md="2"></MDBCol>
                <MDBCol size="12" md="8">
                <p>Um das API Passwort zu erhalten hier klicken: <a target="_blank" href="https://app.billbee.io/app_v2/settings/api/general">Billbee API</a></p>
                    <MDBInput label="E-Mail Address" value={manualForm.email} onChange={(e) => updateManualForm({...manualForm, email: e.target.value})} />
                    <MDBInput label="API Password" value={manualForm.apiKey} onChange={(e) => updateManualForm({...manualForm, apiKey: e.target.value})} />
                </MDBCol>
                <MDBCol size="0" md="2"></MDBCol>
                <MDBCol size="12" className="text-center mt-3 mt-md-4">
                {isLoading.connectManualStore ? 
                    <div className="spinner-grow text-default" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                :
                <MDBBtn size="sm" disabled={!(manualForm.email?.length > 5 && manualForm.apiKey?.length > 4 )} onClick={() => connectManualStore()}>Connect</MDBBtn>
                }
                {manualStoreRes.finished && !manualStoreRes.success && <span className="text-danger">{manualStoreRes.message}</span> }
                </MDBCol>
            </MDBRow> 
        </MDBContainer>
                  
       
    </Fragment>
  )
}
