import { Component } from "react"
import { 
    CardHeader,
    Input,
    MDBRow,
    MDBCol,
    MDBListGroup,
    MDBListGroupItem,
    MDBTabContent,
    MDBTabPane,MDBTable, MDBTableBody, MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption,MDBBtn, MDBInput, MDBInputGroup, MDBIcon,
    MDBPopover,
    MDBPopoverBody,
    MDBPopoverHeader,
    MDBSelectV5
 } from "mdbreact"
import { OauthSender } from 'react-oauth-flow'
import { connect } from "react-redux"
import {SettingsAPI} from "../../Api/connect"
import Marketplace_Shopify from "./Marketplaces/shopify"
import Marketplace_Amazon from "./Marketplaces/amazon"
import Marketplace_Ebay from "./Marketplaces/ebay"
import Marketplace_Billbee from "./Marketplaces/billbee"
import New_Marketplace from "./Marketplaces/newMarketplace"
import Marketplace_Shopware6 from "./Marketplaces/shopware6"



class Marketplaces extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "newMarketplace",
            marketplaces: {},
            activeMarketplaces: props.marketplaces?.active || [],
            profile: ""
        }

    }

    componentWillReceiveProps(props) {
        this.setState({activeMarketplaces: props.marketplaces?.active || []})
    }

    async componentDidMount() {
        try {

           const marketplaces = await SettingsAPI.getSettings();
            this.setState({
                marketplaces: marketplaces.settings.marketplaces
            })
            console.log(marketplaces.settings.marketplaces);
        } catch (error) {
            console.log(error)
            
        }
       
    }

toggleTabs = tab => () => {
    if (this.state.activeTab !== tab) {
    this.setState({
        activeTab: tab
    });
    }
    }

renderShopwarePayments = (payments) => payments.map(p => (
    <tr>
        <td className="py-0 px-3"><Input type="text" label="Store Payment ID" value={p.id} /></td>
        <td className="py-0 px-3">
            <MDBSelect>
            <MDBSelectInput selected={p.provider || "Choose payment provider"} />
            <MDBSelectOptions>
                <MDBSelectOption disabled>Choose payment provider</MDBSelectOption>
                <MDBSelectOption value="1">paypal</MDBSelectOption>
                <MDBSelectOption value="2">amazon</MDBSelectOption>
                <MDBSelectOption value="3">amazon_pay</MDBSelectOption>
                <MDBSelectOption value="3">sofort</MDBSelectOption>
                <MDBSelectOption value="3">credit_card</MDBSelectOption>
                <MDBSelectOption value="3">prepayment</MDBSelectOption>
            </MDBSelectOptions>
            </MDBSelect>
        </td>
    </tr>
))

renderShopwareShipping = (shippers) => shippers.map(s => (
    <tr>
        <td className="py-0 px-3"><Input type="text" label="Store Fulfiller ID" value={s.id} /></td>
        <td className="py-0 px-3">
            <MDBSelect>
            <MDBSelectInput selected={s.provider || "Choose fulfillment provider"} />
            <MDBSelectOptions>
                <MDBSelectOption disabled>Choose fulfillment provider</MDBSelectOption>
                <MDBSelectOption value="1">dhl</MDBSelectOption>
                <MDBSelectOption value="2">ups</MDBSelectOption>
                <MDBSelectOption value="3">amazon_fba</MDBSelectOption>
            </MDBSelectOptions>
            </MDBSelect>
        </td>
    </tr>
))
    
render() {
    return (
        <section>
           <MDBRow>
               <MDBCol sm="4">
                <MDBListGroup className="my-4 mx-4" style={{ width: "22rem" }}>
                        {this.state.activeMarketplaces.length > 0 && this.state.activeMarketplaces.map(marketplace => <MDBListGroupItem color="light" onClick={this.toggleTabs(marketplace.handle)}>{marketplace.name}</MDBListGroupItem>)}
                        <MDBListGroupItem className="default-color white-text text-center" onClick={this.toggleTabs("newMarketplace")}><MDBIcon icon="plus" /></MDBListGroupItem>
                    </MDBListGroup>
               </MDBCol>
                <MDBCol sm="8">
                <MDBTabContent className="card p-0" activeItem={this.state.activeTab}>
                <CardHeader color="stylish-color">{this.state.activeTab.charAt(0).toUpperCase() + this.state.activeTab.slice(1)}</CardHeader>
               
                <MDBTabPane tabId="ebay">
                    <Marketplace_Ebay />
                </MDBTabPane>
                <MDBTabPane tabId="shopware">
                <MDBRow>
                        <MDBCol className="p-5">
                        <h4>General Settings</h4>
                        <Input type="text" label="Shop domain" value={this.state.marketplaces.shopware && this.state.marketplaces.shopware.storeURL} />
                        <Input type="text" label="Username" value={this.state.marketplaces.shopware && this.state.marketplaces.shopware.username} />
                        <Input type="text" label="API-Key" value={this.state.marketplaces.shopware && this.state.marketplaces.shopware.apiKey} />
                        <br />
                        <br />
                        <h4>Payment Providers</h4>
                        <MDBTable borderless>
                        <MDBTableBody>
                            {this.state.marketplaces.shopware && this.renderShopwarePayments(this.state.marketplaces.shopware.matchPayment)}
                        </MDBTableBody>
                        </MDBTable>
                        <br />
                        <h4>Fulfillment Providers</h4>
                        <MDBTable borderless>
                        <MDBTableBody>
                            {this.state.marketplaces.shopware && this.renderShopwareShipping(this.state.marketplaces.shopware.matchShipping)}
                        </MDBTableBody>
                        </MDBTable>
                        </MDBCol>
                    </MDBRow>
                </MDBTabPane>
                <MDBTabPane tabId="shopware6">
                   <Marketplace_Shopware6 />
                </MDBTabPane>
                <MDBTabPane tabId="shopify">
                   <Marketplace_Shopify />
                    
                </MDBTabPane>
                <MDBTabPane tabId="amazon">
                   <Marketplace_Amazon />
                </MDBTabPane>
                <MDBTabPane tabId="billbee">
                   <Marketplace_Billbee />
                </MDBTabPane>
                <MDBTabPane tabId="newMarketplace">
                   <New_Marketplace activeMarketplaces={this.state.activeMarketplaces} toggleMarketplace={this.toggleTabs} />
                </MDBTabPane>
                </MDBTabContent>
                </MDBCol>
           </MDBRow>
        </section>
     );
}
  
};


const mapStateToProps = (state, ownProps) => ({
    marketplaces: state.marketplaces,
    ...ownProps
})

 export default connect(
    mapStateToProps, null
 )(Marketplaces)