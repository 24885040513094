import { Component } from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, Table, TableHead, TableBody, MDBInput } from 'mdbreact';
import {OrdersAPI} from "../../Api/connect";
import images from "../images";


export default class Invoice extends Component {
   
  state = {
    openAfterCreation: false
  }
    renderOrders = (orders) => {
        if (!orders) {
            return
        }
        return orders.map((order) => ({
                "orderNumber": <span>{order.id}<br /><span className="grey-text">{order.storeOrderId}</span></span>,
                "store": images[order.intPartner],
                "address": <span>{order.addresses.shipping.fullName || order.customerData.firstName + " " + order.customerData.lastName}<br />{order.addresses.shipping.street}<br />{order.addresses.shipping.zip + " " + order.addresses.shipping.city}<br />{order.addresses.shipping.country}</span>,
                "articles": <span>{order.articles.names.map((a, index) => <span>{a}<br /><span className="grey-text">{order.articles.skus[index]}</span></span>)}</span>
        }))
    };

    createInvoice = async (open) => {
        let request = {
          view: open,
          createNew: true,
          sendEmail: false,
          orderIds: this.props.orders.map(order => order.id),
          documentType: "invoice"
        }

        const response = await OrdersAPI.createInvoice(null, request);

        if(open) window.open(response.url, "_blank")


        // let invoices = [];
        // let body = {
        //     orderIds: []
        // };
        // this.props.orders.forEach(o => {
        //   if(o.invoice && o.invoice.url) {
        //     invoices.push(o.invoice);
        //   } else {
        //     body.orderIds.push(o.id);
        //   }
        // });
        // try {
        //     if(body.orderIds.length > 0) {
        //       const newInvoices = await OrdersAPI.createInvoice(null, body);
        //       console.log(newInvoices);
        //       invoices = invoices.concat(newInvoices);
              
        //     }
        //     console.log(invoices);
        //     console.log(open);
        //     if (open && invoices.length > 0) {
        //       console.log(invoices);
        //       invoices.forEach(invoice => {window.open(invoice.url, "_blank")})
        //     }
        //     this.props.toggle("invoice");
        // } catch (error) {
        //     console.log(error)
        // }
    }

    sendEmail = async () => {
      let body = {
          orderIds: []
      };
      this.props.orders.forEach(o => {
          body.orderIds.push(o.id);
      });
      try {
          if(body.orderIds.length > 0) {
            await OrdersAPI.sendInvoiceEmail(null, body);
            
          }
          //this.props.toggle("invoice");
      } catch (error) {
          console.log(error)
      }
  }

    openInvoice = async () => {

      try {
          const b = {
              documentIds: this.props.orders.map(order => order.documents.find(d => d.type === "invoice").id)
          };
          const RES = await OrdersAPI.openDocument(b);
          window.open(RES.url, "_blank")
      } catch (error) {
          console.log(error)
      }
  }

    render= () => {
        const data_checkboxes = {
            columns: [
              {
                'label': 'Order number',
                'field': 'orderNumber',
                'sort': 'asc'
              },
              {
                'label': 'Store',
                'field': 'store',
                'sort': 'asc'
              },
              {
                'label': 'Address',
                'field': 'address',
                'sort': 'asc'
              },
              {
                'label': 'Articles',
                'field': 'articles',
                'sort': 'asc'
              },
             
            ]
          };
    return (
        <MDBModal isOpen={this.props.isOpen} toggle={() => {this.props.toggle("invoice")}} contentClassName="modalTeal" centered size="xl">
          <MDBModalHeader toggle={() => {this.props.toggle("invoice")}}>Invoice</MDBModalHeader>
          <MDBModalBody>
          <Table responsive className="text-center">
                 <TableHead columns={data_checkboxes.columns} />
                 <TableBody rows={this.renderOrders(this.props.orders)} />
            </Table>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn rounded outline color="danger" onClick={() => {this.props.toggle("invoice")}}>Cancel</MDBBtn>
            <MDBBtn rounded outline onClick={this.openInvoice}>Open</MDBBtn>
            
            <MDBBtn rounded outline onClick={() => {this.createInvoice(false)}}>Create new 
            {/* <MDBInput containerClass="float-left w-100px" label="Open" type="checkbox" id="openAfterCreationCheckbox"
            checked={this.state.openAfterCreation}
            onChange={e => this.setState({openAfterCreation: e.target.checked})}
            /> */}
            </MDBBtn>
            <MDBBtn disabled rounded color="grey" outline>Send Email</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
    )
    }
}
