import { Component } from 'react';
import {  MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, Table, TableHead, TableBody, MDBRow, MDBCol } from 'mdbreact';
import { FinancialAPI } from "../../../Api/connect";


class UpdateStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedStatus: "",
            partnerId: "solectric"
        }
    }
    renderBills(bills) {
        if (!bills) {
            return null;
        }
        return bills.map((bill) => {
            const d = new Date();
            let billD = new Date(bill.date);
            const dueDay = new Date(billD.getTime() + bill.dueDays * 86400000);
            const remaining = parseInt((dueDay.getTime() - d.getTime())/(24*3600*1000));
            let color;
            switch (true) {
                case (remaining >= 2 && remaining < 7):
                    color = "orange"
                    break;
                case (remaining < 2):
                    color = "red"
                    break;
                case (remaining > 7):
                    color = "green"
                    break;

                default:
                    break;
            }
            return {
                    check: <div className="form-check">
                                <input type="checkbox" onChange={this.selectOrder} className="form-control form-check-input my-0" id={"bill" + bill.id} value="" />
                                <label class="form-check-label mr-5" for={"bill" + bill.id} data-error="" data-success="" id={"bill" + bill.id}></label>
                            </div>,
                    billID: "#" + bill.id,
                    billNumber: bill.number,
                    amount: bill.amount + " €",
                    date: bill.date,
                    remaining: <span className="font-weight-bold" style={{color: color}}>{remaining}</span>
            }
        }
        ).reverse();

    }

    execute = async () => {
        const body = {
            bills: this.props.bills.map(b => b.id)
        };
        const qs = {
          partnerId: this.state.partnerId,
          status: this.state.selectedStatus
        }
        try {
            await FinancialAPI.updateBillStatus(qs, body);
            this.props.toggle("updateStatus", true);
        } catch (error) {
            console.log(error)
        }
    }

    changeStatus = e => {
        this.setState({selectedStatus: e.target.value})
    }

    render= () => {
        const data_checkboxes = {
            columns: [
              {
                'label': '#',
                'field': 'check'
              },
              {
                'label': 'Bill ID',
                'field': 'billID',
                'sort': 'asc'
              },
              {
                'label': 'Bill number',
                'field': 'billNumber',
                'sort': 'asc'
              },
              {
                'label': 'Amount',
                'field': 'amount',
                'sort': 'asc'
              },
              {
                'label': 'Billing Date',
                'field': 'date',
                'sort': 'asc'
              },
              {
                'label': 'Remaining time',
                'field': 'remaining',
                'sort': 'asc'
              }
            ]
          };
    return (
        <MDBModal isOpen={this.props.isOpen} toggle={() => {this.props.toggle("updateStatus")}} centered size="xl">
          <MDBModalHeader toggle={() => {this.props.toggle("updateStatus")}}>Update Status</MDBModalHeader>
          <MDBModalBody>
          <Table responsive className="text-center">
                 <TableHead  color="teal" textWhite columns={data_checkboxes.columns} />
                 <TableBody rows={this.renderBills(this.props.bills)} />
            </Table>
            <MDBRow>
              <MDBCol size="12" md="6" className="mx-auto">
              <select
              className="browser-default custom-select"
              // value=""
              style={{backgroundColor: "transparent", border: "none", borderBottom: "1px solid #ced4da", borderRadius: "0"}}
              onChange={this.changeStatus}>
              <option>New Status</option>
              <option value="open">Open</option>
              <option value="payed">Payed</option>
              <option value="needVerify">Need check</option>
              </select>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color=" rgba-red-strong" onClick={() => {this.props.toggle("updateStatus")}}>Cancel</MDBBtn>
            <MDBBtn color=" rgba-stylish-strong" onClick={this.execute}>Update</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
    )
    }
}



export default UpdateStatus;