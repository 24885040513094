import { Component } from "react";
import { MDBRow, MDBCol, MDBStepper, MDBStep, MDBBtn, Card, CardHeader, CardBody, Table, TableHead, TableBody, MDBIcon, MDBSelect,
    MDBInput } from "mdbreact";
import {ProductsAPI, SettingsAPI, MultichannelAPI} from "../../Api/connect";
import MarketplaceValidator from "../../validators/marketplaces/marketplaces";
import ConnectImages from "../../components/images";
import Images from "../../components/images";

class CreateListings extends Component {

state = {
    activeStep: 1,
    warning: {
        validate: false,
        publish: false
    },
    products: [],
    perPage: 800,
    perSearch: 300,
    searchQuery: "",
    searchedProducts: [],
    selectedProducts: [],
    activeListings: [],
    selectedMarketplaces: [],
    settings: {},
    marketplaceSettings: {},
    isPublishing: false
}

async componentDidMount() {
    this.getProducts();
    this.getListings();
    try {
        const settings = await SettingsAPI.getSettings();
        const marketplaceInfos = await MultichannelAPI.getMarketplaceInfos({
            marketplaces: Object.keys(settings.settings.marketplaces)
        });
        console.log(marketplaceInfos);
        this.setState({settings: settings.settings, marketplaceInfos})
    } catch (error) {
        console.log(error)
    }
   
}

getProducts = async () => {
    try {
        const products = await ProductsAPI.getProducts({limit: this.state.perPage});
        this.setState({products})
    } catch (error) {
        console.log(error)
    } 
}

async getListings() {
    try {
        const activeListings = await MultichannelAPI.getListings();
        this.setState({activeListings})
    } catch (error) {
        console.log(error)
    } 
}

publishProducts = async () => {
    try {
        let marketplaceSettings = {};
        this.state.selectedMarketplaces.forEach(m => marketplaceSettings[m] = this.state.marketplaceSettings[m] || ({}))
        const b = {
            marketplaceSettings,
            productIds: this.state.selectedProducts.map(p => p.id)
        };
        this.setState({isPublishing: true})
        console.log(b);
        const res = await MultichannelAPI.publishProducts(b);
        console.log(res)
        this.setState({
            publishResponse : res,
            searchedProducts: []
        })
        this.getProducts();
    this.getListings();
    } catch (error) {
        console.log(error)
    }
}

changeSearchQuery = e => {
    const {value} = e;
    if(e.key === 'Enter' && this.state.searchQuery.length > 2) {
        this.searchProducts()
    } else {
        this.setState({
            searchQuery: value
        })
    }
}

changeMarketplaceSettings = (marketplace, option, value) => {
    if (this.state.marketplaceSettings[marketplace] 
        && this.state.marketplaceSettings[marketplace][option] 
        && this.state.marketplaceSettings[marketplace][option] === value
        ) {
    } else {
        this.setState(state => ({
            marketplaceSettings: {
                ...state.marketplaceSettings,
                [marketplace]: {
                    ...state.marketplaceSettings[marketplace],
                    [option]: value
                }
            }
        }))
    }
}

searchProducts = async (e) => {
    const {value} = e.target;
    if(e.key === 'Enter'){
        if(value.length < 1) {
            this.setState({
                searchQuery: ""
            })
            this.getProducts()
        } else {
            this.setState({
                searchQuery: value
            })
            try {
                const b = {
                    q: value,
                    limit: this.state.perSearch
                };
                const searchedProducts = await ProductsAPI.searchProducts(b)
                this.setState(state => ({
                    searchedProducts: searchedProducts,
                    products: searchedProducts
                }))
            } catch (error) {
                console.log(error)
            }
        }

    }
}


selectProduct = (id, selected) => {
    console.log(selected)
    if (selected) {
        this.setState(state => ({
            selectedProducts: [...state.selectedProducts.filter(p => p.id !== id)]
        }))
    } else if (this.state.selectedProducts.filter(p => p.id === id).length < 1){
        this.setState(state => ({
            selectedProducts: [
                ...state.selectedProducts,
                this.state.products.filter(p => p.id === id)[0]
            ]
        }))
    }
}

selectMarketplace = marketplace => {
    if (this.state.selectedMarketplaces.indexOf(marketplace) >= 0) {
        // Remove
        this.setState(state => ({
            selectedMarketplaces: state.selectedMarketplaces.filter(m => m !== marketplace)
        }))
    } else {
        // Add
        this.setState(state => ({
            selectedMarketplaces: [
                ...state.selectedMarketplaces,
                marketplace
            ]
        }))
    }
}


renderProducts(products) {
    return products.map(product => {
        const isSelected = this.state.selectedProducts.filter(p => p.id === product.id).length > 0;
        const matchingListing = this.state.activeListings.filter(l => l.productId === product.id);
        const stock = product.stock && Object.values(product.stock).reduce((a, b) => a + b);
        return ({
            img: <img src={product.images && product.images.main} alt={product.name} height="50" />,
            sku: product.sku,
            name: product.name,
            stock: <span style={{color: stock > 0 ? "black": "red"}}>{stock}</span>,
            onlineAt: matchingListing.length > 0 && matchingListing.map(listing => <span>{Images[listing.marketplace]} <a target="_blank" rel="noopener noreferrer" href={listing.marketplaceOfferLink}><MDBIcon className="grey-text" icon="external-link-alt" /></a><br /></span> ),
            button: <MDBBtn
            className="my-0"
            color={isSelected ? " teal white-text" : " grey white-text"}
            size="sm" id={product.id.toString()}
            onClick={() => this.selectProduct(product.id, isSelected)}>Select</MDBBtn>

        })
                });

}

validateProducts(products, marketplaces) {
        // const warnings = 0;
        return products.map(product => ({
                img: <img src={product.images && product.images.main} alt={product.name} height="50" />,
                sku: product.sku,
                name: product.name,
                stock: product.stock && Object.values(product.stock).reduce((accumulator, currentValue) => accumulator + currentValue),
                status: marketplaces.map(m => (
                    <p>
                        {ConnectImages[m]}
                        {MarketplaceValidator.product[m](product) ? <MDBIcon className="green-text ml-3" icon="check" /> : <MDBIcon className="red-text ml-3" icon="times-circle" />}
                    </p>
                ))
        }));
      
}

renderPublishProducts(products, marketplaces) {
    // const warnings = 0;
    return products.map(product => ({
            img: <img src={product.images && product.images.main} alt={product.name} height="50" />,
            sku: product.sku,
            name: product.name,
            stock: product.stock && Object.values(product.stock).reduce((accumulator, currentValue) => accumulator + currentValue),
            status: marketplaces.map(m => (
                <p>
                    {ConnectImages[m]}
                    {!this.state.publishResponse ? "" : this.state.publishResponse[m].responses.filter(r => r.productId === product.id)[0].status === 200 ? <MDBIcon className="green-text ml-3" icon="check" /> : <MDBIcon className="red-text ml-3" icon="times-circle" />}
                </p>
            ))
    }));
  
}



changeActiveStep = a => {
    this.setState({activeStep: a})
}

render() {
    const data_checkboxes = {
        columns: [
          {
            'label': 'Image',
            'field': 'img',
            'sort': 'asc'
          },
          {
            'label': 'SKU',
            'field': 'sku',
            'sort': 'asc'
          },
          {
            'label': 'Name',
            'field': 'name',
            'sort': 'asc'
          },
          {
            'label': 'Stock',
            'field': 'stock',
            'sort': 'asc'
          },
          {
            'label': 'Online',
            'field': 'onlineAt',
            'sort': 'asc'
          },
          {
            'label': 'Select',
            'field': 'button',
            'sort': 'asc'
          }
        ]
      };
      const data_checkboxesValidate = {
        columns: [
          {
            'label': 'Image',
            'field': 'img',
            'sort': 'asc'
          },
          {
            'label': 'SKU',
            'field': 'sku',
            'sort': 'asc'
          },
          {
            'label': 'Name',
            'field': 'name',
            'sort': 'asc'
          },
          {
            'label': 'Stock',
            'field': 'stock',
            'sort': 'asc'
          },
          {
            'label': 'Status',
            'field': 'status',
            'sort': 'asc'
          }
        ]
      };
  return (
    <section>
      <MDBStepper>
        <MDBStep className="completed">
        <a href="#!" onClick={() => this.changeActiveStep(1)}>
            <span className="circle">1</span>
            <span className="label">Products</span>
          </a>
        </MDBStep>
        <MDBStep className={this.state.activeStep === 2 ? "active" : this.state.activeStep > 2 && "completed" }>
        <a href="#!" onClick={() => this.changeActiveStep(2)}>
            <span className="circle">2</span>
            <span className="label">Marketplaces</span>
          </a>
        </MDBStep>
        <MDBStep className={this.state.warning.validate ? "warning" : this.state.activeStep === 3 ? "active" : this.state.activeStep > 3 && "completed" }>
        <a href="#!" onClick={() => this.changeActiveStep(3)}>
            <span className="circle">3</span>
            <span className="label">Validate</span>
          </a>
        </MDBStep>
        <MDBStep className={this.state.warning.publish ? "warning" : this.state.activeStep === 4 ? "active" : ""}>
        <a href="#!" onClick={() => this.changeActiveStep(4)}>
            <span className="circle">4</span>
            <span className="label">Publish</span>
          </a>
        </MDBStep>
      </MDBStepper>

          {this.state.activeStep === 1 &&
          (<section>
              <MDBRow>
            <MDBCol size="12">
                <Card className="w-100">
                        <CardHeader color="stylish-color">All Products</CardHeader>
                        <CardBody className="p-0">
                        <MDBRow>
                            <MDBCol size="9">
                            <div className="md-form my-1 ml-md-4">
                            <MDBInput label="Search"
                                    id="search"
                                    onKeyPress={this.searchProducts}
                            />
                            </div>
                            </MDBCol>
                            <MDBCol size="3">
                                <MDBBtn size="sm" color=" teal white-text mt-md-2" onClick={this.searchProducts}>Search</MDBBtn>
                            </MDBCol>
                        </MDBRow>
                            <Table responsive className="text-center">
                                <TableHead  color="teal" textWhite columns={data_checkboxes.columns} />
                                <TableBody rows={this.state.searchedProducts.length > 0 ? this.renderProducts(this.state.searchedProducts) : this.state.products && this.renderProducts(this.state.products)} />
                            </Table>
                        </CardBody>
                    </Card>
            </MDBCol>
            <MDBCol size="12" className="text-right my-3">
                <MDBBtn color="success" onClick={() => this.changeActiveStep(this.state.activeStep + 1)}>Continue</MDBBtn>
            </MDBCol>
            <MDBCol size="12">
                <Card className="w-100">
                        <CardHeader color="stylish-color">Selected Products</CardHeader>
                        <CardBody className="p-0">
                        <Table responsive className="text-center">
                                <TableHead  color="teal" textWhite columns={data_checkboxes.columns} />
                                <TableBody rows={this.state.products && this.renderProducts(this.state.selectedProducts, true)} />
                            </Table>
                        </CardBody>
                    </Card>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="12" className="text-right">
                <MDBBtn color="success" onClick={() => this.changeActiveStep(this.state.activeStep + 1)}>Continue</MDBBtn>
            </MDBCol>
          </MDBRow>
          </section>
             )}

          {this.state.activeStep === 2 &&
            (<section>
            <MDBRow>
            <MDBCol size="12">
                <Card className="w-100">
                        <CardHeader color="stylish-color">Select Marketplaces</CardHeader>
                        <CardBody className="text-center">
                        <h4 className="mb-md-4">Marketplaces</h4>
                        <MDBRow>
                            {this.state.settings.marketplaces && this.state.marketplaceInfos && Object.keys(this.state.settings.marketplaces).map(marketplace => (
                                <MDBCol id={marketplace} size="12" md="3">
                                    <Card className={this.state.selectedMarketplaces.indexOf(marketplace) >= 0 && "thick-border-success"} onClick={() => this.selectMarketplace(marketplace)}>
                                        <CardHeader style={{height: "65px"}}>
                                        {ConnectImages[marketplace]}
                                        </CardHeader>
                                        <CardBody>
                                        {marketplace.toLocaleUpperCase()}
                                        
                                        </CardBody>
                                    </Card>
                                    {marketplace === "amazon" && 
                                    <MDBSelect
                                            options={this.state.marketplaceInfos.amazon.marketplaces.map(p => ({
                                                text: p.country,
                                                value: p.id.toString(),
                                                icon: p.id === this.state.marketplaceInfos.amazon.mainMarketplace.id && "https://s3.eu-central-1.amazonaws.com/cdn.eu.com/connect/check-square-regular.svg",
                                                checked: this.state.marketplaceSettings.amazon && this.state.marketplaceSettings.amazon.marketplaceId ? this.state.marketplaceSettings.amazon.marketplaceId === p.id : p.id === this.state.marketplaceInfos.amazon.mainMarketplace.id
                                            }) )}
                                            label="Marketplace"
                                            getValue={value => this.changeMarketplaceSettings("amazon", "marketplaceId", value[0])}
                                            />
                                    }
                                    {marketplace === "ebay" && 
                                        <>
                                            <MDBSelect
                                            options={this.state.marketplaceInfos.ebay.paymentPolicies.all.map(p => ({
                                                text: p.name,
                                                value: p.paymentPolicyId.toString(),
                                                icon: p.paymentPolicyId === this.state.marketplaceInfos.ebay.paymentPolicies.default.paymentPolicyId && "https://s3.eu-central-1.amazonaws.com/cdn.eu.com/connect/check-square-regular.svg",
                                                checked: this.state.marketplaceSettings.ebay && this.state.marketplaceSettings.ebay.paymentPolicyId ? this.state.marketplaceSettings.ebay.paymentPolicyId === p.paymentPolicyId  : p.paymentPolicyId === this.state.marketplaceInfos.ebay.paymentPolicies.default.paymentPolicyId
                                            }) )}
                                            label="Payment Policy"
                                            getValue={value => this.changeMarketplaceSettings("ebay", "paymentPolicyId", value[0])}
                                            />
                                            <MDBSelect
                                            options={this.state.marketplaceInfos.ebay.fulfillmentPolicies.all.map(p => ({
                                                text: p.name,
                                                value: p.fulfillmentPolicyId.toString(),
                                                icon: p.fulfillmentPolicyId === this.state.marketplaceInfos.ebay.fulfillmentPolicies.default.fulfillmentPolicyId && "https://s3.eu-central-1.amazonaws.com/cdn.eu.com/connect/check-square-regular.svg",
                                                checked: this.state.marketplaceSettings.ebay && this.state.marketplaceSettings.ebay.fulfillmentPolicyId ? this.state.marketplaceSettings.ebay.fulfillmentPolicyId === p.fulfillmentPolicyId  : p.fulfillmentPolicyId === this.state.marketplaceInfos.ebay.fulfillmentPolicies.default.fulfillmentPolicyId
                                            }) )}
                                            label="Fulfillment Policy"
                                            getValue={value => this.changeMarketplaceSettings("ebay", "fulfillmentPolicyId", value[0])}
                                            />
                                            <MDBSelect
                                            options={this.state.marketplaceInfos.ebay.returnPolicies.all.map(p => ({
                                                text: p.name,
                                                value: p.returnPolicyId.toString(),
                                                icon: p.returnPolicyId === this.state.marketplaceInfos.ebay.returnPolicies.default.returnPolicyId && "https://s3.eu-central-1.amazonaws.com/cdn.eu.com/connect/check-square-regular.svg",
                                                checked: this.state.marketplaceSettings.ebay && this.state.marketplaceSettings.ebay.returnPolicyId ? this.state.marketplaceSettings.ebay.returnPolicyId === p.returnPolicyId : p.returnPolicyId === this.state.marketplaceInfos.ebay.returnPolicies.default.returnPolicyId
                                            }) )}
                                            label="Return Policy"
                                            getValue={value => this.changeMarketplaceSettings("ebay", "returnPolicyId", value[0])}
                                            />
                                            <MDBInput label="eBay Plus" containerClass="w-100" type="checkbox" id="ebayPlus"
                                            onChange={e => this.changeMarketplaceSettings("ebay", "ebayPlus", e.target.checked)}

                                            />
                                        </>
                                        }
    
                                </MDBCol>
                            ))}
                        </MDBRow>
                        </CardBody>
                    </Card>
            </MDBCol>
          </MDBRow>
          <MDBRow>
          <MDBCol size="6" className="text-left">
                <MDBBtn color="danger" onClick={() => this.changeActiveStep(this.state.activeStep - 1)}>Back</MDBBtn>
            </MDBCol>
          <MDBCol size="6" className="text-right">
                <MDBBtn color="success" onClick={() => this.changeActiveStep(this.state.activeStep + 1)}>Continue</MDBBtn>
            </MDBCol>
          </MDBRow>
          </section>
             )}

          {this.state.activeStep === 3 &&
            (<section>
            <MDBRow>
            <MDBCol size="12">
                <Card className="w-100">
                        <CardHeader color="stylish-color">Validate Products</CardHeader>
                        <CardBody className="p-0">
                        <Table responsive className="text-center">
                                <TableHead  color="teal" textWhite columns={data_checkboxesValidate.columns} />
                                <TableBody rows={this.state.products && this.validateProducts(this.state.selectedProducts, this.state.selectedMarketplaces)} />
                            </Table>
                        </CardBody>
                    </Card>
            </MDBCol>
          </MDBRow>
          <MDBRow>
          <MDBCol size="6" className="text-left">
                <MDBBtn color="danger" onClick={() => this.changeActiveStep(this.state.activeStep - 1)}>Back</MDBBtn>
            </MDBCol>
          <MDBCol size="6" className="text-right">
                <MDBBtn color="success" onClick={() => this.changeActiveStep(this.state.activeStep + 1)}>Continue</MDBBtn>
            </MDBCol>
          </MDBRow>
          </section>
             )}

          {this.state.activeStep === 4 &&
            (<section>
            <MDBRow>
            <MDBCol size="12">
                <Card className="w-100">
                        <CardHeader color="stylish-color">Publish Products</CardHeader>
                        <CardBody>
                        <MDBRow>
                            <MDBCol className="text-center">
                                <MDBBtn size="lg"
                                onClick={this.publishProducts}
                                disabled={this.state.isPublishing}
                                color="green"
                                className="mb-3"
                                >Publish Products</MDBBtn>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <Table responsive className="text-center">
                                    <TableHead  color="teal" textWhite columns={data_checkboxesValidate.columns} />
                                    <TableBody rows={this.state.products && this.renderPublishProducts(this.state.selectedProducts, this.state.selectedMarketplaces)} />
                                </Table>
                            </MDBCol>
                        </MDBRow>
                        </CardBody>
                    </Card>
            </MDBCol>
          </MDBRow>
          <MDBRow>
          <MDBCol size="12" className="text-left">
                <MDBBtn color="danger" onClick={() => this.changeActiveStep(this.state.activeStep - 1)}>Back</MDBBtn>
            </MDBCol>
          </MDBRow>
          </section>
             )}
    </section>
    );
  };
}

export default CreateListings;