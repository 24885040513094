import { Component } from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBRow, MDBCol, Card, CardBody, Input } from 'mdbreact';
import {ProductsAPI} from "../../Api/connect";
import TreeMenu from 'react-simple-tree-menu';

const buildTree = function (data, root) {
    var o = {};
    data.forEach(function(a) {
        a = Object.assign({}, a);
        if (o[a.id] && o[a.id].nodes) {
            a.nodes = o[a.id].nodes;
        }
        o[a.id] = { [a.id]: a };
        o[a.parent] = o[a.parent] || {};
        o[a.parent][a.parent] = o[a.parent][a.parent] || {};
        o[a.parent][a.parent].nodes = o[a.parent][a.parent].nodes || {};
        o[a.parent][a.parent].nodes[a.id] = a;
    });
    return o[root][root].nodes;
  };


class CreateCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            storeMatch: {},
            seo: {}
        }
    }

    componentWillReceiveProps() {
            this.setState({
                id: this.props.category.id,
                name: this.props.category.name,
                storeMatch: this.props.category.storeMatch || {},
                seo: this.props.category.seo || {},
                parentId: this.props.category.parentID
            }) 
    }
    
    formCategories = (categories) => {
        const cats = categories.map(c => {
            return ({
                index: c.id,
                id: c.id,
                parent: c.parentID,
                label: c.name,
                short: c.short
            })
        });
        console.log(cats);
        return buildTree(cats, "0");
    }

    execute = async () => {
        const body = {
            category: {
                id: this.state.id,
                parentID: this.state.parentId,
                name: this.state.name && this.state.name,
                short: this.state.name.toLowerCase().replace(/ /g, "_"),
                storeMatch: Object.keys(this.state.storeMatch).length > 0 ? this.state.storeMatch : null,
                seo: Object.keys(this.state.seo).length > 0 ? this.state.seo : null
            }
        };
        try {
            await ProductsAPI.updateCategory(null, body);
            this.props.toggle("EditCategory");
        } catch (error) {
            console.log(error)
        }
    }

    changeParent(id) {
        console.log("changed to: " + id);
        // this.setState({parentId: id})
    }

    changeVal = e => {
        this.setState({[e.target.id]: e.target.value})
    }
    changeValStore = e => {
        const id = e.target.id;
        const val = e.target.value;
        this.setState(state => ({
            storeMatch: {
                ...state.storeMatch,
                [id]: val
            }
        }))
    }
    changeValSeo = e => {
        const id = e.target.id;
        const val = e.target.value;
        if (val.length > 0) {
            this.setState(state => ({
                seo: {
                    ...state.seo,
                    [id]: val
                }
            }))
        } else {
            this.setState(state => ({
                seo: {
                    ...state.seo,
                    [id]: null
                }
            })) 
        }
    }

    render= () => {
    if (this.props.isOpen)  {
    return (
        <MDBModal isOpen={this.props.isOpen} toggle={() => {this.props.toggle("EditCategory")}} centered size="xl">
          <MDBModalHeader toggle={() => {this.props.toggle("EditCategory")}}>Edit Category: {this.props.category.name}</MDBModalHeader>
          <MDBModalBody>
          <MDBRow>
              <MDBCol md="4">
                <Card>
                    <CardBody>
                    <h5 className="text-center">Parent Category</h5>
                    {this.props.categories.length > 0 && 

                        <TreeMenu
                        data={this.formCategories(this.props.categories)}
                        initialActiveKey={this.state.parentId}
                        onClickItem={({ key, label, ...props }) => {
                            console.log(props.short)
                            this.setState({parentId: parseInt(props.id, 10)})
                        }}
                        debounceTime={125}>
                            {({ search, items }) => (
                                <>
                                <Input
                                style={{
                                    border: "none"
                                }}
                                aria-label="Type and search"
                                type="search"
                                label="Type and search"
                                onChange={e => search(e.target.value)} placeholder="Type and search" />
                                <ul
                                style={{
                                    listStyleType: "none",
                                    paddingLeft: 0,
                                    borderTop: "1px solid #ccc"
                                }}
                                >
                                    {items.map(props => {
                                    console.log("PRops: " + JSON.stringify(props));
                                    return <li
                                            key={props.id}
                                            parent={props.parent}
                                            level={props.level}
                                            opennodes={props.opennodes}
                                            index={props.index}
                                            id={props.id}
                                            onClick={props.onClick}
                                            style={{
                                            padding: " .75rem  1rem  .75rem " + (3.5 + 1.25 * props.level) + "rem",
                                            cursor: "pointer",
                                            color: props.active ? "white" : "#333",
                                            background: props.active ? "#009688" : "none",
                                            borderBottom: "1px solid #ccc"
                                            }}
                                            role={"button"}
                                            aria-pressed={props.active}
                                        >
                                        <div
                                        style={{
                                            position: "absolute",
                                            left: 2 + 1.25 * props.level + "rem",
                                            width: "2rem",
                                            height: "2rem"
                                        }}
                                        >
                                        <div
                                        role="img"
                                        aria-label="Toggle"
                                        >
                                        {props.hasNodes && (props.isOpen ? "-" : "+")}
                                        </div>
                                        </div>
                                        {props.label}
                                        </li>
                                    })}
                                </ul>
                                </>
                            )}
                        </TreeMenu>
                    }
                    
                    </CardBody>
                </Card>
              </MDBCol>
              <MDBCol md="8">
              <Input label="Name" id="name" value={this.state.name} onChange={this.changeVal} />
              <h4>Store match</h4>
              <Input label="Amazon Browse Node" id="amazon" value={this.state.storeMatch.amazon} onChange={this.changeValStore} />
              <Input label="eBay Category ID" id="ebay" value={this.state.storeMatch.ebay} onChange={this.changeValStore} />
              <Input label="Shopware Category ID" id="shopware" value={this.state.storeMatch.shopware} onChange={this.changeValStore} />
              <h4>SEO</h4>
              <Input label="Title" id="title" value={this.state.seo.title} onChange={this.changeValSeo} />
              <Input type="textarea" label="Description" id="desc" value={this.state.seo.desc} onChange={this.changeValSeo} />
              <Input label="Keywords" id="keywords" value={this.state.seo.keywords} onChange={this.changeValSeo} />
              </MDBCol>
          </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color=" rgba-red-strong" onClick={() => {this.props.toggle("EditCategory")}}>Cancel</MDBBtn>
            <MDBBtn color=" rgba-stylish-strong" onClick={this.execute}>Update</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
    )
    } else {
        return null
    }
    }
}



export default CreateCategory;