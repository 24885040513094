import { Component } from "react";
import { 
    CardHeader,
    Input,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBListGroup,
    MDBListGroupItem,
    MDBTable, MDBTableBody, MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption
 } from "mdbreact";

import {SettingsAPI} from "../../Api/connect";



class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "shopware",
            marketplaces: {},
            profile: ""
        }

    }

    async componentDidMount() {
        try {

           const marketplaces = await SettingsAPI.getSettings();
            this.setState({
                marketplaces: marketplaces.settings.marketplaces
            })
            console.log(marketplaces.settings.marketplaces);
        } catch (error) {
            console.log(error)
            
        }
       
    }

toggleTabs = tab => () => {
    if (this.state.activeTab !== tab) {
    this.setState({
        activeTab: tab
    });
    }
    }

renderShopwarePayments = (payments) => payments.map(p => (
    <tr>
        <td className="py-0 px-3"><Input type="text" label="Store Payment ID" value={p.id} /></td>
        <td className="py-0 px-3">
            <MDBSelect>
            <MDBSelectInput selected={p.provider || "Choose payment provider"} />
            <MDBSelectOptions>
                <MDBSelectOption disabled>Choose payment provider</MDBSelectOption>
                <MDBSelectOption value="1">paypal</MDBSelectOption>
                <MDBSelectOption value="2">amazon</MDBSelectOption>
                <MDBSelectOption value="3">amazon_pay</MDBSelectOption>
                <MDBSelectOption value="3">sofort</MDBSelectOption>
                <MDBSelectOption value="3">credit_card</MDBSelectOption>
                <MDBSelectOption value="3">prepayment</MDBSelectOption>
            </MDBSelectOptions>
            </MDBSelect>
        </td>
    </tr>
))

renderShopwareShipping = (shippers) => shippers.map(s => (
    <tr>
        <td className="py-0 px-3"><Input type="text" label="Store Fulfiller ID" value={s.id} /></td>
        <td className="py-0 px-3">
            <MDBSelect>
            <MDBSelectInput selected={s.provider || "Choose fulfillment provider"} />
            <MDBSelectOptions>
                <MDBSelectOption disabled>Choose fulfillment provider</MDBSelectOption>
                <MDBSelectOption value="1">dhl</MDBSelectOption>
                <MDBSelectOption value="2">ups</MDBSelectOption>
                <MDBSelectOption value="3">amazon_fba</MDBSelectOption>
            </MDBSelectOptions>
            </MDBSelect>
        </td>
    </tr>
))
    
render() {
    return (
        <section>
           <MDBRow>
               <MDBCol sm="4">
                <MDBListGroup className="my-4 mx-4" style={{ width: "22rem" }}>
                        <MDBListGroupItem color="light" onClick={this.toggleTabs("amazon")}>Amazon</MDBListGroupItem>
                        <MDBListGroupItem color="light" onClick={this.toggleTabs("ebay")}>Ebay</MDBListGroupItem>
                        <MDBListGroupItem color="light" onClick={this.toggleTabs("shopware")}>Shopware</MDBListGroupItem>
                    </MDBListGroup>
               </MDBCol>
                <MDBCol sm="8">
                <MDBCard>
                <CardHeader color="stylish-color">{this.state.activeTab.charAt(0).toUpperCase() + this.state.activeTab.slice(1)}</CardHeader>
                    <MDBRow>
                        <MDBCol className="p-5">
                        <h4>Amazon MWS Credentials</h4>
                        <p className="font-weight-bold">Get your credentials here: <a href="https://developer.amazonservices.de/">Amazon MWS Registration</a>
                        <br />
                        Register credentials for application: YTEC Connect and developer/app id: 223745577728</p>
                        <Input type="text" label="SellerId" value={this.state.marketplaces.ebay && this.state.marketplaces.amazon.sellerId} />
                        <Input type="text" label="Token" value={this.state.marketplaces.ebay && this.state.marketplaces.amazon.token} />
                        <br />
                        <h4>Amazon Marketplaces</h4>
                        <MDBTable borderless>
                        <MDBTableBody>
                            <tr className="text-center">
                                <td>{this.state.marketplaces.amazon ? this.state.marketplaces.amazon.marketplaces[0].country : "DE"}</td>
                                <td className="p-0"><Input type="text" label="Marketplace ID" value={this.state.marketplaces.amazon && this.state.marketplaces.amazon.marketplaces[0].id} /></td>
                            </tr>
                            <tr className="text-center">
                            <td>{this.state.marketplaces.amazon ? this.state.marketplaces.amazon.marketplaces[1].country : "UK"}</td>
                                <td className="p-0"><Input type="text" label="Marketplace ID" value={this.state.marketplaces.amazon && this.state.marketplaces.amazon.marketplaces[1].id} /></td>
                            </tr>
                            <tr className="text-center">
                            <td>{this.state.marketplaces.amazon ? this.state.marketplaces.amazon.marketplaces[2].country : "FR"}</td>
                                <td className="p-0"><Input type="text" label="Marketplace ID" value={this.state.marketplaces.amazon && this.state.marketplaces.amazon.marketplaces[2].id} /></td>
                            </tr>
                            <tr className="text-center">
                            <td>{this.state.marketplaces.amazon ? this.state.marketplaces.amazon.marketplaces[3].country : "ES"}</td>
                                <td className="p-0"><Input type="text" label="Marketplace ID" value={this.state.marketplaces.amazon && this.state.marketplaces.amazon.marketplaces[3].id} /></td>
                            </tr>
                            <tr className="text-center">
                            <td>{this.state.marketplaces.amazon ? this.state.marketplaces.amazon.marketplaces[4].country : "IT"}</td>
                                <td className="p-0"><Input type="text" label="Marketplace ID" value={this.state.marketplaces.amazon && this.state.marketplaces.amazon.marketplaces[4].id} /></td>
                            </tr>
                        </MDBTableBody>
                        </MDBTable>
                        </MDBCol>
                    </MDBRow>
                    </MDBCard>
                </MDBCol>
           </MDBRow>
        </section>
     );
}
  
};

export default Settings;