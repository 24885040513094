import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {API} from "aws-amplify";


// const getActiveMarketplaces = createAsyncThunk(
//     'marketplaces/fetchActiveMarketplaces',
//     async () => {
//       const response = await API.get("ConnectApi", "settings/component", {queryStringParameters: {component: "marketplaces"}})
//       return response
//     }
//   )

export const shippingProviders = createSlice({
    name: "shippingProviders",
    initialState: {active: []},
    reducers: {
        setShippingProviders: (state, action) => {
            return action.payload
        },
        addActiveShippingProvider: (state, action) => {

            return {active: [...state.active, action.payload]}
        }
    },
    // extraReducers: (builder) => {
    //     // Add reducers for additional action types here, and handle loading state as needed
    //     builder.addCase(fetchActiveMarketplaces.fulfilled, (state, action) => {
    //       return action.payload
    //     })
    //   },
})

export const { setShippingProviders, addActiveShippingProvider } = shippingProviders.actions

export default shippingProviders.reducer