import React, { useState, useEffect } from 'react'
import {
    MDBRow,
    MDBCol,
    MDBInput,
    MDBCard,
    MDBCardBody,
    MDBBtn,
    MDBIcon,
    Table,
    TableBody,
    MDBPagination,
    MDBPageItem,
    MDBPageNav,
    MDBCardText,
    MDBBadge
} from "mdbreact";
import {ProductsAPI} from "../../../Api/connect";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader";

const renderProducts = (products, setBundleItems) => {

    return products.map(product => ({
            img: product.image ? <img src={product.image} alt={product.name} height="50" /> : <img src="http://cdn.eu.com/connect/img/image-placeholder.jpg" alt={product.name} height="50" />,
            sku: product.sku,
            name: product.name,
            action: <MDBBtn outline size="sm" onClick={() => setBundleItems(prevState => [...prevState, {id: product.id, qty:1, stock: product.stock || {}}])} >Add</MDBBtn>
    }));

}

const renderPages = (searchParams, searchedProducts) => {
    const limit = searchParams.limit;
    const totalItems = searchedProducts.count;
    const lastItem = searchParams.limit + searchParams.from
    let pageItems = [];
        for (let index = 0; index < (totalItems / limit); index++) {
           pageItems.push(<MDBPageItem
                            key={index}
                            active={lastItem / limit === index + 1 ? true : false}
                            onClick={() => {this.toPage(index * limit)}}
                            >
                    <MDBPageNav>
                        {index + 1}
                    </MDBPageNav>
                    </MDBPageItem>)
        }
    return pageItems;
}

export default function EditProduct_Bundlevariants(props) {
    const { t} = useTranslation();
    const { product, handleChange } = props;

    

    const [isBundle, setIsBundle] = useState(product.bundleItems ? product.bundleItems.length > 0 : false)
    const [bundleItems, setBundleItems] = useState(product.bundleItems || [])

    useEffect(() => {
        // IF is first get bundles
        if(!isBundle && (!bundleItems || bundleItems.length === 0) && (product.bundleItems && product.bundleItems.length > 0)) {
            setIsBundle(product.bundleItems ? product.bundleItems.length > 0 : false)
            setBundleItems(product.bundleItems)
        }
      }, [product.bundleItems])

    const [bundleItemsData, setBundleItemsData] = useState([])
    // Bundle search products
    const [searchQuery, setSearchQuery] = useState("")
    const [searchParams, setSearchParams] = useState({from: 0, limit: 30})
    const [isLoadingSearch, setIsLoadingSearch] = useState(false)
    const [searchedProducts, setSeachedProducts] = useState({})
    
    const searchProducts = async (from) => {
        setIsLoadingSearch(true)
        try {
            const esRes = await ProductsAPI.searchProducts({
                limit: searchParams.limit,
                from: from || searchParams.from,
                q: searchQuery
            })
            setSeachedProducts({
                products: esRes.products,
                totalItems: esRes.count
            })
        } catch (error) {
            console.log(error)
        }
        setIsLoadingSearch(false)
    }
    const searchInputKeyPress = e => {
        if(e.key === 'Enter'){
            setSearchParams(prevState => ({...prevState, from: 0}))
            searchProducts(0);
        } 
    }

    const nextPage = (from) => {
        setSearchParams(prevState => ({...prevState, from: from + searchParams.limit}))
        searchProducts(from + searchParams.limit)
    }
    const 
    prevPage = (from) => {
        setSearchParams(prevState => ({...prevState, from: from - searchParams.limit}))
        searchProducts(from - searchParams.limit)
    }

    // Selected Bundles Data
    const getBundleProductsData = async bundleItems => {
        try {
            const esRes = await ProductsAPI.searchProducts({
                filter: {
                    id: bundleItems.map(i => i.id)
                }
            })
            setBundleItemsData(esRes.products)
            console.log(esRes)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
      handleChange("bundleItems", bundleItems)
      getBundleProductsData(bundleItems)
    }, [bundleItems])
    
    return (
        <>
           <MDBRow>
                <MDBCol size="12">
                    <h4 className="float-left">Bundles</h4>
                    <MDBInput
                        type="checkbox"
                        label="Active"
                        containerClass="float-left ml-3 mt-1"
                        id="isbundle"
                        checked={isBundle}
                        onChange={e => setIsBundle(e.target.checked)}
                    />
                </MDBCol>
                {isBundle &&
                <MDBCol size="12">
                    <MDBRow className="py-3">
                    <MDBCol md="5">
                    <h5>Included in bundle</h5>
                    <MDBCard>
                        <MDBCardBody>
                            {bundleItems.length > 0 ? bundleItems.map((bundleItem, bundleItemIndex) => {
                                const itemData = bundleItemsData.find(i => i.id === bundleItem.id);
                                return <MDBCard className='mb-2'>
                                <MDBCardBody className="p-3">
                                    <MDBCardText className="mb-0">
                                    <MDBRow>
                                        <MDBCol size="3" className="text-center">
                                        {itemData?.image ? <img src={itemData.image} alt={itemData?.name} height="50" className="mx-auto" /> : <img src="http://cdn.eu.com/connect/img/image-placeholder.jpg"  className="mr-4" alt={product.name} height="30" />}
                                        </MDBCol>
                                        <MDBCol size="9">
                                        {itemData?.name}
                                        <MDBInput hint="Quantity" outline size="sm" type="number" steps="1" style={{width: "60px"}} icon="times" value={bundleItem.qty} onChange={e => setBundleItems(prevState => {
                                            let newBundleItems = [...prevState];
                                            newBundleItems[bundleItemIndex].qty = e.target.value;
                                            return newBundleItems
                                        })} />
                                        </MDBCol>
                                    </MDBRow>
                                    </MDBCardText>
                                    <MDBIcon icon="times-circle" className="red-text float-right" style={{position: "absolute", top: ".66rem", right: ".66rem"}} onClick={() => setBundleItems(prevState => [...prevState, {id: product.id, stock: product.stock || {}}])} />
                                </MDBCardBody>
                            </MDBCard>
                            })
                            : <span>No products selected for bundle</span>
                            }
                        </MDBCardBody>
                    </MDBCard>
                        
                    </MDBCol>
                    <MDBCol md="7">
                    <h5>Add products to bundle</h5>
                        <MDBCard>
                        <MDBCardBody className="pt-0">
                        <MDBRow>
                        <MDBCol size="2">
                                <MDBBtn outline size="sm" className="mt-4" onClick={() => searchProducts()}>
                                    <MDBIcon icon="sync" className="mr-0" />
                                </MDBBtn>
                            </MDBCol>
                            <MDBCol size="10">
                                    <MDBRow>
                                        <MDBCol size="10">
                                            <MDBInput label="Search"
                                            id="search"
                                            onChange={e => setSearchQuery(e.target.value)}
                                            onKeyPress={e => searchInputKeyPress(e)}
                                            />
                                        </MDBCol>
                                        <MDBCol size="2">
                                        <MDBBtn outline size="sm" className="mt-4" onClick={() => searchProducts()}>
                                            <MDBIcon icon="search" className="mr-0" />
                                        </MDBBtn>
                                        </MDBCol>
                                    </MDBRow>
                            </MDBCol>
                        </MDBRow>
                            <Table responsive className="text-center">
                                {/* <TableHead  color="teal" textWhite columns={data_checkboxes.columns} /> */}
                                <thead data-test="table-head" className="text-white teal">
                                    <tr>
                                        <th className="">{t("gen.image", "Image")}</th>
                                        <th className="">{t("gen.sku", "SKU")}</th>
                                        <th className="">{t("gen.name", "Name")}</th>
                                        <th className="">{t("gen.action", "Action")}</th>
                                    </tr>
                                </thead>
                                <TableBody rows={searchedProducts.products?.length > 0 && renderProducts(searchedProducts.products, setBundleItems)} />
                            
                            </Table>
                            {isLoadingSearch && <Loader /> }
                            {!isLoadingSearch && searchedProducts.products?.length === 0  &&
                                <div className="text-center w-100">
                                    <img className="rounded-0 mx-auto" src="https://s3.eu-central-1.amazonaws.com/cdn.eu.com/ch/illustration/Create_Product.png" alt="Create Product" />
                                    <h6>Keine Produkte gefunden. </h6>
                                </div>
                            }
                                <MDBRow className="w-100">
                                    <MDBCol size="11">
                                        <MDBPagination color="teal" style={{maxWidth: "100%", justifyContent: "center", display: "flex"}}>
                                            <MDBPageItem>
                                            <MDBPageNav aria-label="Previous" onClick={() => prevPage(searchParams.from)}>
                                                <span aria-hidden="true">&laquo;</span>
                                                <span className="sr-only">Previous</span>
                                            </MDBPageNav>
                                            </MDBPageItem>
                                            {renderPages(searchParams, searchedProducts)}
                                            <MDBPageItem>
                                            <MDBPageNav onClick={() => nextPage(searchParams.from)}>
                                                &raquo;
                                            </MDBPageNav>
                                            </MDBPageItem>
                                        </MDBPagination>
                                        </MDBCol>
                                        <MDBCol size="1">
                                    </MDBCol>
                                </MDBRow>
                        </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                </MDBCol>
                }
            </MDBRow> 
        </>
        
    )
}
