import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {API} from "aws-amplify";


// const getActiveMarketplaces = createAsyncThunk(
//     'marketplaces/fetchActiveMarketplaces',
//     async () => {
//       const response = await API.get("ConnectApi", "settings/component", {queryStringParameters: {component: "marketplaces"}})
//       return response
//     }
//   )

export const marketplaces = createSlice({
    name: "marketplaces",
    initialState: {active: []},
    reducers: {
        setMarketplaces: (state, action) => {
            return action.payload
        },
        addActiveMarketplace: (state, action) => {

            return {active: [...state.active, action.payload]}
        }
    },
    // extraReducers: (builder) => {
    //     // Add reducers for additional action types here, and handle loading state as needed
    //     builder.addCase(fetchActiveMarketplaces.fulfilled, (state, action) => {
    //       return action.payload
    //     })
    //   },
})

export const { setMarketplaces, addActiveMarketplace } = marketplaces.actions

export default marketplaces.reducer