import { Component } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  Table,
  TableHead,
  TableBody,
} from 'mdbreact';
import {OrdersAPI} from "../../Api/connect";
import images from "../images";

export default class CapturePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderIds: props
        .orders
        .map(order => order.connectOrderId)
    }
  }

  // componentWillReceiveProps(props) {
  //   this.setState({
  //     orderIds: props
  //       .orders
  //       .map(order => order.connectOrderId)
  //   })
  // }

  renderOrders = (orders) => {
    if (!orders) {
      return
    }
    return orders.map((order) => ({
      "orderNumber": <span>{order.connectOrderId}<br/>
        <span className="grey-text">{order.storeOrderId}</span>
      </span>,
      "store": images[order.intPartner],
      "address": <span>{order.shippment.address.fullName || order.customerData.firstName + " " + order.customerData.lastName}<br/>{order.shippment.address.street}<br/>{order.shippment.address.zip + " " + order.shippment.address.city}<br/>{order.shippment.address.country}</span>,
      "articles": <span>{order
          .articles
          .map(a => <span>{a
              .name
              .substring(0, 50)}<br/>
            <span className="grey-text">{a.sku}</span>
          </span>)}</span>,
      "provider": <span>
          {images[order.financial.payment.provider]}
          <br/>
          <span
            style={{
            color: order.financial.payment.setaspayed
              ? "#00c300"
              : "red"
          }}
            className="font-weight-bold">{order.financial.invoiceAmount}€</span>
          <span
            style={{
            color: order.invoice && order.invoice.sentEmail
              ? "#00c300"
              : "black"
          }}
            className="font-weight-bold">{order.invoice && " | RE" + order.invoice.number}</span>
        </span>
    }))
  };

  execute = async() => {
    try {
      await OrdersAPI.capturePayment(null, {orderIds: this.state.orderIds});
      this
        .props
        .toggle("capturePayment", true);
    } catch (error) {
      console.log(error)
    }
  }

  render = () => {
    const data_checkboxes = {
      columns: [
        {
          'label': 'Order number',
          'field': 'orderNumber',
          'sort': 'asc'
        }, {
          'label': 'Store',
          'field': 'store',
          'sort': 'asc'
        }, {
          'label': 'Address',
          'field': 'address',
          'sort': 'asc'
        }, {
          'label': 'Articles',
          'field': 'articles',
          'sort': 'asc'
        }, {
          'label': 'Provider',
          'field': 'provider',
          'sort': 'asc'
        }

      ]
    };
    return (
      <MDBModal
        isOpen={this.props.isOpen}
        toggle={() => {
        this
          .props
          .toggle("capturePayment")
      }}
        centered
        size="xl">
        <MDBModalHeader
          toggle={() => {
          this
            .props
            .toggle("capturePayment")
        }}>Capture Payment</MDBModalHeader>
        <MDBModalBody>
          <Table responsive className="text-center">
            <TableHead color="teal" textWhite columns={data_checkboxes.columns}/>
            <TableBody rows={this.renderOrders(this.props.orders)}/>
          </Table>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            color=" rgba-red-strong"
            onClick={() => {
            this
              .props
              .toggle("capturePayment")
          }}>Cancel</MDBBtn>
          <MDBBtn color=" rgba-stylish-strong" onClick={this.execute}>Capture Payment</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }
}