import { useState } from 'react';
import queryString from 'query-string';
import { MDBContainer, MDBIcon } from 'mdbreact';
import {API} from 'aws-amplify';
const shopifyClientID = "4def2e636055c0fee236ee7414bce5b3"
const shopifyRedirectURI = "https://app.channelhive.com/settings/oauth/shopify"
const shopifyScopes = "write_orders,write_customers,write_assigned_fulfillment_orders,write_draft_orders,write_fulfillments,write_inventory,read_locales,read_locations,write_merchant_managed_fulfillment_orders,write_products,read_product_listings,read_reports,write_shipping,read_shopify_payments_disputes,write_third_party_fulfillment_orders,write_order_edits"

 export default function OAuth_Shopify() {
    const [qsParams, setQsParams] = useState({})
    const [setupState, setSetupState] = useState("open")

    const verifyShopifyRequest = async (qs, hmac, shop) => {
        //await new Promise(resolve => setTimeout(resolve, 5000));
        try {
            
            const verifyRes = await API.post("ConnectApi", "/oauth/shopify/verifyrequest", {body: {qs, hmac}})
            console.log("verifyRes", verifyRes)
            setSetupState("success")
            shop = shop.replace(".myshopify.com", "")
            window.location.replace(`https://${shop}.myshopify.com/admin/oauth/authorize?client_id=${shopifyClientID}&scope=${shopifyScopes}&redirect_uri=${shopifyRedirectURI}&state=${JSON.stringify({ from: 'shopifyStore', storeName: shop })}`)
        } catch (error) {
            console.log("verification Failed", verifyRes)
            setSetupState("failed")
        }
    }

    let qs = queryString.parse(window.location.search);
    console.log(qs);
    if (qs.shop && qs.timestamp && qs.hmac) {
        console.log("Validating connection request for: " + qs.shop);
        const hmac = qs.hmac;
        delete qs.hmac;
        const qsString = queryString.stringify(qs)
        verifyShopifyRequest(qsString, hmac, qs.shop);
    } else {
        console.log("no code found")
    }

    const renderLoading = setupState => {
        switch (setupState) {
            case "open":
                return <MDBContainer className="text-center">
                     <div className="spinner-grow text-default" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <h5>Verifying request and redirecting to your Shopify store...</h5>
                    <p className="mt-5" style={{ fontSize: 8}}>StoreName: {qs.shop}</p>
                </MDBContainer>
            break;
            case "success":
                return <MDBContainer className="text-center">
                        <MDBIcon fa icon="check" className="teal-text" style={{fontSize: 20}} />
                        <h5>Click here if you didn't get redirected</h5>
                </MDBContainer>
            break;
            case "failed":
                return <MDBContainer className="text-center">
                        <MDBIcon fa icon="exclamation-triangle" className="teal-text" style={{fontSize: 20}} />
                        <h5>verification failed</h5>
                </MDBContainer>
             break;
                    
            default:
                break;
        }
    }
   
   return renderLoading(setupState)
 }