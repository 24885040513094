import { Component } from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, Table, TableHead, TableBody } from 'mdbreact';
import {OrdersAPI} from "../../Api/connect";
import images from "../images";


export default class CancelOrder extends Component {
   
    renderOrders = (orders) => {
        if (!orders) {
            return
        }
        return orders.map((order) => ({
                "orderNumber": <span>{order.connectOrderId}<br /><span className="grey-text">{order.storeOrderId}</span></span>,
                "store": images[order.intPartner],
                "address": <span>{order.shippment.address.fullName || order.customerData.firstName + " " + order.customerData.lastName}<br />{order.shippment.address.street}<br />{order.shippment.address.zip + " " + order.shippment.address.city}<br />{order.shippment.address.country}</span>,
                "articles": <span>{order.articles.map(a => <span>{a.name.substring(0,50)}<br /><span className="grey-text">{a.sku}</span></span>)}</span>
        }))
    };

    execute = async () => {
        const body = {
            orderIds: this.props.orders.map(o => o.connectOrderId)
        };
        try {
            await OrdersAPI.cancelOrders(null, body);
            this.props.toggle("cancel");
        } catch (error) {
            console.log(error)
        }
    }

    render= () => {
        const data_checkboxes = {
            columns: [
              {
                'label': 'Order number',
                'field': 'orderNumber',
                'sort': 'asc'
              },
              {
                'label': 'Store',
                'field': 'store',
                'sort': 'asc'
              },
              {
                'label': 'Address',
                'field': 'address',
                'sort': 'asc'
              },
              {
                'label': 'Articles',
                'field': 'articles',
                'sort': 'asc'
              },
             
            ]
          };
    return (
        <MDBModal isOpen={this.props.isOpen} toggle={() => {this.props.toggle("cancel")}} centered size="xl">
          <MDBModalHeader toggle={() => {this.props.toggle("cancel")}}>Cancel</MDBModalHeader>
          <MDBModalBody>
          <Table responsive className="text-center">
                 <TableHead  color="teal" textWhite columns={data_checkboxes.columns} />
                 <TableBody rows={this.renderOrders(this.props.orders)} />
            </Table>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color=" rgba-red-strong" onClick={() => {this.props.toggle("cancel")}}>Cancel</MDBBtn>
            <MDBBtn color=" rgba-stylish-strong" onClick={this.execute}>Cancel Orders</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
    )
    }
}
