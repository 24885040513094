import { Component } from 'react';
import { MDBListGroup,  MDBCard, MDBCardBody, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBRow, MDBCol} from 'mdbreact';
import {OrdersAPI} from "../../Api/connect";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


class Ticket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textField: "gffg"
        }
    }
   
   
    
    execute = async () => {

        try {
            await OrdersAPI.instructShipping();
            this.props.toggle("ticket");
        } catch (error) {
            console.log(error)
        }
    }

    onEditorStateChange = (e) => {
        console.log(e)
    }


    render= () => {
        const ChatMessage = ({ message: { author, avatar, when, message } }) => (
            <li className="chat-message d-flex justify-content-between mb-4">
              <MDBCard className="ml-5">
                <MDBCardBody>
                  <div>
                    <strong className="primary-font">{author}</strong>
                    <small className="pull-right text-muted">
                      <i className="far fa-clock" /> {when}
                    </small>
                  </div>
                  <hr />
                  <p className="mb-0">{message}</p>
                </MDBCardBody>
              </MDBCard>
            </li>
          );
    return (
        <MDBModal isOpen={this.props.isOpen} toggle={() => {this.props.toggle("ticket")}} centered size="xl">
          <MDBModalHeader toggle={() => {this.props.toggle("ticket")}}>{"#" + this.props.ticket.id + " - " + this.props.ticket.subject}</MDBModalHeader>
          <MDBModalBody>
          
            <MDBRow className="mt-5">
                <MDBCol size="12" md="8" className="mx-auto">
                <MDBListGroup className="list-unstyled pl-3">
                  {this.props.ticket.messages && this.props.ticket.messages.map(message => (
                    <ChatMessage
                      key={message.timestamp}
                      message={{
                          author: this.props.ticket.requestor.substring(0,15),
                          avatar: "",
                          when: new Date(message.timestamp*1000).toISOString().substring(0, 16).replace("T", " "),
                          message: message.body
                          }}
                    />
                  ))}
                  <li>
                    <div className="form-group basic-textarea">
                    <Editor
                        // editorState={this.state.textField}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={this.onEditorStateChange}
                        />
                      
                    </div>
                  </li>
                </MDBListGroup>
                </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color=" rgba-red-strong" onClick={() => {this.props.toggle("ticket")}}>Cancel</MDBBtn>
            <MDBBtn color=" rgba-grey-strong" onClick={() => {this.execute(true)}}>Draft</MDBBtn>
            <MDBBtn color=" rgba-stylish-strong" onClick={this.execute}>Reply</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
    )
    }
}



export default Ticket;