import { Component } from "react";
import { 
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Button,
    ListGroup,
    ListGroupItem,
    MDBIcon,
    Table,
    TableBody,
    TableHead,
    MDBInput,
 } from "mdbreact";
import {FinancialAPI} from "../../Api/connect";

import CreateBillModal from "../../components/financial/bills/CreateBill";
import UpdateStatusModal from "../../components/financial/bills/UpdateStatus";
import ViewBillModal from "../../components/financial/bills/ViewBill";



class Bills extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bills: [],
            orders: [],
            dueDays: 40,
            totalAmount: 0,
            selectedBills: [],
            selectBill: {},
            searchFilters: {
                perPage: 40,
                partnerId: "solectric"
            },
            modals: {
                createBill: {
                    isOpen: false,
                    items: []
                },
                updateStatus: {
                    isOpen: false,
                    items: []
                },
                viewBill: {
                    isOpen: false,
                    items: []
                }
            },
            settings: {}
        }

    }

    async componentDidMount() {
        this.getBills();
    }

    getBills = async (qs) => {
        try {
            const bills = await FinancialAPI.getBills(qs || this.state.searchFilters);
            this.setState({bills: bills.reverse()});
        } catch (error) {
            console.log(error)
        }
    }

    handleFilter = async (e) => {
        const {id, value} = e.target;
        // const add = (accumulator, a) => {
        //     return accumulator + a;
        // };
        const partner = await this.getPartner(this.state.searchFilters);
        this.setState({
            partner,
            bills: partner.bills.filter(b => b[id] === value),
        totalAmount: partner.bills.filter(b => b[id] === value).map(b => {
            if (typeof(b.amount) === "string") {
                return parseInt(b.amount)
            } else {
                return b.amount
            }
        }).reduce((partial_sum, a) => partial_sum + a,0)
        })
    }


    selectBill = (e) => {
        const id = e.target.id;
        console.log("ID: " + typeof(id));
        this.setState(state => ({
            selectedBills: [...state.selectedBills, this.state.bills.filter(b => b.id === id)[0]],
            selectedBill: this.state.bills.filter(b => b.id === id)[0]
        }))
    }

    viewBill = (id) => {
        this.selectBill({target: {id}});
        this.toggleModal("viewBill")
    }

    instructShipping = () => {
        this.setState(state => ({
            modals: {
                ...state.modals,
                instrShipping: {
                    isOpen: true,
                    items: state.selectedOrders
                }
            }
        }))
    }


    toggleModal = async (id, reset) => {
        try {
            this.setState(state => ({
                modals: {
                    ...state.modals,
                    [id]: {
                        ...!state.modals[id],
                        isOpen: !state.modals[id].isOpen,
                        items: !state.modals[id].isOpen ? state.selectedBills : []
                    }
                },
                bills: this.state.bills,
                selectedBills: reset ? [] : state.selectedBills
            }))
        } catch (error) {
            console.log(error)
        }
    }

    renderBills(bills) {
            const d = new Date();
        return bills.map((bill) => {  
            let billD = new Date(bill.invoiceDate.split('.').reverse().join('/'));
            const dueDay = new Date(billD.getTime() + this.state.dueDays * 86400000);
            const remaining = parseInt((dueDay.getTime() - d.getTime())/(24*3600*1000));
            let color;
            switch (true) {
                case (remaining >= 2 && remaining < 7):
                    color = "orange"
                    break;
                case (remaining < 2):
                    color = "red"
                    break;
                case (remaining > 7):
                    color = "green"
                    break;

                default:
                    break;
            }
            return {
                    check: <div className="form-check">
                                <input type="checkbox" onChange={this.selectBill} className="form-control form-check-input my-0" id={bill.id.toString()} value="" />
                                <label className="form-check-label mr-5" htmlFor={bill.id.toString()} data-error="" data-success="" id={bill.id.toString()}></label>
                            </div>,
                    billID: "#" + bill.id,
                    billNumber: bill.invoiceId,
                    amount: bill.totalAmount + " €",
                    date: bill.invoiceDate,
                    remaining: bill.status === "payed" ? <span className="font-weight-bold">Payed</span> :<span className="font-weight-bold" style={{color: color}}>{remaining} Days</span>,
                    status: bill.status === "payed" ? <span className="font-weight-bold">Payed</span> :
                            <span className="font-weight-bold" style={{color: bill.status === "accepted" ? "green" : "red"}}>{bill.status}</span>,
                    action: <span><MDBIcon className="green-text" far icon="check-square" />
                                    <MDBIcon className="ml-4 red-text" icon="exclamation-circle" />
                                    <MDBIcon className="ml-4" icon="search" onClick={() => this.viewBill(bill.id)} /></span>
                }
        }
        ).reverse();

    }

render() {
    
   
    const data_checkboxes = {
        columns: [
          {
            'label': '#',
            'field': 'check'
          },
          {
            'label': 'Bill ID',
            'field': 'billID',
            'sort': 'desc'
          },
          {
            'label': 'Bill number',
            'field': 'billNumber',
            'sort': 'asc'
          },
          {
            'label': 'Amount',
            'field': 'amount',
            'sort': 'asc'
          },
          {
            'label': 'Billing Date',
            'field': 'date',
            'sort': 'asc'
          },
          {
            'label': 'Remaining',
            'field': 'remaining',
            'sort': 'asc'
          },
          {
            'label': 'Status',
            'field': 'status',
            'sort': 'asc'
          },
          {
            'label': 'Action',
            'field': 'action',
            'sort': 'asc'
          }
        ]
      };
    
    const styleButtonsBig = {
        width: "100%",
        margin: "0px"
    
    };

    return (
        <section>
            <Row>
                <Col md="6">
                <Card className="h-100">
                   <CardHeader color="stylish-color">Actions</CardHeader>
                   <CardBody>
                   <Row>
                       <Col>
                           <ListGroup style={{cursor: "pointer"}}>
                               <ListGroupItem className="d-flex justify-content-between align-items-center" onClick={() => {this.toggleModal("createBill")}}>
                                   Create bill <MDBIcon icon="plus" />
                               </ListGroupItem>
                               <ListGroupItem className="d-flex justify-content-between align-items-center">
                                   Cancel bill <MDBIcon icon="remove" />
                               </ListGroupItem>
                           </ListGroup>
                       </Col>
                       <Col>
                           <ListGroup style={{cursor: "pointer"}}>
                               <ListGroupItem className="d-flex justify-content-between align-items-center">
                                   Edit <MDBIcon icon="edit" />
                               </ListGroupItem>
                               <ListGroupItem className="d-flex justify-content-between align-items-center">
                                   Copy<MDBIcon icon="copy" />
                               </ListGroupItem>
                           </ListGroup>
                       </Col>
                   </Row>
                   </CardBody>
               </Card>
                </Col>
                <Col md="3">
                <Card className="h-100">
                       <CardHeader color="stylish-color">Partner</CardHeader>
                       <CardBody className="text-center">
                       <select
                                className="browser-default custom-select md-form"
                                // value=""
                                style={{backgroundColor: "transparent", border: "none", borderBottom: "1px solid #ced4da", borderRadius: "0"}}
                                id="partner"
                                onChange={this.handleFilter}>
                                <option value="">Partner</option>
                                <option value="verify">Solectric</option>
                                <option value="unpayed">Rayline</option>
                                </select>
                       </CardBody>
                   </Card>
                
                </Col>
                <Col md="3">
                   <Card className="h-100">
                       <CardHeader color="stylish-color">Actions</CardHeader>
                       <CardBody className="text-center">
                       <Button outline style={styleButtonsBig} onClick={() => {this.toggleModal("updateStatus")}}>Change Status</Button>
                       <hr></hr>
                       <span>Returns</span>
                       <Button outline style={styleButtonsBig}>Return items</Button>
                       </CardBody>
                   </Card>
                </Col>
               
            </Row>
            <Row className="py-3">
           <Col md="12">
             <Card>
               <CardBody>
                   <Row>
                       <Col>
                           <Row>
                               <Col>
                               <select
                                className="browser-default custom-select md-form"
                                // value=""
                                style={{backgroundColor: "transparent", border: "none", borderBottom: "1px solid #ced4da", borderRadius: "0"}}
                                id="status"
                                onChange={this.handleFilter}>
                                <option value="">Status</option>
                                <option value="open">Open</option>
                                <option value="payed">Payed</option>
                                <option value="needVerify">Need check</option>
                                </select>
                               
                               </Col>
                               <Col>
                               <select
                                className="browser-default custom-select md-form"
                                // value=""
                                style={{backgroundColor: "transparent", border: "none", borderBottom: "1px solid #ced4da", borderRadius: "0"}}
                                id="store"
                                onChange={this.handleFilter}>
                                <option value="">Store</option>
                                <option value="amazon">Amazon</option>
                                <option value="shopware">Shopware</option>
                                <option value="ebay">eBay</option>
                                <option value="shopify">Shopify</option>
                                <option value="wooCommerce">WooCommerce</option>
                                </select>
                               
                               </Col>
                               <Col>
                               <select
                                className="browser-default custom-select md-form"
                                // value=""
                                style={{backgroundColor: "transparent", border: "none", borderBottom: "1px solid #ced4da", borderRadius: "0"}}
                                id="payment"
                                onChange={this.handleFilter}>
                                <option value="">Payment Provider</option>
                                <option value="paypal">PayPal</option>
                                <option value="amazon_pay">Amazon Pay</option>
                                <option value="amazon">Amazon Marketplace</option>
                                <option value="credit_card">Stripe</option>
                                <option value="sofort">Sofort</option>
                                <option value="prepayment">Prepayment</option>
                                </select>
                              
                               </Col>
                           </Row>
                       </Col>
                       <Col>
                       <Row>
                               <Col md="3">
                                   <MDBInput label="Amount from" />
                               </Col>
                               <Col md="3">
                                    <MDBInput label="Amount to" />
                               </Col>
                               <Col md="6">
                                   <MDBInput label="Search" />
                                   
                               </Col>
                           </Row>
                       </Col>
                   </Row>
                 <Table responsive className="text-center">
                 <TableHead  color="teal" textWhite columns={data_checkboxes.columns} />
                 <TableBody rows={this.state.bills && this.renderBills(this.state.bills)} />
                   </Table>
               </CardBody>
             </Card>
           </Col>
         </Row>

         <CreateBillModal settings={this.state.settings} isOpen={this.state.modals.createBill.isOpen} toggle={this.toggleModal} />
         <UpdateStatusModal settings={this.state.settings} isOpen={this.state.modals.updateStatus.isOpen} bills={this.state.modals.updateStatus.items} toggle={this.toggleModal} />
         <ViewBillModal isOpen={this.state.modals.viewBill.isOpen} bill={this.state.selectedBill} toggle={this.toggleModal} />
        </section>
     );
}
  
};

export default Bills;