import { Component } from 'react';
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  Table,
  TableHead,
  TableBody,
  MDBInput,
  MDBDatePicker,
  MDBCard,
  MDBCardImage
} from 'mdbreact';
import {OrdersAPI} from "../../Api/connect";
import images from "../images";

export default class markShipped extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trackingInfos: props.orders.map(order => ({orderId: order.id, trackingProvider: "dhl"})), // {orderId, trackingId, trackingProvider, shippTime}
      notifyMarketplace: true,
      isLoading: false
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      trackingInfos: props
        .orders
        .map(order => ({orderId: order.id, trackingProvider: "dhl"}))
    }) // {orderId, trackingId, trackingProvider, shippTime}})
  }

  renderSelect = (fulfillers) => {
    const options = Object
      .entries(fulfillers)
      .map(f => (
        <option value={f[0]}>{f[1].name}</option>
      ))

    return (
      <select className="browser-default custom-select" // value=""
  style={{
        backgroundColor: "transparent",
        border: "none",
        borderBottom: "1px solid #ced4da",
        borderRadius: "0"
      }} onChange={this.changeFulfiller}>
        <option>Choose Shipping Provider</option>
        {options}
      </select>
    )
  }
  renderOrders = (orders) => {
    if (!orders) {
      return
    }
    return orders.map((order) => ({
      "orderNumber": <span>{order.id}<br/>
        <span className="grey-text">{order.storeOrderId}</span>
      </span>,
      "store": images[order.store],
      "address": order.addresses.shipping && <span>{order.addresses.shipping.fullName || order.customerData.firstName + " " + order.customerData.lastName}<br />{order.addresses.shipping.street}<br />{order.addresses.shipping.zip + " " + order.addresses.shipping.city}<br />{order.addresses.shipping.country}</span>,
      "articles": <span>{order.articles.names.map((name, index) => <span key={index}>{name.substring(0, 30) + (name.length > 30 && "...")}<br /><span className="grey-text">{order.articles.skus[index]}</span></span>)}</span>,
      "trackingId": <MDBInput
        onChange={this.changeTrackingId}
        id={order
        .id
        .toString()}/>,
      "provider": <select className="browser-default custom-select" // value=""
  style={{
          backgroundColor: "transparent",
          border: "none",
          borderBottom: "1px solid #ced4da",
          borderRadius: "0"
        }} onChange={this.changeTemplate}>
          <option>Provider</option>
          <option value="dhl" selected>DHL</option>
          <option value="ups">UPS</option>
          <option value="fba">FBA</option>
        </select>
    }))
  };

  execute = async() => {
    if(this.state.isLoading) return;
    this.setState({isLoading: true});
    let body = {
      account: this.props.orders[0].account,
      trackingInfos: this
        .state
        .trackingInfos
        .map(trackingInfo => ({
          ...trackingInfo,
          shippTime: this.state.shipTime
        })),
      notifyMarketplace: this.state.notifyMarketplace
    };
    try {
      console.log(body)
      await OrdersAPI.updateOrderStatus(null, body);
      console.log("Send");
      this
        .props
        .toggle("markShipped", true);
    } catch (error) {
      console.log(error)
    }
    this.setState({isLoading: false});
  }

  changeTrackingId = e => {
    const orderId = parseInt(e.target.id);
    const trackingID = e.target.value;

    this.setState(state => ({
      trackingInfos: state
        .trackingInfos
        .map(trackingInfo => {
          if (trackingInfo.orderId !== orderId) {
            return trackingInfo;
          } else {
            return ({
              ...trackingInfo,
              trackingId: trackingID
            });
          }
        })
    }))
  }

  changeTrackingProvider = e => {
    const orderId = parseInt(e.target.id);
    const trackingProvider = e.target.value;

    this.setState(state => ({
      trackingInfos: state
        .trackingInfos
        .map(trackingInfo => {
          if (trackingInfo.orderId !== orderId) {
            return trackingInfo;
          } else {
            return ({
              ...trackingInfo,
              trackingProvider: trackingProvider
            });
          }
        })
    }))
  }

  getShipDate = date => {
    this.setState({
      shipTime: date.toISOString()
    });
    console.log(date);
  }

  render = () => {
    const data_checkboxes = {
      columns: [
        {
          'label': 'Order number',
          'field': 'orderNumber',
          'sort': 'asc'
        }, {
          'label': 'Store',
          'field': 'store',
          'sort': 'asc'
        }, {
          'label': 'Address',
          'field': 'address',
          'sort': 'asc'
        }, {
          'label': 'Articles',
          'field': 'articles',
          'sort': 'asc'
        }, {
          'label': 'Tracking ID',
          'field': 'trackingId',
          'sort': 'asc'
        }, {
          'label': 'Provider',
          'field': 'provider',
          'sort': 'asc'
        }

      ]
    };
    return (
      <MDBModal
        isOpen={this.props.isOpen}
        toggle={() => {
        this
          .props
          .toggle("markShipped")
      }}
        centered
        size="xl">
        
        <MDBModalBody>
        <MDBCard style={{margin: "-1rem"}}>
        <MDBCardImage
            className='teal white-text d-flex justify-content-center align-items-center flex-column p-2 mb-4'
            tag='div'
          >
            <h2>Confirm shipment</h2>
          </MDBCardImage>

        </MDBCard>
          <Table responsive className="text-center">
            <TableHead columns={data_checkboxes.columns}/>
            <TableBody rows={this.renderOrders(this.props.orders)}/>
          </Table>
         
        </MDBModalBody>
        <MDBModalFooter>
        <MDBRow className="mr-auto">
          <MDBCol size="12" md="6" className="mx-auto text-center">
            <h5 className="my-4">Shipping Date:
            </h5>
          </MDBCol>
          <MDBCol size="12" md="6" className="mx-auto">
            <MDBDatePicker disableFuture getValue={this.getShipDate}/>
          </MDBCol>
        </MDBRow>
          <MDBBtn
            outline color="danger"
            onClick={() => {
            this
              .props
              .toggle("markShipped")
          }}>Cancel</MDBBtn>
          <MDBInput containerClass="w-200" label="Notify Marketplace" type="checkbox" id="notifyMarketplaceCheckbox"
          checked={this.state.notifyMarketplace}
          onChange={e => this.setState({notifyMarketplace: e.target.checked})}
           />
          <MDBBtn
          onClick={() => this.execute()}
          >
          {this.state.isLoading ?
            <div className="spinner-grow spinner-small white-text" role="status"></div>
          :
            "Update"
          }</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }
}