import React from 'react'
import {
    MDBRow,
    MDBCol,
    MDBInput
} from "mdbreact";

export default function EditProduct_price(props) {
    const { product, handleChange } = props;

  return (
      <>
      <MDBRow className="border-bottom">
        <MDBCol size="12" md="6" className="border-right">
            <h5 className="font-weight-normal">Purchase & Defaults</h5>
            <MDBInput
                label="Purchasing price"
                id="ek"
                icon="euro-sign"
                type="number"
                step="0.01"
                value={product.price?.ek}
                onChange={e => handleChange("price.ek", e.target.value)}
            />
            <MDBInput
                label="UVP"
                id="uvp"
                icon="euro-sign"
                type="number"
                step="0.01"
                value={product.price?.uvp}
                onChange={e => handleChange("price.uvp", e.target.value)}
            />
            <MDBInput
                label="Selling Price"
                id="vk"
                icon="euro-sign"
                type="number"
                step="0.01"
                value={product.price?.vk}
                onChange={e => handleChange("price.vk", e.target.value)}
            />
            <MDBInput
                label="VAT"
                id="tax"
                icon="percentage"
                type="number"
                step="1"
                value={product.tax || 19}
                onChange={e => handleChange("tax", e.target.value)}
            />
        </MDBCol>
        <MDBCol size="12" md="6">
            <h5 className="font-weight-normal">Marketplaces</h5>
            <MDBInput
            label="Amazon"
            id="amazon"
            icon="euro-sign"
            type="number"
            step="0.01"
            value={product.price?.amazon}
            onChange={e => handleChange("price.amazon", e.target.value)}/>
            <MDBInput
            label="eBay"
            id="ebay"
            icon="euro-sign"
            type="number"
            step="0.01"
            value={product.price?.ebay}
            onChange={e => handleChange("price.ebay", e.target.value)}/>
            <MDBInput
            label="Shopware"
            id="shopware"
            icon="euro-sign"
            type="number"
            step="0.01"
            value={product.price?.shopware}
            onChange={e => handleChange("price.shopware", e.target.value)}/>
            <MDBInput
            label="Idealo"
            id="idealo_direkt"
            icon="euro-sign"
            type="number"
            step="0.01"
            value={product.price?.idealo_direkt}
            onChange={e => handleChange("price.idealo_direkt", e.target.value)}/>

        </MDBCol>
      </MDBRow>
      {product.autoprice &&
              <>
              <h5>Auto price</h5>
              <MDBRow style={{backgroundColor: "#dbdbdb"}}>
              {
                product.autoprice?.amazon &&
                <MDBCol>
                  <MDBInput
                    label="Amazon"
                    id="amazon"
                    icon="euro-sign"
                    type="number"
                    step="0.01"
                    value={product.autoprice.amazon.price}
                    disabled
                    />
                </MDBCol>
              }
              {
                product.autoprice?.ebay &&
                <MDBCol>
                  <MDBInput
                    label="eBay"
                    id="ebay"
                    icon="euro-sign"
                    type="number"
                    step="0.01"
                    value={product.autoprice.ebay.price}
                    disabled
                    />
                </MDBCol>
              }
              {
                product.autoprice?.shopware &&
                <MDBCol>
                  <MDBInput
                    label="Shopware"
                    id="shopware"
                    icon="euro-sign"
                    type="number"
                    step="0.01"
                    value={product.autoprice.shopware.price}
                    disabled
                    />
                </MDBCol>
              }
              </MDBRow>
              </>
              }
      </>
    
  )
}
