import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import {API} from "aws-amplify";
import {ProductsAPI} from "../../../Api/connect";
import { MDBBtn, MDBCard, MDBCardBody, MDBChip, MDBCol, MDBRow } from "mdbreact";

export default function Variants(props) {
    const { t } = useTranslation();
    const { product, handleChange } = props;

    const [attributes, setAttributes] = useState([])

    const getAttributes = async () => {
        try {
            const attributes = await ProductsAPI.getAttributes();
            setAttributes(attributes)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getAttributes()
    }, [])

    // Build Variants

    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const [selectedVariants, setSelectedVariants] = useState([]);

    // Handlers

    const selectAttribute = id => {
        if (selectedAttributes.find(attribute => attribute.id === id)) {
            setSelectedAttributes(oldAttributes => ([...oldAttributes.filter(attribute => attribute.id !== id)]))
            setSelectedVariants(oldVariants => ([...oldVariants.filter(v => v.attributeId !== id)]))
        } else {
            setSelectedAttributes(oldAttributes => ([...oldAttributes, attributes.find(attribute => attribute.id === id)]))
        }
    }
    const selectVariant = (attributeId, variantName) => {
        if (selectedVariants.find(v => v.attributeId === attributeId && v.name === variantName)) {
            setSelectedVariants(oldVariants => ([...oldVariants.filter(v => v.attributeId !== attributeId && v.name !== variantName)]))
        } else {
            setSelectedVariants(oldVariants => ([...oldVariants, {attributeId, name: variantName}]))
        }
    }

    return (
        <>
            <h5 className="font-weight-normal">Generate new variants</h5>
            <MDBRow className="mt-md-3">
                <MDBCol size="12" className="text-center">
                    <h5 className="font-weight-normal">Select attributes</h5>
                    <MDBCard>
                        <MDBCardBody className="p-3">
                            {attributes.map(attribute => <MDBChip className="my-0" bgColor={selectedAttributes.find(a => a.id === attribute.id) ? "green lighten-3" : ""} onClick={() => selectAttribute(attribute.id)}>{attribute.name}</MDBChip>)}
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                {selectedAttributes.length > 0 &&
                <MDBCol size="12" className="text-center mt-md-4">
                    <h5 className="font-weight-normal">Select variants</h5>
                    <MDBCard>
                        <MDBCardBody className="p-3">
                            <MDBRow>
                                {selectedAttributes.map(attribute => <MDBCol size="12">
                                    <h6 className="font-weight-normal mt-2">{attribute.name}</h6>
                                    {attribute.variants.map(variant => <MDBChip className="my-0" bgColor={selectedVariants.find(v => v.attributeId === attribute.id && v.name === variant) ? "green lighten-3" : ""} onClick={() => selectVariant(attribute.id, variant)}>{variant}</MDBChip>)}
                                </MDBCol>)}
                            </MDBRow>
                            
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                }
                {selectedVariants.length > 0 &&
                <MDBCol size="12" className="text-center mt-md-4">
                    <MDBBtn>Generate Variants</MDBBtn>
                </MDBCol>
                }
            </MDBRow>
           
        </>
    )
}
