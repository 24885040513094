import { Component } from 'react';
import { MDBDatePicker, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBRow, MDBCol, MDBInput } from 'mdbreact';
import {FinancialAPI} from "../../../Api/connect";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


class CreateBill extends Component {
    constructor(props) {
        super(props);
        this.state = {
            articleFields: [
               this.articleField(1)
            ],
            billNumber: "",
            orderId: "",
            dueDays: "",
            issueDate: "",
            articles: {},
            articlesCount: 1

        }
    }
   
    articleField = id => (
        <MDBRow key={id}>
                    <MDBCol>
                    <MDBInput label="ArticleNr" type="text" value={this.state && this.state.articles[id] && this.state.articles[id].manufacturerSKU} onChange={(e) => {this.handleChangeArticle(id, "manufacturerSKU", e.target.value)}} />
                    </MDBCol>
                    <MDBCol>
                    <MDBInput label="Name" type="text" value={this.state && this.state.articles[id] && this.state.articles[id].name} onChange={(e) => {this.handleChangeArticle(id, "name", e.target.value)}} />
                    </MDBCol>
                    <MDBCol>
                    <MDBInput label="QTY" type="number" min="0" value={this.state && this.state.articles[id] && this.state.articles[id].qty} onChange={(e) => {this.handleChangeArticle(id, "qty", e.target.value)}} />
                    </MDBCol>
                    <MDBCol>
                    <MDBInput label="SerialIDs" type="text" value={this.state && this.state.articles[id] && this.state.articles[id].serialIds} onChange={(e) => {this.handleChangeArticle(id, "serialIds", e.target.value)}} />  
                    </MDBCol>
                    <MDBCol>
                    <MDBInput label="Price" type="number" step="0.01" min="0" value={this.state && this.state.articles[id] && this.state.articles[id].price} onChange={(e) => {this.handleChangeArticle(id, "price", e.target.value)}} />
                    </MDBCol>
                </MDBRow>
    )
   
    addArticleField = () => {
        let fields = this.state.articleFields;
        fields.push(this.articleField(this.state.articlesCount + 1));
        this.setState(state => ({
            articleFields: fields,
            articlesCount: state.articlesCount + 1
        }))
    }

    addShipping = () => {
        let fields = this.state.articleFields;
        fields.push(this.articleField(this.state.articlesCount + 1));
        this.setState(state => ({
            articleFields: fields,
            articlesCount: state.articlesCount + 1
        }))
    }
    
    execute = async () => {

        const qs = {
            partnerId: "solectric"
        };
        const body = {
            bill: {
                number: this.state.billNumber,
                orderId: this.state.orderId,
                dueDays: this.state.dueDays,
                date: this.state.issueDate,
                amount: this.calcTotalAmount(),
                articles: Object.values(this.state.articles),
                status: "open"
            }
        };
        try {
            await FinancialAPI.createBill(qs, body);
            this.setState({
                articleFields: [],
                 billNumber: "",
                 orderId: "",
                 dueDays: "",
                 issueDate: "",
                 articles: {},
                 articlesCount: 1
            });
            this.props.toggle("createBill");
            setTimeout(this.setState({articleFields: [
                this.articleField(1)
             ]}) , 500)
        } catch (error) {
            console.log(error)
        }
    }

    onEditorStateChange = (e) => {
        console.log(e)
    }

    handleChange = e => {
        const id = e.target.id;
        this.setState({
            [id]: e.target.value
        })
    }

    handleChangeArticle = (id, field, value) => {
        this.setState(state => ({
            articles: {
                ...state.articles,
                [id]: {
                    ...state.articles[id],
                    [field]: value
                }
            }
        }))
    }

    handleDatePicker = value => {
        const d = new Date(value);
        this.setState({issueDate: d.toISOString().substring(0,10)})
      }

    calcTotalAmount = () => {
        let amount = 0;
        Object.keys(this.state.articles).forEach(articleNr => {
            amount += parseFloat(this.state.articles[articleNr].qty * this.state.articles[articleNr].price.replace(",", "."));
        });
        return amount.toFixed(2)
    }


    render= () => {
        
    return (
        <MDBModal isOpen={this.props.isOpen} toggle={() => {console.log("Toggle")}} centered size="xl">
          <MDBModalHeader toggle={() => {this.props.toggle("createBill")}}>Create Bill</MDBModalHeader>
          <MDBModalBody>
          
            <MDBRow className="mt-5">
                <MDBCol size="12" md="11" className="mx-auto">
                <MDBRow>
                    <MDBCol size= "12" md="5">
                        <MDBInput label="Bill Nr" type="text" id="billNumber" value={this.state.billNumber} onChange={this.handleChange} />
                        <MDBInput label="Order ID" type="text" id="orderId" value={this.state.orderId} onChange={this.handleChange} />
                        <MDBInput label="Due Days" type="number" id="dueDays" value={this.state.dueDays} onChange={this.handleChange} />
                        <MDBDatePicker getValue={this.handleDatePicker} disableFuture showTodayButton />
                    </MDBCol>
                    <MDBCol size= "12" md="7">
                        <h4>Articles</h4>
                        {this.state.articleFields.map(a => a)}
                        <MDBRow>
                        <MDBCol className="text-center">
                            <MDBBtn outline onClick={this.addArticleField}>Add Article</MDBBtn>
                        </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color=" rgba-red-strong" onClick={() => {this.props.toggle("createBill")}}>Cancel</MDBBtn>
            <MDBBtn color=" rgba-grey-strong" onClick={() => {this.execute(true)}}>Draft</MDBBtn>
            <MDBBtn color=" rgba-stylish-strong" onClick={this.execute}>Create</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
    )
    }
}



export default CreateBill;