import React, { Fragment, useState, useEffect } from 'react'
import { 
    MDBRow,
    MDBCol,
    MDBCollapse ,
    MDBIcon, MDBBtn, MDBInput, MDBInputGroup, MDBCard, MDBCardTitle, MDBCardBody, MDBCardText, MDBCardImage,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBSelectV5,
    MDBContainer,
    MDBFormInline
 } from "mdbreact";
 import { OauthSender } from 'react-oauth-flow';
 import {API} from 'aws-amplify';

export default function ShippignProvider_DHL(props) {
    // Active requests in process
    const [isLoading, setIsLoading] = useState({updateAuth: false})

    // Manual setup form
    const [settingsForm, updateSettingsForm] = useState({
        senderAddress: {
            companyName: "",
            contactPersonName: "",
            street: "",
            streetNumber: "",
            zip: "",
            city: "",
            countryCode: ""
        },
        contact: {
            phone: "",
            mail: ""
        },
        auth: {
            username: "",
            password: ""
        },
        ekp: "",
        products: {
            paketDE: "01",
            paketDEActive: false,
            paketInternational: "53",
            paketInternationalActive: false,
            paketEU: "55",
            paketEUActive: false,
            paketEUBusiness: "54",
            paketEUBusinessActive: false,
            warenpostDE: "62",
            warenpostDEActive: false,
            warenpostInternational: "66",
            warenpostInternationalActive: false,
            retoureDE: "07",
            retoureDEActive: false,
            retoureInternational: "",
            retoureInternationalActive: false
        }
    })
    const [updateAuthRes, setUpdateAuth] = useState({finished: false, success: null, message:""})

    const [connectionActive, setConnectionActive] = useState(false)
    

    useEffect(() => {
        if(props.isActive) {
            getSettings();
            getLocations()
        }   
    }, [props.isActive])


    const getSettings = async () => {
        try {
            const resDHLSettings = await API.get("ConnectApi", "settings/component", {queryStringParameters: {component: "dhl"}})
            if(resDHLSettings) updateSettingsForm({
                ...resDHLSettings
            })
            if(resShopifySettings.active) setConnectionActive(true)
        } catch (error) {
            console.log(error)
        }
    }
    const [locations, setLocations] = useState([])
    const [selectedLocation, setSelectedLocation] = useState([])


    const getLocations = async () => {
        setIsLoading({...isLoading, locations: true})
        try {
            const resLocations = await API.get("ConnectApi", "settings/component", {queryStringParameters: {component: "locations"}})
            if(resLocations) setLocations(resLocations.locations)
        } catch (error) {
            console.log(error)
        }
        setIsLoading({...isLoading, locations: false})
    }


    const updateAuth = async () => {
        setIsLoading({...isLoading, updateAuth: true})
        try {
            await API.post("ConnectApi", "settings/shippingproviders/dhl/update", {body: {data: {...settingsForm, locationId: selectedLocation.id || settingsForm.locationId}}})
            setUpdateAuth({
                finished: true,
                success: true
            })
            setConnectionActive(true)
        } catch (error) {
            // console.log(error.response)
            setUpdateAuth({
                finished: true,
                success: false,
                message: "Wrong credentials"
            })
        }
        setIsLoading({...isLoading, updateAuth: false})
    }



  return (
    <MDBContainer className="text-center px-md-5 py-md-3">
        <h4 className="mt-5">DHL Settings</h4>
        <h6>Credentials</h6>
        <MDBRow className="px-5">
            <MDBCol><MDBInput label="Username" value={settingsForm.auth.username} onChange={(e) => updateSettingsForm({...settingsForm, auth: {...settingsForm.auth, username: e.target.value}})} /></MDBCol>
            <MDBCol><MDBInput label="Password" value={settingsForm.auth.password} onChange={(e) => updateSettingsForm({...settingsForm, auth: {...settingsForm.auth, password: e.target.value}})} /></MDBCol>
        </MDBRow>
        <h6>Your contact for shippments</h6>
        <MDBRow className="px-5">
            <MDBCol><MDBInput label="E-Mail" value={settingsForm.contact.mail} onChange={(e) => updateSettingsForm({...settingsForm, contact: {...settingsForm.contact, mail: e.target.value}})} /></MDBCol>
            <MDBCol><MDBInput label="Phone" value={settingsForm.contact.phone} onChange={(e) => updateSettingsForm({...settingsForm, contact: {...settingsForm.contact, phone: e.target.value}})} /></MDBCol>
        </MDBRow>
        {/* <h6>Address</h6>
        <MDBRow className="px-5">
            <MDBCol size="6"><MDBInput label="Company Name" value={settingsForm.senderAddress.companyName} onChange={(e) => updateSettingsForm({...settingsForm, senderAddress: {...settingsForm.senderAddress, companyName: e.target.value}})} /></MDBCol>
            <MDBCol size="6"><MDBInput label="Contact Person" value={settingsForm.senderAddress.contactPersonName} onChange={(e) => updateSettingsForm({...settingsForm, senderAddress: {...settingsForm.senderAddress, contactPersonName: e.target.value}})} /></MDBCol>
            <MDBCol size="8"><MDBInput label="Street" value={settingsForm.senderAddress.street} onChange={(e) => updateSettingsForm({...settingsForm, senderAddress: {...settingsForm.senderAddress, street: e.target.value}})} /></MDBCol>
            <MDBCol size="4"><MDBInput label="Street Number" value={settingsForm.senderAddress.streetNumber} onChange={(e) => updateSettingsForm({...settingsForm, senderAddress: {...settingsForm.senderAddress, streetNumber: e.target.value}})} /></MDBCol>
            <MDBCol size="6"><MDBInput label="Zip" value={settingsForm.senderAddress.zip} onChange={(e) => updateSettingsForm({...settingsForm, senderAddress: {...settingsForm.senderAddress, zip: e.target.value}})} /></MDBCol>
            <MDBCol size="6"><MDBInput label="City" value={settingsForm.senderAddress.city} onChange={(e) => updateSettingsForm({...settingsForm, senderAddress: {...settingsForm.senderAddress, city: e.target.value}})} /></MDBCol>
            <MDBCol size="12"><MDBInput label="Country" value={settingsForm.senderAddress.countryCode} onChange={(e) => updateSettingsForm({...settingsForm, senderAddress: {...settingsForm.senderAddress, countryCode: e.target.value}})} /></MDBCol>
        </MDBRow> */}
        <h6>Abrechnungsnummern</h6>
        <MDBRow className="px-5">
            <MDBCol size="12"><MDBInput label="EKP" value={settingsForm.ekp} onChange={(e) => updateSettingsForm({...settingsForm, ekp: e.target.value})} /></MDBCol>
            <MDBCol size="12">
                <MDBRow>
                <span className="font-weight-bold">Active Products:</span><br></br>
                <MDBFormInline>
            
            <MDBInput label="DE Paket" type="checkbox" checked={settingsForm.products.paketDEActive} id="paketDEActive" onChange={(e) => updateSettingsForm({...settingsForm, products: {...settingsForm.products, paketDEActive: e.target.checked}})} containerClass='mx-2' />
            <MDBInput label="EU Paket" type="checkbox" checked={settingsForm.products.paketEUActive} id="paketEUActive" onChange={(e) => updateSettingsForm({...settingsForm, products: {...settingsForm.products, paketEUActive: e.target.checked}})} containerClass='mx-2' />
            <MDBInput label="EU Paket Business" type="checkbox" checked={settingsForm.products.paketEUBusinessActive} id="paketEUBusinessActive" onChange={(e) => updateSettingsForm({...settingsForm, products: {...settingsForm.products, paketEUBusinessActive: e.target.checked}})} containerClass='mx-2' />
            <MDBInput label="Paket International" type="checkbox" checked={settingsForm.products.paketInternationalActive} id="paketInternationalActive" onChange={(e) => updateSettingsForm({...settingsForm, products: {...settingsForm.products, paketInternationalActive: e.target.checked}})} containerClass='mx-2' />
            <MDBInput label="Warenpost" type="checkbox" checked={settingsForm.products.warenpostDEActive} id="warenpostDEActive" onChange={(e) => updateSettingsForm({...settingsForm, products: {...settingsForm.products, warenpostDEActive: e.target.checked}})} containerClass='mx-2' />
            <MDBInput label="Warenpost International" type="checkbox" checked={settingsForm.products.warenpostInternationalActive} id="warenpostInternationalActive" onChange={(e) => updateSettingsForm({...settingsForm, products: {...settingsForm.products, warenpostInternationalActive: e.target.checked}})} containerClass='mx-2' />
            <MDBInput label="Retoure" type="checkbox" checked={settingsForm.products.retoureDEActive} id="retoureDEActive" onChange={(e) => updateSettingsForm({...settingsForm, products: {...settingsForm.products, retoureDEActive: e.target.checked}})} containerClass='mx-2' />
            <MDBInput label="Retoure International" type="checkbox" checked={settingsForm.products.retoureInternationalActive} id="retoureInternationalActive" onChange={(e) => updateSettingsForm({...settingsForm, products: {...settingsForm.products, retoureInternationalActive: e.target.checked}})} containerClass='mx-2' />
            </MDBFormInline>
                </MDBRow>
            </MDBCol>
            {settingsForm.products.paketDEActive && <MDBCol size="3"><MDBInput label="DE" value={settingsForm.products.paketDE} onChange={(e) => updateSettingsForm({...settingsForm, products: {...settingsForm.products, paketDE: e.target.value}})} /></MDBCol>}
            {settingsForm.products.paketEUActive && <MDBCol size="3"><MDBInput label="EU" value={settingsForm.products.paketEU} onChange={(e) => updateSettingsForm({...settingsForm, products: {...settingsForm.products, paketEU: e.target.value}})} /></MDBCol>}
            {settingsForm.products.paketEUBusinessActive && <MDBCol size="3"><MDBInput label="EU Business" value={settingsForm.products.paketEUBusiness} onChange={(e) => updateSettingsForm({...settingsForm, products: {...settingsForm.products, paketEUBusiness: e.target.value}})} /></MDBCol>}
            {settingsForm.products.paketInternationalActive && <MDBCol size="3"><MDBInput label="International" value={settingsForm.products.paketInternational} onChange={(e) => updateSettingsForm({...settingsForm, products: {...settingsForm.products, paketInternational: e.target.value}})} /></MDBCol>}
            {settingsForm.products.warenpostDEActive && <MDBCol size="3"><MDBInput label="Warenpost" value={settingsForm.products.warenpostDE} onChange={(e) => updateSettingsForm({...settingsForm, products: {...settingsForm.products, warenpostDE: e.target.value}})} /></MDBCol>}
            {settingsForm.products.warenpostInternationalActive && <MDBCol size="3"><MDBInput label="Warenpost International" value={settingsForm.products.warenpostInternational} onChange={(e) => updateSettingsForm({...settingsForm, products: {...settingsForm.products, warenpostInternational: e.target.value}})} /></MDBCol>}
            {settingsForm.products.retoureDEActive && <MDBCol size="3"><MDBInput label="Retoure" value={settingsForm.products.retoureDE} onChange={(e) => updateSettingsForm({...settingsForm, products: {...settingsForm.products, retoureDE: e.target.value}})} /></MDBCol>}
            {settingsForm.products.retoureInternationalActive && <MDBCol size="3"><MDBInput label="Retoure International" value={settingsForm.products.retoureInternational} onChange={(e) => updateSettingsForm({...settingsForm, products: {...settingsForm.products, retoureInternational: e.target.value}})} /></MDBCol>}
        </MDBRow>
        <MDBCol size="12" md="7" className="mx-auto text-center mt-3 mt-md-4">
        <MDBSelectV5
                style={{width: "100%"}}
                getValue={(value) => {
                    if(value.length > 0) setSelectedLocation(locations.find(loc => loc.id === parseInt(value[0])))
                }}
                options={[{
                    value:"default",
                    text: "Select sender location"
                },
                ...locations.map(loc => ({value: loc.id, text: loc.name}))]}
                
                selected={settingsForm.locationId && locations.length > 0 ? locations.find(loc => loc.id === settingsForm.locationId).name : selectedLocation?.name ? selectedLocation.name : "Select sender location"}
            />
        </MDBCol>
        <MDBCol size="12" className="text-center mt-3 mt-md-4">
        {isLoading.updateAuth ? 
            <div className="spinner-grow text-default" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        :
        <MDBBtn size="sm" onClick={() => updateAuth()}>Save</MDBBtn>
        }
        {updateAuthRes.finished && updateAuthRes.success ? <span className="text-success"><MDBIcon icon="check" /></span> : <span className="text-danger">{updateAuthRes.message}</span> }
        </MDBCol>
    </MDBContainer>
  )
}
