import React, { Fragment, useState, useEffect } from 'react'
import { 
    MDBRow,
    MDBCol,
    MDBCollapse ,
    MDBIcon, MDBBtn, MDBInput, MDBInputGroup, MDBCard, MDBCardTitle, MDBCardBody, MDBCardText, MDBCardImage,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBSelectV5,
    MDBContainer
 } from "mdbreact";
 import {API} from 'aws-amplify';

export default function Marketplace_Shopware6(props) {
    // Active requests in process
    const [isLoading, setIsLoading] = useState({connectStore: false})


    // Existing connections
    const [stores, setStores] = useState({})
    // For creating new connection
    const [storeName, setStoreName] = useState("")

    // Setup form
    const [form, updateForm] = useState({})
    const [storeRes, setStoreRes] = useState({finished: false, success: null, message:""})

    // Configure a connected store
    const [selectedStore, setSelectedStore] = useState({})
    const [isModalOpen, setModalOpen] = useState(false)
    

    useEffect(() => {
        if(props.isActive) getStores()
    }, [props.isActive])

    const getStores = async () => {
        try {
            const resSettings = await API.get("ConnectApi", "settings/component", {queryStringParameters: {component: "shopware"}})
            setStores(resSettings.stores)
        } catch (error) {
            console.log(error)
        }
    }

    const connectStore = async () => {
        console.log("Connecting store")
        setIsLoading({...isLoading, connectStore: true})
        try {
            const connectRes = await API.post("ConnectApi", "settings/shopware6/connectstore", {body: {
                credentials: {
                    storeName: form.storeUrl.replaceAll(".", "_"),
                    storeUrl: form.storeUrl,
                    username: form.username,
                    password: form.password
                }
            }})
            console.log(connectRes)
            setStoreRes({
                finished: true,
                success: true
            })
            updateForm({storeName: "", storeUrl: "", username: "", password: ""})
            getStores()
        } catch (error) {
            // console.log(error.response)
            setStoreRes({
                finished: true,
                success: false,
                message: "Cannot connect store"
            })
        }
        setIsLoading({...isLoading, connectStore: false})
    }
   

    const updateSyncInterval = async (type, value, storeName) => {
        console.log(value)
        if(isModalOpen && value.toString() !== selectedStore.syncintervals[type].toString()) {
            console.log("updating sync interval for " + type + " to: " + value)
            try {
                await API.post("ConnectApi", "settings/shopify/update", {queryStringParameters: {storeName: storeName}, body: {interval: {type, value}}})
                setStores({
                    ...stores,
                    [storeName]: {
                        ...stores[storeName],
                        syncintervals: {
                            ...stores[storeName].syncintervals,
                            [type]: parseInt(value)
                        }
                    }
                })
                setSelectedStore({
                    ...selectedStore,
                    syncintervals: {
                        ...selectedStore.syncintervals,
                        [type]: parseInt(value)
                    }
                })
            } catch (error) {
                console.log(error)
            }
        }
    }
    

  return (
    <Fragment>
        <MDBContainer className="my-2 my-md-4">
            {Object.keys(stores).length > 0 && <>
                <h4>Connected stores</h4>
                <MDBRow className="mt-4">
                {Object.keys(stores).map(storeHandle => <MDBCol key={storeHandle} size="12" md="6">
                    <MDBCard
                    onClick={() => {
                        setSelectedStore({handle: storeHandle, ...stores[storeHandle]});
                        setModalOpen(true)
                    }}
                    >
                        <MDBCardBody>
                            <MDBCardTitle>{storeHandle}</MDBCardTitle>
                            <MDBCardText>
                                Connected: {new Date(stores[storeHandle].connectedTime).toLocaleDateString()}<br></br>
                                Connection-Type: {stores[storeHandle].type}
                            </MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>)}
                </MDBRow>
            </>}
            <h4 className="mt-5">Connect new store</h4>
                <MDBRow>
                    <MDBCol size="0" md="2"></MDBCol>
                    <MDBCol size="12" md="8">
                        <MDBInputGroup
                            material
                            hint="Store URL"
                            containerClassName="mb-0 mt-1"
                            prepend="https://"
                            value={form.storeUrl}
                            onChange={(e) => updateForm(prevForm => ({...prevForm, storeUrl: e.target.value}))}
                        />
                        <MDBInput label="Username" value={form.username} onChange={(e) => updateForm(prevForm => ({...prevForm, username: e.target.value}))} />
                        <MDBInput label="Password" value={form.password} onChange={(e) => updateForm(prevForm => ({...prevForm, password: e.target.value}))} />
                    </MDBCol>
                    <MDBCol size="0" md="2"></MDBCol>
                    <MDBCol size="12" className="text-center mt-3 mt-md-4">
                    {isLoading.connectStore ? 
                        <div className="spinner-grow text-default" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    :
                    <MDBBtn size="sm" disabled={!(form.storeUrl?.length > 4 && form.username?.length > 1 && form.password?.length > 3)} onClick={() => connectStore()}>Connect</MDBBtn>
                    }
                    {storeRes.finished && !storeRes.success && <span className="text-danger">{storeRes.message}</span> }
                    </MDBCol>
                </MDBRow>
        </MDBContainer>
                  
        <MDBModal
    isOpen={isModalOpen}
    toggle={() => {
      setModalOpen(false);
    }}
    centered
    size="lg">

    <MDBModalBody>
    <MDBCard style={{margin: "-1rem"}}>
    <MDBCardImage
        className='teal white-text d-flex justify-content-center align-items-center flex-column p-2 mb-4'
        tag='div'
      >Configure store {selectedStore.handle}
      </MDBCardImage>
    {selectedStore?.handle && 
        <>
        <h6 className="ml-3 ml-md-4 font-weight-bold">Sync intervals</h6>
        <MDBRow>
            <MDBCol size="6" className="text-center">
                <p className="mt-md-4">Orders</p>
            </MDBCol>
            <MDBCol size="6" className="px-1 px-md-5">
            <MDBSelectV5
                style={{width: "100%"}}
                getValue={(value) => {
                    if(value.length > 0) updateSyncInterval("orders", value[0], selectedStore.handle)
                }}
                options={[
                {
                    value: "0",
                    text: "Set order sync interval",
                    disabled: true
                },
                {
                    value: "10",
                    text: "Every 10 Minutes"
                },
                {
                    value: "20",
                    text: "Every 20 Minutes"
                },
                {
                    value: "30",
                    text: "Every 30 Minutes"
                },
                {
                    value: "60",
                    text: "Every 60 Minutes"
                }
                
                ]}
                selected={selectedStore.syncintervals?.orders ? "Every "+ selectedStore.syncintervals.orders +" Minutes" : "Set order sync interval"}
            />
            </MDBCol>
            <MDBCol size="6" className="text-center">
                <p className="mt-md-4">Stock</p>
            </MDBCol>
            <MDBCol size="6" className="px-1 px-md-5">
            <MDBSelectV5
                style={{width: "100%"}}
                getValue={(value) => {
                    if(value.length > 0) updateSyncInterval("stock", value[0], selectedStore.handle)
                }}
                options={[
                {
                    value: "0",
                    text: "Set stock sync interval",
                    disabled: true
                },
                {
                    value: "10",
                    text: "Every 10 Minutes"
                },
                {
                    value: "20",
                    text: "Every 20 Minutes"
                },
                {
                    value: "30",
                    text: "Every 30 Minutes"
                },
                {
                    value: "60",
                    text: "Every 60 Minutes"
                }
                
                ]}
                selected={selectedStore.syncintervals?.orders ? "Every "+ selectedStore.syncintervals.stock +" Minutes" : "Set stock sync interval"}
            />
            </MDBCol>
        </MDBRow>
        </>
        
    }
    
      
    </MDBCard>
    </MDBModalBody>
    <MDBModalFooter>
      <MDBBtn
        outline color="danger"
        onClick={() => { 
            setModalOpen(false);
      }}>Close</MDBBtn>
    </MDBModalFooter>
  </MDBModal>
    </Fragment>
  )
}
