import { Component } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBFileInput
} from 'mdbreact';
import {ProductsAPI} from "../../Api/connect";

export default class ImportTracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: ""
    }
  }

  setFile = (files) => {
    const setStatefile = (file) => {
      this.setState({file})
    }
    console.log(files)
    var reader = new FileReader();
    reader.onload = (function (theFile) {
      return function (e) {
        var binaryData = e.target.result;
        var base64String = window.btoa(binaryData);
        setStatefile(base64String)
      };
    })(files[0]);
    reader.readAsBinaryString(files[0])
  }

  execute = async() => {
    const body = {
      csv: this.state.file
    };
    try {
      await ProductsAPI.importProducts(null, body);
      this
        .props
        .toggle("importProducts");
    } catch (error) {
      console.log(error)
    }
  }

  render = () => {

    return (
      <MDBModal
        isOpen={this.props.isOpen}
        toggle={() => {
        this
          .props
          .toggle("importProducts")
      }}
        centered
        size="xl">
        <MDBModalHeader
          toggle={() => {
          this
            .props
            .toggle("importProducts")
        }}>Import Products</MDBModalHeader>
        <MDBModalBody>
          <MDBRow className="mt-5">
            <MDBCol size="12" md="6" className="mx-auto">
              <h4>Tutorial:</h4>
              <ol>
                <li>Download this template: <a href="https://cdn.eu.com/ch/frontend_support/templates/ChannelHive_Product_Import_Template.csv">products.csv</a>.</li>
                <li>Fill the template file with your product data.</li>
                <li>Delete first row describing the fields.</li>
                <li>Save and upload your file.</li>
              </ol>
              <MDBFileInput
                btnColor=" teal white-text"
                textFieldTitle={"Select products csv file"}
                getValue={this.setFile}/>
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            color=" rgba-red-strong"
            onClick={() => {
            this
              .props
              .toggle("importProducts")
          }}>Cancel</MDBBtn>
          <MDBBtn color=" rgba-stylish-strong" onClick={this.execute}>Import</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }
}