import {Component} from "react";
import {
    MDBInput,
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBSelect,
    MDBRow,
    MDBCol,
    MDBBtn
} from "mdbreact";
import categories from "./categories.json";

const options = [
    {
        text: "=< 0,1 KG 20x15x1 cm",
        amazon: 1.63,
        shop: 3.45
    }, {
        text: "=< 0,1 KG 33x23x2,5 cm",
        amazon: 1.79,
        shop: 3.60
    }, {
        text: "=< 0,25 KG 33x23x2,5 cm",
        amazon: 1.92,
        shop: 3.70
    }, {
        text: "=< 0,5 KG 33x23x2,5 cm",
        amazon: 2.04,
        shop: 3.80
    }, {
        text: "=< 1 KG 33x23x5 cm",
        amazon: 2.4,
        shop: 4.05
    }
]

class ProductEarnings extends Component {
    state = {
        name: "",
        purchasingPrice: 2.99,
        sellingPriceAmazon: 19.9,
        sellingPriceWebShop: 19.9,
        packageSize: "",
        category: "",
        percentFee: 15,
        isPaypal: false,
        paypalFees: {
            rate: 1.79,
            fix: 0.35
        },
        klarnaFees: {
            rate: 2.99,
            fix: 0.35
        },
        packingMaterial: 0.4,
        percentageTax: 19,
        result: {},
        showResult: false,
        channelHiveProvisionA: 5,
        channelHiveProvisionWS: 7 
    }

    calculate = () => {
        const amazon = {
            marketplacefees: parseFloat((this.state.sellingPriceAmazon * this.state.percentFee * 0.01).toFixed(2)),
            tax: parseFloat((this.state.sellingPriceAmazon - (this.state.sellingPriceAmazon / (1 + (this.state.percentageTax * 0.01)))).toFixed(2)),
            channelHiveProvision: parseFloat((this.state.sellingPriceAmazon * this.state.channelHiveProvisionA * 0.01).toFixed(2)),
            shippingFees: this.state.packageSize
                ? options.filter(o => o.text === this.state.packageSize)[0].amazon
                : 0
        }
        const amazonEarnings = parseFloat((this.state.sellingPriceAmazon - this.state.purchasingPrice - amazon.marketplacefees - amazon.tax - amazon.channelHiveProvision - amazon.shippingFees).toFixed(2));
        const amazonEarningsPercent = parseFloat((amazonEarnings / (this.state.sellingPriceAmazon / 100)).toFixed(2));
        const shop = {
            paypalFees: parseFloat(((this.state.sellingPriceWebShop * this.state.paypalFees.rate * 0.01) + this.state.paypalFees.fix).toFixed(2)),
            klarnaFees: parseFloat(((this.state.sellingPriceWebShop * this.state.klarnaFees.rate * 0.01) + this.state.klarnaFees.fix).toFixed(2)),
            tax: parseFloat((this.state.sellingPriceWebShop - (this.state.sellingPriceWebShop / (1 + (this.state.percentageTax * 0.01)))).toFixed(2)),
            channelHiveProvision: parseFloat((this.state.sellingPriceWebShop * this.state.channelHiveProvisionWS * 0.01).toFixed(2)),
            shippingFees: 0
        }
        const shopEarningsPaypal = parseFloat((this.state.sellingPriceWebShop - this.state.purchasingPrice - this.state.packingMaterial - shop.paypalFees - shop.tax - shop.channelHiveProvision - shop.shippingFees).toFixed(2));
        const shopEarningsKlarna = parseFloat((this.state.sellingPriceWebShop - this.state.purchasingPrice - this.state.packingMaterial - shop.klarnaFees - shop.tax - shop.channelHiveProvision - shop.shippingFees).toFixed(2));
        const shopEarningsPercentPaypal = parseFloat((shopEarningsPaypal / (this.state.sellingPriceWebShop / 100)).toFixed(2));
        const shopEarningsPercentKlarna = parseFloat((shopEarningsKlarna / (this.state.sellingPriceWebShop / 100)).toFixed(2));
        const result = {
            purchasingPrice: this.state.purchasingPrice,
            sellingPriceAmazon: this.state.sellingPriceAmazon,
            sellingPriceWebShop: this.state.sellingPriceWebShop,
            packingMaterial: this.state.packingMaterial,
            amazon: {
                ...amazon,
                earnings: amazonEarnings,
                earningsPercent: amazonEarningsPercent
            },
            shop: {
                ...shop,
                earnings: [
                    shopEarningsPaypal, shopEarningsKlarna
                ],
                earningsPercent: [shopEarningsPercentPaypal, shopEarningsPercentKlarna]
            }
        };
        console.log(result)
        this.setState({showResult: true, result})
    }

    changeSelectPackage = e => e => {
        this.setState({packageSize: e})
    }

    render() {

        return ( <> 
            <MDBCard>
            <MDBCardHeader color="stylish-color">Introduction</MDBCardHeader>
            <MDBCardBody>
            Do you want to know how much money your product will make? This profit calculator makes the guesswork for you. Enter in all of the relevant information about your product, and our tool will do the rest!
            <br></br>  
            </MDBCardBody>
            </MDBCard>

            <MDBCard className='mt-5'>
                <MDBCardHeader color="stylish-color">
                    Product data
                </MDBCardHeader>

                <MDBCardBody>
                    <MDBRow>
                        <MDBCol><MDBInput
                            label="Einkaufspreis"
                            type="number"
                            step="0.01"
                            value={this.state.purchasingPrice}
                            onChange={(e) => this.setState({
                purchasingPrice: parseFloat(e.target.value)
            })}/></MDBCol>
                        <MDBCol><MDBInput
                            label="Tax"
                            type="number"
                            value={this.state.percentageTax}
                            onChange={(e) => this.setState({
                percentageTax: parseInt(e.target.value)
            })}/></MDBCol>
                        <MDBCol><MDBInput
                            label="Verkaufspreis Amazon"
                            type="number"
                            step="0.01"
                            value={this.state.sellingPriceAmazon}
                            onChange={(e) => this.setState({
                sellingPriceAmazon: parseFloat(e.target.value)
            })}/></MDBCol>
                        <MDBCol>
                            <MDBInput
                                label="ChannelHive Provision Amazon"
                                type="number"
                                value={this.state.channelHiveProvisionA}
                                onChange={(e) => this.setState({
                                channelHiveProvisionA: parseFloat(e.target.value)
                            })}/></MDBCol>
                        <MDBCol>
                            <MDBInput
                                label="Verkaufspreis Shop"
                                type="number"
                                step="0.01"
                                value={this.state.sellingPriceWebShop}
                                onChange={(e) => this.setState({
                                sellingPriceWebShop: parseFloat(e.target.value)
                            })}/></MDBCol>
                        <MDBCol>
                            <MDBInput
                                label="ChannelHive Provision Web Shop"
                                type="number"
                                value={this.state.channelHiveProvisionWS}
                                onChange={(e) => this.setState({
                                channelHiveProvisionWS: parseFloat(e.target.value)
                            })}/></MDBCol>
                    </MDBRow>
                    {/* <MDBSelect
                options={options}
                selected="Paketgröße auswählen"
                label="Paketgröße"
                />
                <MDBSelect
                options={categories.categories}
                selected="Kategorie"
                label="Kategorie"
                /> */}
                    <MDBRow className="text-left">
                        <MDBCol>
                            <MDBInput
                                type="number"
                                label="% Amazon Fees"
                                icon="percentage"
                                id="percentFee"
                                value={this.state.percentFee}
                                onChange={(e) => this.setState({
                                percentFee: parseFloat(e.target.value)
                            })}/>
                        </MDBCol>
                        <MDBCol>
                            <select
                                className="browser-default custom-select"
                                onChange={e => this.setState({packageSize: e.target.value})}>
                                <option>Package Size</option>
                                {options.map(option => <option>{option.text}</option>)}
                            </select>
                        </MDBCol>
                        <MDBCol>
                            <MDBInput
                                type="number"
                                label="Verpackungsmaterial"
                                id="packingMaterial"
                                value={this.state.packingMaterial}
                                onChange={(e) => this.setState({
                                packingMaterial: parseFloat(e.target.value)
                            })}/>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="text-center">
                        <MDBCol>
                            <MDBBtn onClick={this.calculate}>Calculate</MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
            {
            this.state.showResult && <MDBCard className="mt-5">
                    <MDBCardHeader color="stylish-color">Result</MDBCardHeader>
                    <MDBCardBody>
                        <table className="mx-auto text-center">
                            <tr>
                                <th
                                    style={{
                                    width: "200px"
                                }}></th>
                                <th
                                    style={{
                                    width: "200px"
                                }}>Amazon</th>
                                <th
                                    style={{
                                    width: "200px"
                                }}>Shop Paypal</th>

                                <th
                                    style={{
                                    width: "200px"
                                }}>Shop Klarna</th>

                            </tr>
                            <tr>
                                <td>Verkaufspreis</td>
                                <td>{this
                                        .state
                                        .result
                                        .sellingPriceAmazon
                                        .toFixed(2)}
                                    €</td>
                                <td>{this
                                        .state
                                        .result
                                        .sellingPriceWebShop
                                        .toFixed(2)}
                                    €</td>
                                <td >{this
                                        .state
                                        .result
                                        .sellingPriceWebShop
                                        .toFixed(2)}
                                    €</td>
                            </tr>
                            <tr>
                                <td>Steuer</td>
                                <td>{this
                                        .state
                                        .result
                                        .amazon
                                        .tax
                                        .toFixed(2)}
                                    €</td>
                                <td>{this
                                        .state
                                        .result
                                        .shop
                                        .tax
                                        .toFixed(2)}
                                    €</td>
                                <td>{this
                                        .state
                                        .result
                                        .shop
                                        .tax
                                        .toFixed(2)}
                                    €</td>
                            </tr>
                            <tr>
                                <td>Einkaufspreis</td>
                                <td>{this
                                        .state
                                        .result
                                        .purchasingPrice
                                        .toFixed(2)}
                                    €</td>
                                <td>{this
                                        .state
                                        .result
                                        .purchasingPrice
                                        .toFixed(2)}
                                    €</td>
                                <td>{this
                                        .state
                                        .result
                                        .purchasingPrice
                                        .toFixed(2)}
                                    €</td>
                            </tr>
                            <tr>
                                <td>ChannelHive Provision</td>
                                <td>{this
                                        .state
                                        .result
                                        .amazon
                                        .channelHiveProvision
                                        .toFixed(2)}
                                    €</td>
                                <td>{this
                                        .state
                                        .result
                                        .shop
                                        .channelHiveProvision
                                        .toFixed(2)}
                                    €</td>
                                <td>{this
                                        .state
                                        .result
                                        .shop
                                        .channelHiveProvision
                                        .toFixed(2)}
                                    €</td>
                            </tr>
                            <tr>
                                <td>Marktplatzgebühr</td>
                                <td>{this
                                        .state
                                        .result
                                        .amazon
                                        .marketplacefees
                                        .toFixed(2)}
                                    €</td>
                                <td>0.00 €</td>
                                <td>0.00 €</td>
                            </tr>
                            <tr>
                                <td>Zahlungsanbieter Gebühr</td>
                                <td>0.00 €</td>
                                <td>{this
                                        .state
                                        .result
                                        .shop
                                        .paypalFees
                                        .toFixed(2)}
                                    €</td>
                                <td>{this
                                        .state
                                        .result
                                        .shop
                                        .klarnaFees
                                        .toFixed(2)}
                                    €</td>

                            </tr>
                            <tr>
                                <td>Verpackungsmaterial</td>
                                <td>
                                    0.00€</td>
                                <td>{this
                                        .state
                                        .result
                                        .packingMaterial
                                        .toFixed(2)}
                                    €</td>
                                <td>{this
                                        .state
                                        .result
                                        .packingMaterial
                                        .toFixed(2)}
                                    €</td>
                            </tr>
                            <tr>
                                <td>Versand</td>
                                <td>{this
                                        .state
                                        .result
                                        .amazon
                                        .shippingFees
                                        .toFixed(2)}
                                    €</td>
                                <td>{this
                                        .state
                                        .result
                                        .shop
                                        .shippingFees
                                        .toFixed(2)}
                                    €</td>
                                <td>{this
                                        .state
                                        .result
                                        .shop
                                        .shippingFees
                                        .toFixed(2)}
                                    €</td>
                            </tr>
                            <tr>
                                <td>Gewinn</td>
                                <td>{this
                                        .state
                                        .result
                                        .amazon
                                        .earnings
                                        .toFixed(2)}
                                    €</td>
                                <td>{this
                                        .state
                                        .result
                                        .shop
                                        .earnings[0]
                                        .toFixed(2)}
                                    €</td>
                                <td>{this
                                        .state
                                        .result
                                        .shop
                                        .earnings[1]
                                        .toFixed(2)}
                                    €</td>

                            </tr>
                            <tr>
                                <td>Gewinn (%)</td>
                                <td>{this
                                        .state
                                        .result
                                        .amazon
                                        .earningsPercent
                                        .toFixed(2)}
                                    %</td>
                                <td>{this
                                        .state
                                        .result
                                        .shop
                                        .earningsPercent[0]
                                        .toFixed(2)}
                                    %</td>
                                <td>{this
                                        .state
                                        .result
                                        .shop
                                        .earningsPercent[1]
                                        .toFixed(2)}
                                    %</td>
                            </tr>
                        </table>
                    </MDBCardBody>
                </MDBCard>
        } </>
    );       
}
};

export default ProductEarnings;