import { Component } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBFileInput
} from 'mdbreact';
import {OrdersAPI} from "../../Api/connect";

export default class ImportTracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTemplate: "",
      file: ""
    }
  }

  renderSelect = () => {
    return (
      <select className="browser-default custom-select" // value=""
  style={{
        backgroundColor: "transparent",
        border: "none",
        borderBottom: "1px solid #ced4da",
        borderRadius: "0"
      }} onChange={this.changeTemplate}>
        <option>Choose Template</option>
        <option value="dhl">DHL</option>
        <option value="ups">UPS</option>
        <option value="fba">FBA</option>
      </select>
    )
  }

  setFile = (files) => {
    const setStatefile = (file) => {
      this.setState({file})
    }
    console.log(files)
    var reader = new FileReader();
    reader.onload = (function (theFile) {
      return function (e) {
        var binaryData = e.target.result;
        var base64String = window.btoa(binaryData);
        setStatefile(base64String)
      };
    })(files[0]);
    reader.readAsBinaryString(files[0])
  }

  execute = async() => {
    const body = {
      csv: this.state.file,
      templateName: this.state.selectedTemplate
    };
    try {
      await OrdersAPI.ImportTracking(null, body);
      this
        .props
        .toggle("importTracking");
    } catch (error) {
      console.log(error)
    }
  }

  changeTemplate = e => {
    this.setState({selectedTemplate: e.target.value})
  }

  render = () => {

    return (
      <MDBModal
        isOpen={this.props.isOpen}
        toggle={() => {
        this
          .props
          .toggle("importTracking")
      }}
        centered
        size="xl">
        <MDBModalHeader
          toggle={() => {
          this
            .props
            .toggle("importTracking")
        }}>Import Tracking</MDBModalHeader>
        <MDBModalBody>
          <MDBRow className="mt-5">
            <MDBCol size="12" md="6" className="mx-auto">
              <MDBFileInput
                btnColor=" teal white-text"
                textFieldTitle={"Select tracking export file"}
                getValue={this.setFile}/>
            </MDBCol>
          </MDBRow>
          <MDBRow className="my-5">
            <MDBCol size="12" md="6" className="mx-auto">
              {this.renderSelect()}
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            color=" rgba-red-strong"
            onClick={() => {
            this
              .props
              .toggle("importTracking")
          }}>Cancel</MDBBtn>
          <MDBBtn color=" rgba-stylish-strong" onClick={this.execute}>Import</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }
}