import { Component } from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, Table, TableHead, TableBody, MDBRow, MDBCol } from 'mdbreact';
import {OrdersAPI} from "../../Api/connect";
import images from "../images";


class instrShipping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTemplate: ""
        }
    }
   
    renderSelect = () => {
        return (
            <select
            className="browser-default custom-select"
            // value=""
            style={{backgroundColor: "transparent", border: "none", borderBottom: "1px solid #ced4da", borderRadius: "0"}}
            onChange={this.changeTemplate}>
            <option>Choose Template</option>
            <option value="orders-dhl">DHL</option>
            <option value="dhl">UPS</option>
            <option value="dhl">FBA</option>
             </select>
        )
    }
    renderOrders = (orders) => {
        if (!orders) {
            return
        }
        return orders.map((order) => ({
                "orderNumber": <span>{order.id}<br /><span className="grey-text">{order.storeOrderId}</span></span>,
                "store": images[order.store],
                "address": <span>{order.addresses.shipping.fullName || order.customerData.firstName + " " + order.customerData.lastName}<br />{order.addresses.shipping.street}<br />{order.addresses.shipping.zip + " " + order.addresses.shipping.city}<br />{order.addresses.shipping.country}</span>,
                "articles": <span>{order.articles.names.map((name, index) => <span>{name}<br /><span className="grey-text">{order.articles.skus[index]}</span></span>)}</span>
        }))
    };

    execute = async () => {
        const body = {
            orderIds: this.props.orders.map(o => o.id),
            templateName: this.state.selectedTemplate
        };
        try {
            const URL = await OrdersAPI.exportOrders(null, body);
            window.open(URL, "_blank")
            this.props.toggle("export");
        } catch (error) {
            console.log(error)
        }
    }

    changeTemplate = e => {
        this.setState({selectedTemplate: e.target.value})
    }

    render= () => {
        const data_checkboxes = {
            columns: [
              {
                'label': 'Order number',
                'field': 'orderNumber',
                'sort': 'asc'
              },
              {
                'label': 'Store',
                'field': 'store',
                'sort': 'asc'
              },
              {
                'label': 'Address',
                'field': 'address',
                'sort': 'asc'
              },
              {
                'label': 'Articles',
                'field': 'articles',
                'sort': 'asc'
              },
             
            ]
          };
    return (
        <MDBModal isOpen={this.props.isOpen} toggle={() => {this.props.toggle("export")}} centered size="xl">
          <MDBModalHeader toggle={() => {this.props.toggle("export")}}>Export</MDBModalHeader>
          <MDBModalBody>
          <Table responsive className="text-center">
                 <TableHead  color="teal" textWhite columns={data_checkboxes.columns} />
                 <TableBody rows={this.renderOrders(this.props.orders)} />
            </Table>
            <MDBRow className="mt-5">
                <MDBCol size="12" md="6" className="mx-auto">
                {this.renderSelect()}
                </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color=" rgba-red-strong" onClick={() => {this.props.toggle("export")}}>Cancel</MDBBtn>
            <MDBBtn color=" rgba-stylish-strong" onClick={this.execute}>Export</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
    )
    }
}



export default instrShipping;