import React, { Fragment, useState, useEffect } from 'react'
import { 
    MDBRow,
    MDBCol,
    MDBCollapse ,
    MDBIcon, MDBBtn, MDBInput, MDBInputGroup, MDBCard, MDBCardTitle, MDBCardBody, MDBCardText, MDBCardImage,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBSelectV5,
    MDBContainer
 } from "mdbreact";
 import { OauthSender } from 'react-oauth-flow';
 import {API} from 'aws-amplify';

export default function Marketplace_Shopify(props) {
    // Active requests in process
    const [isLoading, setIsLoading] = useState({updateAuth: false, stockSync: false, trackingSync: false})

    // Manual setup form
    const [settingsForm, updateSettingsForm] = useState({})
    const [updateAuthRes, setUpdateAuth] = useState({finished: false, success: null, message:""})

    const [stockSyncActive, setStockSyncActive] = useState(false)
    const [trackingSyncActive, setTrackingSyncActive] = useState(false)
    const [trackingNotifyMarketplace, setTrackingNotifyMarketplace] = useState(false)

    const [connectionActive, setConnectionActive] = useState(false)

    

    useEffect(() => {
        getSettings()
    }, [props.isActive])

    const getSettings = async () => {
        try {
            const resShopifySettings = await API.get("ConnectApi", "settings/component", {queryStringParameters: {component: "bps"}})
            if(resShopifySettings) updateSettingsForm({
                username: resShopifySettings.credentials.username,
                password: resShopifySettings.credentials.password,
                accountId: resShopifySettings.accountId,
                apiKey: resShopifySettings.apiKey,
            })
            if(resShopifySettings.active) setConnectionActive(true)
            setStockSyncActive(resShopifySettings.stockSync)
            setTrackingSyncActive(resShopifySettings.syncTracking)
            setTrackingNotifyMarketplace(resShopifySettings.syncTrackingNotifyMarketplace)
        } catch (error) {
            console.log(error)
        }
    }


    const updateAuth = async () => {
        setIsLoading({...isLoading, updateAuth: true})
        try {
            await API.post("ConnectApi", "/settings/fulfillers/bps/updateauth", {body: {
                auth: {
                    credentials: {
                        username: settingsForm.username,
                        password: settingsForm.password,
                    },
                    accountId: settingsForm.accountId,
                    apiKey: settingsForm.apiKey,
                }
            }})
            setUpdateAuth({
                finished: true,
                success: true
            })
            setConnectionActive(true)
        } catch (error) {
            // console.log(error.response)
            setUpdateAuth({
                finished: true,
                success: false,
                message: "Wrong credentials or api key"
            })
        }
        setIsLoading({...isLoading, updateAuth: false})
    }


    const updateStockSync = async (active) => {
        setIsLoading({...isLoading, stockSync: true})
        try {
            await API.post("ConnectApi", "/settings/fulfillers/bps/syncstock", {body: {active}})
            setStockSyncActive(active)
        } catch (error) {
            console.log(error)
        }
        setIsLoading({...isLoading, stockSync: false})
    }

    const updateTrackingSync = async (active) => {
        setIsLoading({...isLoading, trackingSync: true})
        try {
            await API.post("ConnectApi", "/settings/fulfillers/bps/synctracking", {body: {active, notifyMarketplace: trackingNotifyMarketplace}})
            setTrackingSyncActive(active)
        } catch (error) {
            console.log(error)
        }
        setIsLoading({...isLoading, trackingSync: false})
    }


  return (
    <MDBContainer className="my-2 my-md-4 text-center">
        <h4 className="mt-5">BPS Settings</h4>
        <MDBRow>
                <MDBCol size="0" md="2"></MDBCol>
                <MDBCol size="12" md="8">
                    <MDBInput label="Username" value={settingsForm.username} onChange={(e) => updateSettingsForm({...settingsForm, username: e.target.value})} />
                    <MDBInput label="Password" value={settingsForm.password} onChange={(e) => updateSettingsForm({...settingsForm, password: e.target.value})} />
                    <MDBInput label="Account ID" value={settingsForm.accountId} onChange={(e) => updateSettingsForm({...settingsForm, accountId: e.target.value})} type="number" />
                    <MDBInput label="API key" value={settingsForm.apiKey} onChange={(e) => updateSettingsForm({...settingsForm, apiKey: e.target.value})} />
                </MDBCol>
                <MDBCol size="0" md="2"></MDBCol>
                <MDBCol size="12" className="text-center mt-3 mt-md-4">
                {isLoading.connectManualStore ? 
                    <div className="spinner-grow text-default" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                :
                <MDBBtn size="sm" disabled={!(settingsForm.username?.length > 3 && settingsForm.password?.length > 3 && settingsForm.accountId?.length > 0 &&settingsForm.apiKey?.length > 10)} onClick={() => updateAuth()}>Save</MDBBtn>
                }
                {updateAuthRes.finished && !updateAuthRes.success && <span className="text-danger">{updateAuthRes.message}</span> }
                </MDBCol>
            </MDBRow>
            {connectionActive && 
            <>
            <MDBCard className="m-2 m-md-5">
                <MDBCardBody className="text-center">
                    <MDBCardTitle>Sync stock</MDBCardTitle>
                    {isLoading.stockSync ? 
                        <div className="spinner-grow text-default" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    :
                    <MDBInput containerClass="text-center w-100" label="Active" checked={stockSyncActive} type="checkbox" id="activateStockSync" onChange={(e) => updateStockSync(e.target.checked)}/>
                    }
                    
                    <p className="mt-2">The stock level is synchronized using the barcode of the products. Please make sure all your products have a unique barcode and it matches the product in BPS</p>
                </MDBCardBody>
            </MDBCard>
            <MDBCard className="m-2 m-md-5">
                <MDBCardBody className="text-center">
                    <MDBCardTitle>Sync tracking</MDBCardTitle>
                    {isLoading.trackingSync ? 
                        <div className="spinner-grow text-default" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    :
                    <MDBRow>
                        <MDBCol><MDBInput containerClass="text-center w-100" label="Notify Marketplace" checked={trackingNotifyMarketplace} type="checkbox" id="notifyMarketplaceTrackingSync" onChange={(e) => setTrackingNotifyMarketplace(e.target.checked)}/></MDBCol>
                        <MDBCol><MDBInput containerClass="text-center w-100" label="Active" checked={trackingSyncActive} type="checkbox" id="activateTrackingSync" onChange={(e) => updateTrackingSync(e.target.checked)}/></MDBCol>
                    </MDBRow>
                    }
                    <p className="mt-2">Notify Marketplace sends the tracking information to the marketplace. When disabled, tracking information is only stored in ChannelHive.</p>
                </MDBCardBody>
            </MDBCard>
            </>
            }
            
    </MDBContainer>
  )
}
