import { Component } from "react";
import { 
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    ListGroup,
    ListGroupItem,
    MDBIcon,
    Input
 } from "mdbreact";
 import {ProductsAPI} from "../../Api/connect";
 import TreeMenu from 'react-simple-tree-menu'
 import CreateCategoryModal from "../../components/products/CategoryCreate";
 import EditCategoryModal from "../../components/products/CategoryEdit";

 const buildTree = function (data, root) {
  var o = {};
  data.forEach(function(a) {
      a = Object.assign({}, a);
      if (o[a.id] && o[a.id].nodes) {
          a.nodes = o[a.id].nodes;
      }
      o[a.id] = { [a.id]: a };
      o[a.parent] = o[a.parent] || {};
      o[a.parent][a.parent] = o[a.parent][a.parent] || {};
      o[a.parent][a.parent].nodes = o[a.parent][a.parent].nodes || {};
      o[a.parent][a.parent].nodes[a.id] = a;
  });
  return o[root][root].nodes;
};

class Categories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            selectedCategory: {},
            modals: {
                CreateCategory: {
                    isOpen: false
                },
                EditCategory: {
                    isOpen: false
                }
            }
        }
    }

    async componentDidMount() {
        this.getCategories();
    }

    async getCategories() {
        try {
            const categories = await ProductsAPI.getCategories();
            console.log(categories)
            this.setState({categories})
        } catch (error) {
            console.log(JSON.stringify(error))
        } 
    }
    
    formCategories = (categories) => {
        const cats = categories.map(c => {
            return ({
                index: c.id,
                id: c.id,
                parent: c.parentID,
                label: c.name,
                short: c.short
            })
        });
        return buildTree(cats, "0");
    }

    toggleModal = (id) => {
        if (this.state.modals[id].isOpen) {
            setTimeout(() => {
                this.getCategories();
            }, 500)
        }
        this.setState(state => ({
            modals: {
                ...state.modals,
                [id]: {
                    ...!state.modals[id],
                    isOpen: !state.modals[id].isOpen
                }
            }
        }));
    }

    render() {
        return (
            <section>
                <Row>
                    <Col>
                    <Card>
                       <CardHeader color="stylish-color">Actions</CardHeader>
                       <CardBody>
                       <Row>
                           <Col>
                               <ListGroup>
                                   <ListGroupItem className="d-flex justify-content-between align-items-center" onClick={() => {this.toggleModal("CreateCategory")}}>
                                       Create <MDBIcon icon="plus" />
                                   </ListGroupItem>
                                   <ListGroupItem className="d-flex justify-content-between align-items-center">
                                       Delete <MDBIcon icon="remove" />
                                   </ListGroupItem>
                               </ListGroup>
                           </Col>
                           <Col>
                               <ListGroup>
                                   <ListGroupItem className="d-flex justify-content-between align-items-center" onClick={() => {this.toggleModal("EditCategory")}}>
                                       Edit <MDBIcon icon="edit" />
                                   </ListGroupItem>
                                   <ListGroupItem className="d-flex justify-content-between align-items-center">
                                       Copy<MDBIcon icon="copy" />
                                   </ListGroupItem>
                               </ListGroup>
                           </Col>
                           <Col>
                               <ListGroup>
                                   <ListGroupItem className="d-flex justify-content-between align-items-center">
                                       Import <MDBIcon icon="file-import" />
                                   </ListGroupItem>
                                   <ListGroupItem className="d-flex justify-content-between align-items-center">
                                       Export <MDBIcon icon="file-export" />
                                   </ListGroupItem>
                               </ListGroup>
                           </Col>
                       </Row>
                       </CardBody>
                   </Card>
                    </Col>
                </Row>
                <Row className="py-3">
               <Col md="12">
                 <Card>
                   <CardBody>
                   {this.state.categories.length > 0 && 
                    <TreeMenu
                        data={this.formCategories(this.state.categories)}
                        onClickItem={({ key, label, ...props }) => {
                            this.setState({selectedCategory: this.state.categories.filter(c => c.id === props.id)[0]})
                        }}
                        debounceTime={125}
                        >
                            {({ search, items }) => (
                                <>
                                <Input
                                style={{
                                    border: "none"
                                }}
                                aria-label="Type and search"
                                type="search"
                                label="Type and search"
                                onChange={e => search(e.target.value)} placeholder="Type and search" />
                                <ul
                                style={{
                                    listStyleType: "none",
                                    paddingLeft: 0,
                                    borderTop: "1px solid #ccc"
                                }}
                                >
                                    {items.map(props => {
                                        console.log(props);
                                    return <li
                                            key={props.id}
                                            parent={props.parent}
                                            level={props.level}
                                            opennodes={props.opennodes}
                                            index={props.index}
                                            id={props.id}
                                            onClick={props.onClick}
                                            style={{
                                            padding: " .75rem  1rem  .75rem " + (3.5 + 1.25 * props.level) + "rem",
                                            cursor: "pointer",
                                            color: props.active ? "white" : "#333",
                                            background: props.active ? "#009688" : "none",
                                            borderBottom: "1px solid #ccc"
                                            }}
                                            role={"button"}
                                            aria-pressed={props.active}
                                        >
                                        <div
                                        style={{
                                            position: "absolute",
                                            left: 2 + 1.25 * props.level + "rem",
                                            width: "2rem",
                                            height: "2rem"
                                        }}
                                        >
                                        <div
                                        role="img"
                                        aria-label="Toggle"
                                        >
                                        {props.hasNodes && (props.isOpen ? "-" : "+")}
                                        </div>
                                        </div>
                                        {props.label} <span style={{fontSize: "10px"}}>{props.id}</span>
                                        </li>
                                    })}
                                </ul>
                                </>
                            )}
                        </TreeMenu>
                   
                   }
                  
                   </CardBody>
                 </Card>
               </Col>
             </Row>
             <CreateCategoryModal  isOpen={this.state.modals.CreateCategory.isOpen} toggle={this.toggleModal} categories={this.state.categories} />
             <EditCategoryModal  isOpen={this.state.modals.EditCategory.isOpen} toggle={this.toggleModal} categories={this.state.categories} category={this.state.selectedCategory} />
            </section>
         );
    }
  
};

export default Categories;