import { createSlice } from "@reduxjs/toolkit"

export const userData = createSlice({
    name: "userData",
    initialState: {},
    reducers: {
        setUserData: (state, action) => {
            return action.payload
        }
    }
})

export const { setUserData } = userData.actions

export default userData.reducer